import { Grow } from "@mui/material";
import { FC } from "react";

import "./style.css";

interface ICongratsMessage {
  name: string;
  active: boolean;
}
export const CongratsMessage: FC<ICongratsMessage> = ({ name, active }) => (
  <Grow in={active}>
    <div className="containerCongratsText">
      <h1>{`Gracias por apoyar a ${name}, tu equipo te lo agradece`}</h1>
    </div>
  </Grow>
);
