import { Grow, Button } from "@mui/material";
import { TeamComponent } from "../Team";
import { Match } from "../../App";
import { FC } from "react";

import "./style.css";

interface IInitialBanner {
  data: Match;
  active: boolean;
  closeInitialBanner: () => void;
}

export const InitialBanner: FC<IInitialBanner> = ({
  data,
  active,
  closeInitialBanner,
}) => (
  <Grow in={active}>
    <div className="containerBanner" onClick={closeInitialBanner}>
      <h1 className="titleBanner">
        Demuestra que equipo tiene la mejor inchada
      </h1>
      <div className="containerTypeGame">
        <p>{data?.typeGame}</p>
      </div>
      <div className="containerTeams">
        <TeamComponent
          team={data?.local}
          active={active}
          textColor="#fff"
          background="transparent"
          showSeats={false}
        />
        <h2 className="vsText">VS</h2>
        <TeamComponent
          team={data?.visitante}
          active={active}
          textColor="#fff"
          background="transparent"
          showSeats={false}
        />
      </div>
      <div className="containerStadiumName">
        <p>{data?.stadiumName}</p>
      </div>
      <div style={{ textAlign: "center" }}>
        <p>
          Solo debes hacer zoom en tu zona de preferencia y seleccionar tu
          asiento para apoyar a tu equipo
        </p>
        <Button variant="contained" onClick={closeInitialBanner}>
          Selecciona tu asiento ya, antes que te lo ganen
        </Button>
      </div>
    </div>
  </Grow>
);
