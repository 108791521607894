import { useCallback, useEffect, useRef, useState } from "react";
import { zonesJSON } from "./zones";

interface Point {
  ath: number;
  atv: number;
}
interface Zone {
  points: Point[];
}

const useZoneEditor = () => {
  const [zones, setZones] = useState<Zone[]>([]);

  const currentZone = useRef<Zone | null>(null);
  const editingMode = useRef<boolean>(false);

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "a") {
        editingMode.current = true;
        currentZone.current = { points: [] };
        console.log("EDITING MODE ON");
      }
    });
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "s") {
        editingMode.current = false;
        if (currentZone.current) {
          const newZone = currentZone.current;
          setZones((prev) => [...prev, newZone]);
        }
        currentZone.current = null;
        console.log("EDITING MODE OFF");
      }
    });
  }, []);

  const pointClicked = useCallback((atv: string, ath: string) => {
    if (editingMode.current) {
      currentZone.current?.points.push({
        ath: Number(ath),
        atv: Number(atv),
      });
      console.log("Added point ->", "atv: ", atv, "ath: ", ath);
    }
  }, []);

  return { pointClicked, zones };
};

export const useAddHotSpots = (
  zones: Zone[],
  callKrpano: (action: string) => void,
  setKrpano: (action: string, value: any) => void
) => {
  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "d") {
          console.log(
            "ZONES",
            JSON.stringify(zones.map((zone, i) => ({ ...zone, id: `hs${i}` })))
          );

          zones.forEach((zone, index) => {
            const hsName = "spot" + index;
            callKrpano("addhotspot(" + hsName + ")");
            setKrpano("hotspot[" + hsName + "].fillalpha", 0.5);
            setKrpano("hotspot[" + hsName + "].borderalpha", 0);
            setKrpano("hotspot[" + hsName + "].borderwidth", 0);
            zone.points.forEach((point, index) => {
              setKrpano(
                "hotspot[" + hsName + "].point[" + index + "].ath",
                point.ath
              );
              setKrpano(
                "hotspot[" + hsName + "].point[" + index + "].atv",
                point.atv
              );
            });
            setKrpano(
              "hotspot[" + hsName + "].onclick",
              "jscall(reactKrpano.hotSpotClicked('" + hsName + "'))"
            );
          });
        }
      },
      { once: true }
    );
  }, [zones, callKrpano, setKrpano]);
};

export const useAddDefaultHotSpots = (
  callKrpano: (action: string) => void,
  setKrpano: (action: string, value: any) => void,
  sillas: Record<string, "visitante" | "local">,
  colores: { visitante: string; local: string }
) => {
  useEffect(() => {
    zonesJSON.forEach((zone) => {
      callKrpano("addhotspot(" + zone.id + ")");
    });

    zonesJSON.forEach((zone) => {
      const hsName = zone.id;
      setKrpano("hotspot[" + hsName + "].fillalpha", 0);
      setKrpano("hotspot[" + hsName + "].borderalpha", 0);
      setKrpano("hotspot[" + hsName + "].borderwidth", 0);
      zone.points.forEach((point, index) => {
        setKrpano(
          "hotspot[" + hsName + "].point[" + index + "].ath",
          point.ath
        );
        setKrpano(
          "hotspot[" + hsName + "].point[" + index + "].atv",
          point.atv
        );
      });
      setKrpano(
        "hotspot[" + hsName + "].onclick",
        "jscall(reactKrpano.hotSpotClicked('" + hsName + "'))"
      );
    });
  }, [callKrpano, setKrpano]);

  useEffect(() => {
    Object.keys(sillas).forEach((key) => {
      setKrpano("hotspot[" + key + "].fillalpha", 0.5);
      setKrpano(
        "hotspot[" + key + "].fillcolor",
        colores[sillas[key]].replace("#", "0x")
      );
      setKrpano("hotspot[" + key + "].enabled", false);
    });
  }, [colores, setKrpano, sillas]);
};

export default useZoneEditor;
