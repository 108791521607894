import Grow from "@mui/material/Grow";
import { FC } from "react";
import { Team } from "../../App";

import "./style.css";

interface ITeam {
  team: Team;
  active: boolean;
  background?: string;
  textColor?: string;
  showSeats?: boolean;
  onClick?: () => void;
}

export const TeamComponent: FC<ITeam> = ({
  team,
  active,
  background,
  textColor,
  showSeats = true,
  onClick,
}) => (
  <Grow in={active} onClick={onClick}>
    <div
      className="containerTeam"
      style={{
        backgroundColor: background || "#ffffff9a",
        cursor: onClick ? "pointer" : "default",
      }}
    >
      <h2 className="teamName" style={{ color: textColor || "#000" }}>{team?.teamName}</h2>
      <img className="teamImage" src={team?.teamUrlImage} alt={team?.teamName} />
      {showSeats && (
        <p
          className="teamSeatsText"
          style={{
            color: textColor || "#000",
          }}
        >
          Asientos selecciondos: {team?.assignedSeats}
        </p>
      )}
    </div>
  </Grow>
);
