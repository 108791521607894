import { Grow } from "@mui/material";
import { TeamComponent } from "../Team";
import { Team } from "../../App";
import { FC } from "react";

import "../InitialBanner/style.css";
import "./style.css";

interface ITeamSelect {
  data: Team;
  active: boolean;
}
export const WinnerBanner: FC<ITeamSelect> = ({ data, active }) => (
  <Grow in={active}>
    <div className="containerTeamSelect">
      <div className="containerTeams containerWinnerBanner">
        <h1 className="titleBanner titleWinnerBanner">
          Se han cerrado las votaciones y el equipo con la mejor hinchada es:
        </h1>
        <TeamComponent
          team={data}
          active={active}
          textColor="#fff"
          background="transparent"
          showSeats={false}
        />
      </div>
    </div>
  </Grow>
);
