import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import JSConfetti from "js-confetti";
import { useEffect, useState } from "react";
import useKrpano from "react-krpano-hooks";
import { db } from "./firebase/config";

import { CongratsMessage } from "./components/CongratsMessage";
import { InitialBanner } from "./components/InitialBanner";
import { TeamComponent } from "./components/Team";
import { TeamSelect } from "./components/TeamSelect";
import useZoneEditor, {
  useAddDefaultHotSpots,
  useAddHotSpots,
} from "./hooks/useZoneEditor";
import { WinnerBanner } from "./components/WinnerBanner";

import "./App.css";

export interface Team {
  assignedSeats: number;
  teamColor: string;
  teamColorSecondary: string;
  teamName: string;
  teamUrlImage: string;
}
export interface Match {
  local: Team;
  visitante: Team;
  stadiumName: string;
  totalSeats: number;
  typeGame: string;
  sillas: Record<string, "visitante" | "local">;
}

const App = () => {
  const [data, setData] = useState<Match | undefined>();
  const [initialBanner, setInitialBanner] = useState(true);
  const [showBannerTeamSelect, setShowBannerTeamSelect] = useState(false);
  const [showCongratsMessage, setShowCongratsMessage] = useState(false);
  const [showWinnerBanner, setShowWinnerBanner] = useState(false);
  const [isLocalWinner, setIsLocalWinner] = useState(false);
  const [isLocal, setIsLocal] = useState(false);
  const [currentSpot, setCurrentSpot] = useState("");
  const jsConfetti = new JSConfetti();

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, "match", "QSepQiwCiutRvgKUvqk0"),
      (doc) => {
        setData(doc.data() as Match);
      }
    );

    return unsub;
  }, []);
  useEffect(() => {
    if (showCongratsMessage) {
      setTimeout(() => {
        setShowCongratsMessage(false);
      }, 3000);
    }
  }, [showCongratsMessage]);

  useEffect(() => {
    showWinner(data as Match);
  }, [data]);

  const showWinner = (data: Match) => {
    const finalMatch =
      data?.totalSeats <=
      data?.local.assignedSeats + data?.visitante.assignedSeats;
    if (finalMatch) {
      setInitialBanner(false);
      setShowCongratsMessage(false);
      setShowWinnerBanner(true);

      const localPercentage =
        (data.local.assignedSeats * 100) / data.totalSeats;
      const visitPercentage =
        (data.visitante.assignedSeats * 100) / data.totalSeats;

      setIsLocalWinner(localPercentage > visitPercentage);

      const winner = data[isLocalWinner ? "local" : "visitante"];

      jsConfetti.addConfetti({
        confettiColors: [winner.teamColor, winner.teamColorSecondary],
        confettiRadius: 6,
        confettiNumber: 500,
      });
    }
  };

  const { pointClicked, zones } = useZoneEditor();

  const { containerRef, callKrpano, setKrpano } = useKrpano({
    embeddingParams: { consolelog: true },
    globalFunctions: {
      hotSpotClicked: (hsName) => {
        setCurrentSpot(hsName);
        !showCongratsMessage && setShowBannerTeamSelect(true);
      },
      pointClicked,
    },
  });

  useAddHotSpots(zones, callKrpano, setKrpano);
  useAddDefaultHotSpots(callKrpano, setKrpano, data?.sillas || {}, {
    visitante: data?.visitante.teamColor || "",
    local: data?.local.teamColor || "",
  });

  const updateSeatForTeam = async (localTeam: boolean) => {
    if (!data) {
      return;
    }

    setIsLocal(localTeam);

    const matchDocument = doc(db, "match", "QSepQiwCiutRvgKUvqk0");

    const { visitante, local, sillas } = data;
    console.log(currentSpot);
    if (localTeam) {
      const newSeats = local.assignedSeats + 1;
      await updateDoc(matchDocument, {
        local: { ...local, assignedSeats: newSeats },
        sillas: { ...sillas, [currentSpot]: "local" },
      });
    } else {
      const newSeats = visitante.assignedSeats + 1;
      await updateDoc(matchDocument, {
        visitante: { ...visitante, assignedSeats: newSeats },
        sillas: { ...sillas, [currentSpot]: "visitante" },
      });
    }

    setShowBannerTeamSelect(false);
    setShowCongratsMessage(true);
    jsConfetti.addConfetti({
      confettiRadius: 6,
      confettiNumber: 500,
    });
  };

  const closeInitialBanner = () => {
    setInitialBanner(false);
  };

  return (
    <>
      {data && (
        <>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              pointerEvents: "none",
            }}
          >
            <TeamComponent
              team={data?.local}
              active={
                !initialBanner && !showBannerTeamSelect && !showWinnerBanner
              }
            />
            <TeamComponent
              team={data?.visitante}
              active={
                !initialBanner && !showBannerTeamSelect && !showWinnerBanner
              }
            />
          </div>
          <InitialBanner
            data={data}
            active={initialBanner}
            closeInitialBanner={closeInitialBanner}
          />
          <TeamSelect
            data={data}
            active={showBannerTeamSelect}
            updateSeatForTeam={updateSeatForTeam}
          />
          <CongratsMessage
            name={data?.[isLocal ? "local" : "visitante"].teamName}
            active={showCongratsMessage}
          />
          <WinnerBanner
            data={data[isLocalWinner ? "local" : "visitante"]}
            active={showWinnerBanner}
          />
        </>
      )}

      <div ref={containerRef as any} />
    </>
  );
};

export default App;
