export const zonesJSON = [
  {
    points: [
      { ath: 125.20660578213054, atv: -19.464436328700735 },
      { ath: 125.37437223788768, atv: -19.469938126174252 },
      { ath: 125.37967440878512, atv: -19.40620039316652 },
      { ath: 125.21193249256444, atv: -19.40778990387747 },
    ],
    id: "hs0",
  },
  {
    points: [
      { ath: 125.38686635381376, atv: -19.467629198112583 },
      { ath: 125.39216362826232, atv: -19.40389090755189 },
      { ath: 125.56488645366659, atv: -19.411597665360244 },
      { ath: 125.56969071199106, atv: -19.470651267816834 },
    ],
    id: "hs1",
  },
  {
    points: [
      { ath: 125.58217087328853, atv: -19.47069009814176 },
      { ath: 125.74748448291984, atv: -19.471118240635686 },
      { ath: 125.75263739460519, atv: -19.40972772912847 },
      { ath: 125.57738617051166, atv: -19.404552108412684 },
    ],
    id: "hs2",
  },
  {
    points: [
      { ath: 125.76005722669814, atv: -19.468782704992112 },
      { ath: 125.92286128653632, atv: -19.47613145306086 },
      { ath: 125.920433976433, atv: -19.41708808362261 },
      { ath: 125.76268546498957, atv: -19.41447141211613 },
    ],
    id: "hs3",
  },
  {
    points: [
      { ath: 125.93542805894538, atv: -19.473783603785662 },
      { ath: 126.10325904741416, atv: -19.47625361633795 },
      { ath: 126.10577481486965, atv: -19.419574889568374 },
      { ath: 125.9329992161918, atv: -19.41237870417501 },
    ],
    id: "hs4",
  },
  {
    points: [
      { ath: 126.11578402273054, atv: -19.47625550975064 },
      { ath: 126.28863923523056, atv: -19.48800179367557 },
      { ath: 126.28857840069065, atv: -19.42187639986695 },
      { ath: 126.11579043269674, atv: -19.42666125973869 },
    ],
    id: "hs5",
  },
  {
    points: [
      { ath: 126.29615242452684, atv: -19.485633780518885 },
      { ath: 126.46901801523788, atv: -19.492485598243228 },
      { ath: 126.46889429189832, atv: -19.431084341030946 },
      { ath: 126.30109915439351, atv: -19.421865588310904 },
    ],
    id: "hs6",
  },
  {
    points: [
      { ath: 126.47652863053634, atv: -19.490110175711656 },
      { ath: 126.4764167034777, atv: -19.435793731745232 },
      { ath: 126.64669811471106, atv: -19.43303630331407 },
      { ath: 126.6468954430214, atv: -19.49679765480046 },
    ],
    id: "hs7",
  },
  {
    points: [
      { ath: 126.65534460429438, atv: -19.495694789199312 },
      { ath: 126.83062819995271, atv: -19.501474999222285 },
      { ath: 126.82857942875481, atv: -19.42825999324429 },
      { ath: 126.6532887915009, atv: -19.43428761022197 },
    ],
    id: "hs8",
  },
  {
    points: [
      { ath: 126.84063244455612, atv: -19.503891681191174 },
      { ath: 127.01096298387898, atv: -19.504748469170703 },
      { ath: 127.01128846918493, atv: -19.440989436384083 },
      { ath: 126.83603174599855, atv: -19.437746827515166 },
    ],
    id: "hs9",
  },
  {
    points: [
      { ath: 127.02098291399128, atv: -19.50479390752219 },
      { ath: 127.19380587076262, atv: -19.512575547274103 },
      { ath: 127.19657339608938, atv: -19.446461236068288 },
      { ath: 127.01876464227993, atv: -19.448107805219266 },
    ],
    id: "hs10",
  },
  {
    points: [
      { ath: 127.20127542331107, atv: -19.512603521697553 },
      { ath: 127.37413956230819, atv: -19.515491136950562 },
      { ath: 127.37433628905546, atv: -19.447004328150996 },
      { ath: 127.20403990318408, atv: -19.446489011575025 },
    ],
    id: "hs11",
  },
  {
    points: [
      { ath: 127.38422112594876, atv: -19.51079198574415 },
      { ath: 127.5570984543003, atv: -19.511144217928592 },
      { ath: 127.55469290009191, atv: -19.454460374637787 },
      { ath: 127.37937806992696, atv: -19.451738930222135 },
    ],
    id: "hs12",
  },
  {
    points: [
      { ath: 127.56460695815667, atv: -19.515879128937648 },
      { ath: 127.7374931298516, atv: -19.518421742949332 },
      { ath: 127.73753087173253, atv: -19.459379546168833 },
      { ath: 127.56219871467309, atv: -19.459195365870315 },
    ],
    id: "hs13",
  },
  {
    points: [
      { ath: 127.74751583811445, atv: -19.518427195065463 },
      { ath: 127.92291598314182, atv: -19.523156601542944 },
      { ath: 127.92288405802708, atv: -19.459390997970452 },
      { ath: 127.74504517395255, atv: -19.45938367348554 },
    ],
    id: "hs14",
  },
  {
    points: [
      { ath: 127.93038686950112, atv: -19.523154484154748 },
      { ath: 128.09578371796496, atv: -19.534806750704018 },
      { ath: 128.09318640826103, atv: -19.47576857552205 },
      { ath: 127.93035587237696, atv: -19.466473970396876 },
    ],
    id: "hs15",
  },
  {
    points: [
      { ath: 128.10329757935983, atv: -19.53243441861781 },
      { ath: 128.28624075417838, atv: -19.53916381444876 },
      { ath: 128.2860470102566, atv: -19.468315263726023 },
      { ath: 128.10069381850624, atv: -19.471034702199884 },
    ],
    id: "hs16",
  },
  {
    points: [
      { ath: 128.29374518951315, atv: -19.534422043044028 },
      { ath: 128.4692090408075, atv: -19.55043187401584 },
      { ath: 128.46892648202484, atv: -19.477223943212064 },
      { ath: 128.29358757048618, atv: -19.47774326685846 },
    ],
    id: "hs17",
  },
  {
    points: [
      { ath: 128.48016886883036, atv: -19.546759967197307 },
      { ath: 128.6430688470948, atv: -19.548950888336275 },
      { ath: 128.64797645939484, atv: -19.48754013519605 },
      { ath: 128.47511887215407, atv: -19.487720785999635 },
    ],
    id: "hs18",
  },
  {
    points: [
      { ath: 128.6530427936846, atv: -19.546573889507776 },
      { ath: 128.83098991814603, atv: -19.55329555673271 },
      { ath: 128.8233008509556, atv: -19.49191246511866 },
      { ath: 128.66046006029168, atv: -19.48988226868458 },
    ],
    id: "hs19",
  },
  {
    points: [
      { ath: 128.84100735554844, atv: -19.550908301285695 },
      { ath: 129.0139370588344, atv: -19.555101882816714 },
      { ath: 129.01372228492062, atv: -19.500786502694126 },
      { ath: 128.83332806659354, atv: -19.49424892848849 },
    ],
    id: "hs20",
  },
  {
    points: [
      { ath: 129.0215106975629, atv: -19.557436708978887 },
      { ath: 129.19944056172915, atv: -19.559073937887206 },
      { ath: 129.1966588516199, atv: -19.50477220760198 },
      { ath: 129.02130285077573, atv: -19.505482957887864 },
    ],
    id: "hs21",
  },
  {
    points: [
      { ath: 129.20698432891157, atv: -19.556678660837708 },
      { ath: 129.37990370591805, atv: -19.56052343652824 },
      { ath: 129.38462039591056, atv: -19.51326921597739 },
      { ath: 129.20921002995792, atv: -19.502354132375356 },
    ],
    id: "hs22",
  },
  {
    points: [
      { ath: 129.39000949822824, atv: -19.567552042715693 },
      { ath: 129.5655020955393, atv: -19.580650057253038 },
      { ath: 129.5625117906198, atv: -19.514553709616177 },
      { ath: 129.3921591568756, atv: -19.510866532381137 },
    ],
    id: "hs23",
  },
  {
    points: [
      { ath: 129.5729449884498, atv: -19.575877467806425 },
      { ath: 129.74336967837854, atv: -19.581752272415976 },
      { ath: 129.7427917425, atv: -19.5132830956885 },
      { ath: 129.57497824971085, atv: -19.512109473848177 },
    ],
    id: "hs24",
  },
  {
    points: [
      { ath: 129.5773601570259, atv: -19.495564714584834 },
      { ath: 129.7401681961531, atv: -19.499135857644728 },
      { ath: 129.7395521740745, atv: -19.425944188404696 },
      { ath: 129.57182847074867, atv: -19.424762516152605 },
    ],
    id: "hs25",
  },
  {
    points: [
      { ath: 129.3944675624512, atv: -19.49196071706981 },
      { ath: 129.55984335879947, atv: -19.502762946650627 },
      { ath: 129.5567362589561, atv: -19.42013765152248 },
      { ath: 129.3915184490455, atv: -19.421135137251497 },
    ],
    id: "hs26",
  },
  {
    points: [
      { ath: 129.20911265872877, atv: -19.48346261350189 },
      { ath: 129.38697770799212, atv: -19.489642047319194 },
      { ath: 129.38409072138236, atv: -19.428261505830477 },
      { ath: 129.20874771804398, atv: -19.412619293026506 },
    ],
    id: "hs27",
  },
  {
    points: [
      { ath: 129.0211800946506, atv: -19.474782929068333 },
      { ath: 129.19653895621488, atv: -19.48115773484734 },
      { ath: 129.19615552483052, atv: -19.405591280427448 },
      { ath: 129.02340060381678, atv: -19.403927665702984 },
    ],
    id: "hs28",
  },
  {
    points: [
      { ath: 128.82830959377128, atv: -19.461199564167867 },
      { ath: 129.0036579904404, atv: -19.467760550194797 },
      { ath: 129.00588606659872, atv: -19.396905275826654 },
      { ath: 128.83564816078706, atv: -19.399778462442008 },
    ],
    id: "hs29",
  },
  {
    points: [
      { ath: 128.6530151269498, atv: -19.459193669513535 },
      { ath: 128.8183364879276, atv: -19.463586643513686 },
      { ath: 128.82066494252578, atv: -19.399816622788535 },
      { ath: 128.66042006490508, atv: -19.397778557532522 },
    ],
    id: "hs30",
  },
  {
    points: [
      { ath: 128.48268929278956, atv: -19.45465388100708 },
      { ath: 128.64049153955588, atv: -19.459212585987405 },
      { ath: 128.65040499163703, atv: -19.397794118953758 },
      { ath: 128.4801434728605, atv: -19.393251369322783 },
    ],
    id: "hs31",
  },
  {
    points: [
      { ath: 128.29321155705563, atv: -19.449161461998237 },
      { ath: 128.46605106568012, atv: -19.457950451147262 },
      { ath: 128.47075204308047, atv: -19.394169123324247 },
      { ath: 128.29048777227325, atv: -19.39013063437604 },
    ],
    id: "hs32",
  },
  {
    points: [
      { ath: 128.1003995647183, atv: -19.444978170402134 },
      { ath: 128.2807310243252, atv: -19.449202747601394 },
      { ath: 128.28301001340225, atv: -19.38779376993844 },
      { ath: 128.09789580415816, atv: -19.388299441577217 },
    ],
    id: "hs33",
  },
  {
    points: [
      { ath: 127.92773583294579, atv: -19.445286007313662 },
      { ath: 128.08552383940074, atv: -19.44736834650535 },
      { ath: 128.0903851265475, atv: -19.388316296369528 },
      { ath: 127.9176192198114, atv: -19.376810477252132 },
    ],
    id: "hs34",
  },
  {
    points: [
      { ath: 127.73242005728855, atv: -19.43599608886956 },
      { ath: 127.91775225788669, atv: -19.440575826805674 },
      { ath: 127.90765639923282, atv: -19.38390809181907 },
      { ath: 127.72739405919762, atv: -19.37223153956442 },
    ],
    id: "hs35",
  },
  {
    points: [
      { ath: 127.52876492954934, atv: -19.421688435096392 },
      { ath: 127.71410586374077, atv: -19.43288018549474 },
      { ath: 127.7163247611634, atv: -19.36674801322013 },
      { ath: 127.53107403674389, atv: -19.3602797283477 },
    ],
    id: "hs36",
  },
  {
    points: [
      { ath: 127.33096844667955, atv: -19.41269297707808 },
      { ath: 127.5187923799821, atv: -19.42171746048534 },
      { ath: 127.51610640979123, atv: -19.362684183193213 },
      { ath: 127.33337523462163, atv: -19.36309373613423 },
    ],
    id: "hs37",
  },
  {
    points: [
      { ath: 127.15072039740275, atv: -19.412916706059217 },
      { ath: 127.32601533944162, atv: -19.419786763953542 },
      { ath: 127.3258933141521, atv: -19.356021868362692 },
      { ath: 127.16568536796626, atv: -19.349139252151907 },
      { ath: 127.16318230525715, atv: -19.349141303270777 },
    ],
    id: "hs38",
  },
  {
    points: [
      { ath: 126.93985540566459, atv: -19.408011355661657 },
      { ath: 127.14017193171708, atv: -19.409943592138976 },
      { ath: 127.14999815366673, atv: -19.34851457020675 },
      { ath: 126.94974868397372, atv: -19.3418684742862 },
    ],
    id: "hs39",
  },
  {
    points: [
      { ath: 126.75209034191641, atv: -19.39640546512303 },
      { ath: 126.9298828669636, atv: -19.410388102923775 },
      { ath: 126.93976769389619, atv: -19.337160709687584 },
      { ath: 126.73953825074534, atv: -19.3373697752848 },
    ],
    id: "hs40",
  },
  {
    points: [
      { ath: 126.54928518224122, atv: -19.39168485101385 },
      { ath: 126.74207787178128, atv: -19.401134269110457 },
      { ath: 126.72952550088291, atv: -19.335012794554764 },
      { ath: 126.55196420750255, atv: -19.33264586937885 },
    ],
    id: "hs41",
  },
  {
    points: [
      { ath: 126.360419261343, atv: -19.386925498522064 },
      { ath: 126.53319213460426, atv: -19.39850924821603 },
      { ath: 126.54306208332758, atv: -19.32764150251924 },
      { ath: 126.36286720828429, atv: -19.327881182112716 },
    ],
    id: "hs42",
  },
  {
    points: [
      { ath: 126.16497508393928, atv: -19.384477873685267 },
      { ath: 126.35027337707851, atv: -19.398316909491513 },
      { ath: 126.35258802185874, atv: -19.325100530534844 },
      { ath: 126.1698923802812, atv: -19.323067803211075 },
    ],
    id: "hs43",
  },
  {
    points: [
      { ath: 125.97276103924506, atv: -19.382109199409328 },
      { ath: 126.15304685251368, atv: -19.39348691254153 },
      { ath: 126.16031817976963, atv: -19.320255720528362 },
      { ath: 125.97514850822589, atv: -19.3277864283688 },
    ],
    id: "hs44",
  },
  {
    points: [
      { ath: 125.78116628135353, atv: -19.372586423607988 },
      { ath: 125.96644924417777, atv: -19.383911820770273 },
      { ath: 125.96620252615469, atv: -19.313064424654062 },
      { ath: 125.77853064415007, atv: -19.315912193581976 },
    ],
    id: "hs45",
  },
  {
    points: [
      { ath: 125.59094871142639, atv: -19.37288652126956 },
      { ath: 125.76869946662228, atv: -19.377335198060297 },
      { ath: 125.77354851433427, atv: -19.308837234145496 },
      { ath: 125.59338401886103, atv: -19.31620346116951 },
    ],
    id: "hs46",
  },
  {
    points: [
      { ath: 125.39572211331699, atv: -19.368266477094483 },
      { ath: 125.58097361668356, atv: -19.370535729628397 },
      { ath: 125.5808992263114, atv: -19.304408549943272 },
      { ath: 125.3857133456182, atv: -19.299776914751703 },
    ],
    id: "hs47",
  },
  {
    points: [
      { ath: 125.20552184115863, atv: -19.358719521786583 },
      { ath: 125.38074605687558, atv: -19.368265661219446 },
      { ath: 125.37825081683309, atv: -19.297414505895613 },
      { ath: 125.21059824748076, atv: -19.297320874689984 },
    ],
    id: "hs48",
  },
  {
    points: [
      { ath: 125.20744713259182, atv: -19.23296550655511 },
      { ath: 125.40003649840045, atv: -19.233389137078714 },
      { ath: 125.41273597645619, atv: -19.12477446032793 },
      { ath: 125.20780275443153, atv: -19.11488661304173 },
    ],
    id: "hs49",
  },
  {
    points: [
      { ath: 125.41000535324022, atv: -19.231043586163768 },
      { ath: 125.41000535324022, atv: -19.231043586163768 },
      { ath: 125.41769076664701, atv: -19.127143615921472 },
      { ath: 125.59767088321493, atv: -19.120254262079303 },
      { ath: 125.5975919445498, atv: -19.238337361472023 },
    ],
    id: "hs50",
  },
  {
    points: [
      { ath: 125.60760021990203, atv: -19.233619847900716 },
      { ath: 125.61266288166718, atv: -19.12970937713684 },
      { ath: 125.78014341582934, atv: -19.127359009703316 },
      { ath: 125.78269334266, atv: -19.240717860548354 },
    ],
    id: "hs51",
  },
  {
    points: [
      { ath: 125.79269771049002, atv: -19.238351935209298 },
      { ath: 125.79514031014219, atv: -19.124990950396146 },
      { ath: 125.9776285052981, atv: -19.134262607440014 },
      { ath: 125.97280495576595, atv: -19.245266801960444 },
    ],
    id: "hs52",
  },
  {
    points: [
      { ath: 125.9827608754961, atv: -19.24052843175795 },
      { ath: 125.99008127055731, atv: -19.13188185388532 },
      { ath: 126.17508208891672, atv: -19.140954680733604 },
      { ath: 126.1628796230001, atv: -19.24961879670582 },
    ],
    id: "hs53",
  },
  {
    points: [
      { ath: 126.17292324533847, atv: -19.24512433759383 },
      { ath: 126.18314510166181, atv: -19.138870061466502 },
      { ath: 126.36562751977587, atv: -19.14383755078459 },
      { ath: 126.3630194338333, atv: -19.257194664915726 },
    ],
    id: "hs54",
  },
  {
    points: [
      { ath: 126.37048454634416, atv: -19.254840632628344 },
      { ath: 126.37558529764428, atv: -19.143846995662933 },
      { ath: 126.56558223350629, atv: -19.15098467149836 },
      { ath: 126.5606063471453, atv: -19.254898778936525 },
    ],
    id: "hs55",
  },
  {
    points: [
      { ath: 126.57311437612898, atv: -19.254895718585487 },
      { ath: 126.57558240971599, atv: -19.150982034597416 },
      { ath: 126.76558789886602, atv: -19.153190817654625 },
      { ath: 126.75824877194069, atv: -19.266559126087664 },
    ],
    id: "hs56",
  },
  {
    points: [
      { ath: 126.76706645071167, atv: -19.270255116742675 },
      { ath: 126.77279282540357, atv: -19.16166745487771 },
      { ath: 126.94778320067343, atv: -19.157904613741692 },
      { ath: 126.94460623561977, atv: -19.27832244821799 },
    ],
    id: "hs57",
  },
  {
    points: [
      { ath: 126.95467638086544, atv: -19.273652290451206 },
      { ath: 126.96034544861257, atv: -19.1532463150433 },
      { ath: 127.1477747994607, atv: -19.163523964155456 },
      { ath: 127.14225208618723, atv: -19.281579114020275 },
    ],
    id: "hs58",
  },
  {
    points: [
      { ath: 127.1547220008033, atv: -19.281626199898955 },
      { ath: 127.1577564000876, atv: -19.158837689188065 },
      { ath: 127.33522587609846, atv: -19.166525145641508 },
      { ath: 127.33232315904746, atv: -19.289320180369213 },
    ],
    id: "hs59",
  },
  {
    points: [
      { ath: 127.34233854436991, atv: -19.286987833789965 },
      { ath: 127.3452413430964, atv: -19.161830847226753 },
      { ath: 127.51272091456289, atv: -19.17404250105646 },
      { ath: 127.50745829120615, atv: -19.292114648239238 },
    ],
    id: "hs60",
  },
  {
    points: [
      { ath: 127.5201593534544, atv: -19.291144705571686 },
      { ath: 127.52576403937374, atv: -19.175457743624733 },
      { ath: 127.72575647887311, atv: -19.17863572210728 },
      { ath: 127.72529593417136, atv: -19.294351334511095 },
    ],
    id: "hs61",
  },
  {
    points: [
      { ath: 127.73777356956003, atv: -19.29203403366977 },
      { ath: 127.73819766305988, atv: -19.183402779416312 },
      { ath: 127.89821151548227, atv: -19.18625098097113 },
      { ath: 127.90038151455525, atv: -19.299614971041883 },
    ],
    id: "hs62",
  },
  {
    points: [
      { ath: 127.91540113562158, atv: -19.297291908619496 },
      { ath: 127.91320070208252, atv: -19.191012731707865 },
      { ath: 128.0807339713104, atv: -19.193721721822268 },
      { ath: 128.07802971876967, atv: -19.304715860703393 },
    ],
    id: "hs63",
  },
  {
    points: [
      { ath: 128.09054553955934, atv: -19.302374316323323 },
      { ath: 128.0907365476031, atv: -19.193737648965584 },
      { ath: 128.26577740048288, atv: -19.203375381165433 },
      { ath: 128.2682699533754, atv: -19.21991032931975 },
      { ath: 128.2682683543007, atv: -19.222272022911216 },
      { ath: 128.2707272642635, atv: -19.286039214774778 },
    ],
    id: "hs64",
  },
  {
    points: [
      { ath: 128.27822337736552, atv: -19.302575336615885 },
      { ath: 128.28078253271806, atv: -19.208108983382846 },
      { ath: 128.44084075331875, atv: -19.20576356362284 },
      { ath: 128.43587457398536, atv: -19.309679739619106 },
    ],
    id: "hs65",
  },
  {
    points: [
      { ath: 128.4508440941747, atv: -19.312036147734613 },
      { ath: 128.45580085243708, atv: -19.20811959601374 },
      { ath: 128.62837391347762, atv: -19.215052518227512 },
      { ath: 128.62352618643186, atv: -19.318972870835328 },
    ],
    id: "hs66",
  },
  {
    points: [
      { ath: 128.63352915520218, atv: -19.314235953316985 },
      { ath: 128.6383778500036, atv: -19.21503874361266 },
      { ath: 128.82346632116284, atv: -19.2217708885393 },
      { ath: 128.8087563166467, atv: -19.33516392973801 },
    ],
    id: "hs67",
  },
  {
    points: [
      { ath: 128.82881511447192, atv: -19.33275351918319 },
      { ath: 128.83352021390084, atv: -19.2241066434397 },
      { ath: 128.99610014022113, atv: -19.22835762214585 },
      { ath: 128.9965522798714, atv: -19.348797020527403 },
    ],
    id: "hs68",
  },
  {
    points: [
      { ath: 129.0090136608188, atv: -19.34639376301343 },
      { ath: 129.00856140227705, atv: -19.228316198848773 },
      { ath: 129.17614190647862, atv: -19.23239089000542 },
      { ath: 129.17173214572847, atv: -19.355208757048615 },
    ],
    id: "hs69",
  },
  {
    points: [
      { ath: 129.18424567131157, atv: -19.35515422431563 },
      { ath: 129.1886576804006, atv: -19.234697853364313 },
      { ath: 129.3562638906048, atv: -19.243331031700396 },
      { ath: 129.36444549789425, atv: -19.356635577970803 },
    ],
    id: "hs70",
  },
  {
    points: [
      { ath: 129.37695042981335, atv: -19.361290810946553 },
      { ath: 129.37374735571433, atv: -19.24559894965521 },
      { ath: 129.5513265176291, atv: -19.24927685886506 },
      { ath: 129.54467864496615, atv: -19.369745829102136 },
    ],
    id: "hs71",
  },
  {
    points: [
      { ath: 129.5571906295426, atv: -19.369665775830658 },
      { ath: 129.56136269266452, atv: -19.25393529580595 },
      { ath: 129.7313936634985, atv: -19.252764718631557 },
      { ath: 129.73742825590006, atv: -19.377862375327762 },
    ],
    id: "hs72",
  },
  {
    points: [
      { ath: 129.69131301636793, atv: -19.243609953940947 },
      { ath: 129.68779778819442, atv: -19.116129446076794 },
      { ath: 129.49784958668943, atv: -19.10792897967775 },
      { ath: 129.51625288188686, atv: -19.240051485675277 },
    ],
    id: "hs73",
  },
  {
    points: [
      { ath: 129.5032446912762, atv: -19.24021953234777 },
      { ath: 129.49048841458955, atv: -19.108982996804137 },
      { ath: 129.31615186464165, atv: -19.101142770086074 },
      { ath: 129.32406987454922, atv: -19.234651095012477 },
    ],
    id: "hs74",
  },
  {
    points: [
      { ath: 129.30750346792874, atv: -19.230293787403983 },
      { ath: 129.30904742474985, atv: -19.10118335836335 },
      { ath: 129.12762118963855, atv: -19.088752506414263 },
      { ath: 129.13066041923997, atv: -19.22007137340546 },
    ],
    id: "hs75",
  },
  {
    points: [
      { ath: 129.12122055916697, atv: -19.21788903303054 },
      { ath: 129.1205326261974, atv: -19.084333066170103 },
      { ath: 128.9485807674259, atv: -19.080586552292583 },
      { ath: 128.9444237110218, atv: -19.216391064551186 },
    ],
    id: "hs76",
  },
  {
    points: [
      { ath: 128.9369490888692, atv: -19.21507947026027 },
      { ath: 128.94093713635667, atv: -19.081502337273584 },
      { ath: 128.75955047966528, atv: -19.073383860329674 },
      { ath: 128.7648723422603, atv: -19.21360379473994 },
    ],
    id: "hs77",
  },
  {
    points: [
      { ath: 128.75544373693336, atv: -19.213640264496714 },
      { ath: 128.75484976000618, atv: -19.075627889811827 },
      { ath: 128.5570276679598, atv: -19.08295556155499 },
      { ath: 128.55975063707547, atv: -19.200931681900126 },
    ],
    id: "hs78",
  },
  {
    points: [
      { ath: 128.54977332647977, atv: -19.19806136404885 },
      { ath: 128.54930631537403, atv: -19.077855298747092 },
      { ath: 128.35617922803007, atv: -19.082872881382787 },
      { ath: 128.361176912763, atv: -19.18748855582726 },
    ],
    id: "hs79",
  },
  {
    points: [
      { ath: 128.17734623849495, atv: -19.1878480958776 },
      { ath: 128.34706490131703, atv: -19.19197633823321 },
      { ath: 128.3491249080858, atv: -19.08066516344015 },
      { ath: 128.17952459470135, atv: -19.078763211680126 },
    ],
    id: "hs80",
  },
  {
    points: [
      { ath: 128.16791791576327, atv: -19.187861520441615 },
      { ath: 128.17009196637542, atv: -19.072098230615676 },
      { ath: 128.00756596644496, atv: -19.07225504883864 },
      { ath: 128.00526854764897, atv: -19.172434053273346 },
    ],
    id: "hs81",
  },
  {
    points: [
      { ath: 127.99796458448296, atv: -19.171624501499224 },
      { ath: 128.00003871716612, atv: -19.07144386269284 },
      { ath: 127.83044033363214, atv: -19.06511314402577 },
      { ath: 127.82120452182966, atv: -19.17198311907817 },
    ],
    id: "hs82",
  },
  {
    points: [
      { ath: 127.81177339674579, atv: -19.16977134067256 },
      { ath: 127.81865226156708, atv: -19.058453090447532 },
      { ath: 127.64436211899647, atv: -19.056411565183033 },
      { ath: 127.63736278507574, atv: -19.163271943787542 },
    ],
    id: "hs83",
  },
  {
    points: [
      { ath: 127.62884770431066, atv: -19.16373574197218 },
      { ath: 127.63789340501819, atv: -19.052403696632197 },
      { ath: 127.4541846536298, atv: -19.046192467836487 },
      { ath: 127.45443614186547, atv: -19.155272751184732 },
    ],
    id: "hs84",
  },
  {
    points: [
      { ath: 127.44265828726256, atv: -19.157522770436692 },
      { ath: 127.44239966727261, atv: -19.04176395519323 },
      { ath: 127.26342053929238, atv: -19.04203281615834 },
      { ath: 127.25175819385325, atv: -19.146674269306338 },
    ],
    id: "hs85",
  },
  {
    points: [
      { ath: 127.23973093889066, atv: -19.148124561253336 },
      { ath: 127.25347897247116, atv: -19.036777697165686 },
      { ath: 127.07685746247114, atv: -19.032791023334973 },
      { ath: 127.07476652906246, atv: -19.14187609051865 },
    ],
    id: "hs86",
  },
  {
    points: [
      { ath: 127.06534107070567, atv: -19.141896205011978 },
      { ath: 127.07213715253314, atv: -19.028348904760243 },
      { ath: 126.91200555511966, atv: -19.024169431183545 },
      { ath: 126.90981834081106, atv: -19.142158750044985 },
    ],
    id: "hs87",
  },
  {
    points: [
      { ath: 126.90274902639419, atv: -19.142167576792062 },
      { ath: 126.90493510379312, atv: -19.01972598725068 },
      { ath: 126.70949289358782, atv: -19.015412238034227 },
      { ath: 126.71894269841789, atv: -19.13339751535307 },
    ],
    id: "hs88",
  },
  {
    points: [
      { ath: 126.71103418014732, atv: -19.13259788214101 },
      { ath: 126.70122636830109, atv: -19.014643384323975 },
      { ath: 126.53171105126569, atv: -19.019506593510382 },
      { ath: 126.53431939792773, atv: -19.133034466736614 },
    ],
    id: "hs89",
  },
  {
    points: [
      { ath: 126.52720960785663, atv: -19.130823754283007 },
      { ath: 126.52695588445735, atv: -19.01506504088144 },
      { ath: 126.34801296926716, atv: -19.022005161820598 },
      { ath: 126.34813122404609, atv: -19.128860963894166 },
    ],
    id: "hs90",
  },
  {
    points: [
      { ath: 126.34106006725503, atv: -19.12664163578248 },
      { ath: 126.33858701736636, atv: -19.01533570154384 },
      { ath: 126.1572784011266, atv: -19.01541604077108 },
      { ath: 126.15727272841963, atv: -19.12895088120167 },
    ],
    id: "hs91",
  },
  {
    points: [
      { ath: 126.15024023176187, atv: -19.126722774719003 },
      { ath: 126.14789613706344, atv: -19.01318773535291 },
      { ath: 125.96423835393708, atv: -19.010853483127022 },
      { ath: 125.96881745130668, atv: -19.119940338896615 },
    ],
    id: "hs92",
  },
  {
    points: [
      { ath: 125.9617518145379, atv: -19.117706528189796 },
      { ath: 125.95483997124751, atv: -18.995260021273204 },
      { ath: 125.77120905982284, atv: -18.992735491899115 },
      { ath: 125.77328286685756, atv: -19.110724743871483 },
    ],
    id: "hs93",
  },
  {
    points: [
      { ath: 125.76625631985476, atv: -19.110711633697417 },
      { ath: 125.76420922408414, atv: -18.983817980477085 },
      { ath: 125.57820820472452, atv: -18.992228545389246 },
      { ath: 125.58013589022713, atv: -19.112442898913127 },
    ],
    id: "hs94",
  },
  {
    points: [
      { ath: 125.57075974055564, atv: -19.110184669647296 },
      { ath: 125.56887919054333, atv: -18.978840236176996 },
      { ath: 125.3829174249457, atv: -18.980377732362594 },
      { ath: 125.38937148895371, atv: -19.10951324678818 },
    ],
    id: "hs95",
  },
  {
    points: [
      { ath: 125.38230470557806, atv: -19.109483493321896 },
      { ath: 125.3758561916053, atv: -18.980347928172154 },
      { ath: 125.1946266831444, atv: -18.979491400583257 },
      { ath: 125.20803756791727, atv: -19.101986664595657 },
    ],
    id: "hs96",
  },
  {
    points: [
      { ath: 125.19388069412153, atv: -18.966554215323114 },
      { ath: 125.37744230001994, atv: -18.969625204344908 },
      { ath: 125.37342279672106, atv: -18.82046536662892 },
      { ath: 125.18532288035149, atv: -18.817372987445903 },
    ],
    id: "hs97",
  },
  {
    points: [
      { ath: 125.18535055900631, atv: -18.806243561826317 },
      { ath: 125.37344821503015, atv: -18.8071094168566 },
      { ath: 125.36702493803776, atv: -18.67129742608738 },
      { ath: 125.18379171424888, atv: -18.668229403780845 },
    ],
    id: "hs98",
  },
  {
    points: [
      { ath: 125.18389166177917, atv: -18.657100437528744 },
      { ath: 125.36711270066618, atv: -18.66016791675751 },
      { ath: 125.35601602110441, atv: -18.52211408248067 },
      { ath: 125.16354600341415, atv: -18.521219690768135 },
    ],
    id: "hs99",
  },
  {
    points: [
      { ath: 125.16600000749327, atv: -18.510101942374867 },
      { ath: 125.36079423090942, atv: -18.51322890384352 },
      { ath: 125.35914565297617, atv: -18.364090232892295 },
      { ath: 125.15743809585888, atv: -18.36983527743042 },
    ],
    id: "hs100",
  },
  {
    points: [
      { ath: 125.1567505695908, atv: -18.35281006745128 },
      { ath: 125.35841506745683, atv: -18.353726629851916 },
      { ath: 125.34728358780362, atv: -18.224578301715603 },
      { ath: 125.15282798194087, atv: -18.219240687811546 },
    ],
    id: "hs101",
  },
  {
    points: [
      { ath: 125.15523465268737, atv: -18.208123921494913 },
      { ath: 125.34734496098716, atv: -18.211223272852997 },
      { ath: 125.33393154933592, atv: -18.07316746543856 },
      { ath: 125.14429371327273, atv: -18.074524572896284 },
    ],
    id: "hs102",
  },
  {
    points: [
      { ath: 125.38353595406974, atv: -18.96535755355585 },
      { ath: 125.56328755020007, atv: -18.95823463519486 },
      { ath: 125.56528995959488, atv: -18.818882898181748 },
      { ath: 125.37925158044214, atv: -18.81680136910072 },
    ],
    id: "hs103",
  },
  {
    points: [
      { ath: 125.37768712354054, atv: -18.80301375144241 },
      { ath: 125.5669497128952, atv: -18.803573022348928 },
      { ath: 125.56566836685369, atv: -18.681058541981322 },
      { ath: 125.37336445400396, atv: -18.66670848317032 },
    ],
    id: "hs104",
  },
  {
    points: [
      { ath: 125.37175892152581, atv: -18.655983685092597 },
      { ath: 125.54793877205137, atv: -18.651918282263477 },
      { ath: 125.54827872046104, atv: -18.532472518154762 },
      { ath: 125.36581192104083, atv: -18.524268775190638 },
    ],
    id: "hs105",
  },
  {
    points: [
      { ath: 125.36748679976478, atv: -18.50896148733613 },
      { ath: 125.54834403987127, atv: -18.509502434445608 },
      { ath: 125.54386866593472, atv: -18.380859867392694 },
      { ath: 125.36962171173784, atv: -18.37574694799067 },
    ],
    id: "hs106",
  },
  {
    points: [
      { ath: 125.36775193341612, atv: -18.35249190532295 },
      { ath: 125.54522230048923, atv: -18.35294221375616 },
      { ath: 125.53904178410366, atv: -18.238078989745855 },
      { ath: 125.355248052669, atv: -18.236074958626503 },
    ],
    id: "hs107",
  },
  {
    points: [
      { ath: 125.3585725032462, atv: -18.20699082174125 },
      { ath: 125.5310581497676, atv: -18.20590462299268 },
      { ath: 125.52654470589067, atv: -18.07420578399048 },
      { ath: 125.33969664062838, atv: -18.07524661471119 },
    ],
    id: "hs108",
  },
  {
    points: [
      { ath: 125.57295492268679, atv: -18.96708753507999 },
      { ath: 125.74621448637149, atv: -18.971945102470283 },
      { ath: 125.752845060922, atv: -18.835657186977347 },
      { ath: 125.57487062781075, atv: -18.830798072476508 },
    ],
    id: "hs109",
  },
  {
    points: [
      { ath: 125.57653497907941, atv: -18.80936173808217 },
      { ath: 125.75611159646377, atv: -18.80809495252324 },
      { ath: 125.7610915990999, atv: -18.69018010606293 },
      { ath: 125.5735732759139, atv: -18.68378063233471 },
    ],
    id: "hs110",
  },
  {
    points: [
      { ath: 125.57524299030428, atv: -18.659281340496452 },
      { ath: 125.75789001741697, atv: -18.66107985878552 },
      { ath: 125.76447813332581, atv: -18.544697831316682 },
      { ath: 125.57065964823326, atv: -18.538291317102836 },
    ],
    id: "hs111",
  },
  {
    points: [
      { ath: 125.74203287081727, atv: -18.377751612107662 },
      { ath: 125.54999521314173, atv: -18.382047941845187 },
    ],
    id: "hs112",
  },
  {
    points: [
      { ath: 125.55455609935942, atv: -18.50456776542489 },
      { ath: 125.73864889466634, atv: -18.507917166037682 },
      { ath: 125.74042830393284, atv: -18.370092995089635 },
      { ath: 125.54841354615067, atv: -18.36826240975692 },
    ],
    id: "hs113",
  },
  {
    points: [
      { ath: 125.55327529005561, atv: -18.359084189158963 },
      { ath: 125.74043925940717, atv: -18.36090470873517 },
      { ath: 125.73897284521388, atv: -18.238394241825254 },
      { ath: 125.54549462661123, atv: -18.23656108637985 },
    ],
    id: "hs114",
  },
  {
    points: [
      { ath: 125.53911091233192, atv: -18.208984242875133 },
      { ath: 125.73416582145833, atv: -18.213887759517625 },
      { ath: 125.73432180688391, atv: -18.08679014274274 },
      { ath: 125.53619461093106, atv: -18.080351182430125 },
    ],
    id: "hs115",
  },
  {
    points: [
      { ath: 125.75053607317656, atv: -18.969252697572784 },
      { ath: 125.94970835813945, atv: -18.971159607547378 },
      { ath: 125.93858995452891, atv: -18.83178630642067 },
      { ath: 125.76222561534968, atv: -18.832987509862757 },
    ],
    id: "hs116",
  },
  {
    points: [
      { ath: 125.76228931217952, atv: -18.8084853488523 },
      { ath: 125.94832557834752, atv: -18.813422686488032 },
      { ath: 125.95012113262501, atv: -18.69397418536351 },
      { ath: 125.7706864267804, atv: -18.68752424954447 },
    ],
    id: "hs117",
  },
  {
    points: [
      { ath: 125.76910538349608, atv: -18.66148702179993 },
      { ath: 125.95014018706715, atv: -18.660283070005207 },
      { ath: 125.95515214160008, atv: -18.548496252178424 },
      { ath: 125.77263703846143, atv: -18.54357776536445 },
    ],
    id: "hs118",
  },
  {
    points: [
      { ath: 125.77111140057895, atv: -18.508352517028904 },
      { ath: 125.9358333722673, atv: -18.507122307747196 },
      { ath: 125.9392482361161, atv: -18.38767889607385 },
      { ath: 125.75206642441248, atv: -18.382736881238856 },
    ],
    id: "hs119",
  },
  {
    points: [
      { ath: 125.7569596526362, atv: -18.362840867157004 },
      { ath: 125.94089263338597, atv: -18.367773369144782 },
      { ath: 125.9426886826418, atv: -18.24833111298136 },
      { ath: 125.74921931829533, atv: -18.24031763290856 },
    ],
    id: "hs120",
  },
  {
    points: [
      { ath: 125.74606463712354, atv: -18.214278403803032 },
      { ath: 125.92983575238529, atv: -18.219218524374526 },
      { ath: 125.93003432799188, atv: -18.095185760085638 },
      { ath: 125.74317163772312, atv: -18.091771993600908 },
    ],
    id: "hs121",
  },
  {
    points: [
      { ath: 125.95379723360105, atv: -18.96818491990859 },
      { ath: 126.12867983276783, atv: -18.973027586849362 },
      { ath: 126.12072464829151, atv: -18.84438036432042 },
      { ath: 125.94922207496518, atv: -18.8349439054435 },
    ],
    id: "hs122",
  },
  {
    points: [
      { ath: 125.95571251578127, atv: -18.81504754721715 },
      { ath: 126.14823605544072, atv: -18.8168395380814 },
      { ath: 126.15642896585936, atv: -18.700457635259493 },
      { ath: 125.95920696453243, atv: -18.68947836556974 },
    ],
    id: "hs123",
  },
  {
    points: [
      { ath: 125.96251952417077, atv: -18.661919368026396 },
      { ath: 126.15485726588952, atv: -18.675953860779764 },
      { ath: 126.1630465679676, atv: -18.55038432380795 },
      { ath: 125.96921770408875, atv: -18.545545423970257 },
    ],
    id: "hs124",
  },
  {
    points: [
      { ath: 125.95151121889958, atv: -18.516417051814166 },
      { ath: 126.12754813485415, atv: -18.5136008223533 },
      { ath: 126.13089581311618, atv: -18.400282343813878 },
      { ath: 125.94694034172912, atv: -18.387774840565292 },
    ],
    id: "hs125",
  },
  {
    points: [
      { ath: 125.94860273727059, atv: -18.364808058516193 },
      { ath: 126.13899864552391, atv: -18.366599942754195 },
      { ath: 126.13759078067605, atv: -18.24562584241638 },
      { ath: 125.9505559978976, atv: -18.2438418162789 },
    ],
    id: "hs126",
  },
  {
    points: [
      { ath: 125.9409036167196, atv: -18.219323890010784 },
      { ath: 126.13758094889508, atv: -18.224188070688978 },
      { ath: 126.13770797141089, atv: -18.09556433425159 },
      { ath: 125.94278467890942, atv: -18.09376778732227 },
    ],
    id: "hs127",
  },
  {
    points: [
      { ath: 126.13237847653406, atv: -18.971645529133745 },
      { ath: 126.30402356542507, atv: -18.969189103637245 },
      { ath: 126.30928451168799, atv: -18.8497582545988 },
      { ath: 126.1280736582176, atv: -18.84759081191613 },
    ],
    id: "hs128",
  },
  {
    points: [
      { ath: 126.15412499617858, atv: -18.815534386846956 },
      { ath: 126.3595652962072, atv: -18.81927547580155 },
      { ath: 126.36316090340614, atv: -18.701368782760746 },
      { ath: 126.16433031408758, atv: -18.69766031136858 },
    ],
    id: "hs129",
  },
  {
    points: [
      { ath: 126.36765895598666, atv: -18.817766210878755 },
      { ath: 126.55856340044804, atv: -18.81818674236 },
      { ath: 126.56201395939888, atv: -18.703336474585498 },
      { ath: 126.36962298069759, atv: -18.702917636560507 },
    ],
    id: "hs130",
  },
  {
    points: [
      { ath: 126.56664993558758, atv: -18.819731685164456 },
      { ath: 126.75270621897229, atv: -18.8245409547415 },
      { ath: 126.7527760800474, atv: -18.729592292344762 },
      { ath: 126.57168405211937, atv: -18.720197882718427 },
    ],
    id: "hs131",
  },
  {
    points: [
      { ath: 126.76082100168358, atv: -18.82607710830517 },
      { ath: 126.94850938405074, atv: -18.829161829670973 },
      { ath: 126.95493574379768, atv: -18.731147570803483 },
      { ath: 126.76250648557422, atv: -18.72653511803955 },
    ],
    id: "hs132",
  },
  {
    points: [
      { ath: 126.95824347451133, atv: -18.829157410382418 },
      { ath: 127.14594448165096, atv: -18.836638827245174 },
      { ath: 127.15395682014184, atv: -18.735553073365246 },
      { ath: 126.96960502092588, atv: -18.732673017968562 },
    ],
    id: "hs133",
  },
  {
    points: [
      { ath: 126.3139179909011, atv: -18.9661614234697 },
      { ath: 126.49202385283272, atv: -18.97120663461993 },
      { ath: 126.48744147638823, atv: -18.851747782654883 },
      { ath: 126.31744777818014, atv: -18.851312596170274 },
    ],
    id: "hs134",
  },
  {
    points: [
      { ath: 126.50164881343159, atv: -18.97122120588312 },
      { ath: 126.68949051873167, atv: -18.974567232014667 },
      { ath: 126.69124168933772, atv: -18.85511795873131 },
      { ath: 126.50191519834652, atv: -18.85177222082998 },
    ],
    id: "hs135",
  },
  {
    points: [
      { ath: 126.69918258957324, atv: -18.973045762168944 },
      { ath: 126.87083904431356, atv: -18.985381423861636 },
      { ath: 126.86922449660375, atv: -18.870524230088723 },
      { ath: 126.70253874456944, atv: -18.859723544371718 },
    ],
    id: "hs136",
  },
  {
    points: [
      { ath: 126.88058218766648, atv: -18.985381020812312 },
      { ath: 127.04901726736418, atv: -18.98989544522899 },
      { ath: 127.04241803791638, atv: -18.86738758020942 },
      { ath: 126.88219784232922, atv: -18.870523843361784 },
    ],
    id: "hs137",
  },
  {
    points: [
      { ath: 127.06038733949714, atv: -18.994479277777945 },
      { ath: 127.2013000026121, atv: -18.998881014913735 },
      { ath: 127.20915543371635, atv: -18.876354584904956 },
      { ath: 127.0537780565974, atv: -18.870440492390316 },
    ],
    id: "hs138",
  },
  {
    points: [
      { ath: 126.16774670688014, atv: -18.6731711017592 },
      { ath: 126.3487870933188, atv: -18.67070096191575 },
      { ath: 126.35239665445971, atv: -18.54973332309809 },
      { ath: 126.16987101962516, atv: -18.552204622574607 },
    ],
    id: "hs139",
  },
  {
    points: [
      { ath: 126.35851344275551, atv: -18.670729860730397 },
      { ath: 126.5492357545453, atv: -18.678813649397984 },
      { ath: 126.54624621271861, atv: -18.55476544235811 },
      { ath: 126.36049613675522, atv: -18.551288825545214 },
    ],
    id: "hs140",
  },
  {
    points: [
      { ath: 126.5573154917796, atv: -18.68035888917634 },
      { ath: 126.74644807508602, atv: -18.688241068791992 },
      { ath: 126.75299907759327, atv: -18.573389340326752 },
      { ath: 126.55431703544431, atv: -18.557842075830486 },
    ],
    id: "hs141",
  },
  {
    points: [
      { ath: 126.75453137751438, atv: -18.688246468566085 },
      { ath: 126.94853331778114, atv: -18.69286649919823 },
      { ath: 126.95171545312112, atv: -18.57800893468406 },
      { ath: 126.76269450085377, atv: -18.570332598854854 },
    ],
    id: "hs142",
  },
  {
    points: [
      { ath: 126.95661759426696, atv: -18.694394670645863 },
      { ath: 127.13122482258007, atv: -18.695771263811746 },
      { ath: 127.13428702047074, atv: -18.58397438246046 },
      { ath: 126.96464030376907, atv: -18.57800337654676 },
    ],
    id: "hs143",
  },
  {
    points: [
      { ath: 126.13612188964805, atv: -18.51379367949184 },
      { ath: 126.32665341484545, atv: -18.519033724545697 },
      { ath: 126.32057292823646, atv: -18.40416722728233 },
      { ath: 126.13662639689312, atv: -18.398950268101032 },
    ],
    id: "hs144",
  },
  {
    points: [
      { ath: 126.33634284599324, atv: -18.519061486615907 },
      { ath: 126.5042974162711, atv: -18.519464290638435 },
      { ath: 126.50131309885418, atv: -18.409201239536763 },
      { ath: 126.33026070024667, atv: -18.402663845750695 },
    ],
    id: "hs145",
  },
  {
    points: [
      { ath: 126.51398402930411, atv: -18.52101436837568 },
      { ath: 126.69647896972424, atv: -18.524337452871478 },
      { ath: 126.6965951507216, atv: -18.41561016421538 },
      { ath: 126.51261720571586, atv: -18.406160426035196 },
    ],
    id: "hs146",
  },
  {
    points: [
      { ath: 126.70777992878365, atv: -18.52894198689974 },
      { ath: 126.88382964207395, atv: -18.529016753620716 },
      { ath: 126.88705333963827, atv: -18.42335139525988 },
      { ath: 126.70627938078474, atv: -18.415619036198756 },
    ],
    id: "hs147",
  },
  {
    points: [
      { ath: 126.89352362258501, atv: -18.559644660155637 },
      { ath: 127.07445494066963, atv: -18.562605089944995 },
      { ath: 127.07590720769048, atv: -18.42477962050903 },
      { ath: 126.89673742488, atv: -18.4172257397863 },
    ],
    id: "hs148",
  },
  {
    points: [
      { ath: 126.1473963650705, atv: -18.384139604790064 },
      { ath: 126.34425578392103, atv: -18.38771262460143 },
      { ath: 126.34456790727461, atv: -18.254486610972076 },
      { ath: 126.14623983385238, atv: -18.25397520477116 },
    ],
    id: "hs149",
  },
  {
    points: [
      { ath: 126.3507459246832, atv: -18.38466366674023 },
      { ath: 126.54438747152088, atv: -18.391087121433323 },
      { ath: 126.54131567095584, atv: -18.259386591011147 },
      { ath: 126.35426736378415, atv: -18.256038089725315 },
    ],
    id: "hs150",
  },
  {
    points: [
      { ath: 126.55404398406648, atv: -18.389565046020092 },
      { ath: 126.74447493706464, atv: -18.392720159652715 },
      { ath: 126.74608562607492, atv: -18.259490761483626 },
      { ath: 126.5509595263114, atv: -18.262458670289305 },
    ],
    id: "hs151",
  },
  {
    points: [
      { ath: 126.75093044159988, atv: -18.394251362585337 },
      { ath: 126.95267663688304, atv: -18.404855356123328 },
      { ath: 126.95735911645221, atv: -18.279277897421466 },
      { ath: 126.76221206834688, atv: -18.264083795104156 },
    ],
    id: "hs152",
  },
  {
    points: [
      { ath: 126.14630522308991, atv: -18.227944235246238 },
      { ath: 126.33815401056933, atv: -18.228441458585635 },
      { ath: 126.34007431535463, atv: -18.098287155051516 },
      { ath: 126.14515594850974, atv: -18.096254271447204 },
    ],
    id: "hs153",
  },
  {
    points: [
      { ath: 126.34456044247301, atv: -18.234579607481376 },
      { ath: 126.54932587427803, atv: -18.233361668186586 },
      { ath: 126.55268368613795, atv: -18.11392337291805 },
      { ath: 126.34970505368642, atv: -18.101368965053574 },
    ],
    id: "hs154",
  },
  {
    points: [
      { ath: 126.55741561254104, atv: -18.233370002870537 },
      { ath: 126.73960971868584, atv: -18.23039569940341 },
      { ath: 126.75088991753856, atv: -18.11554731570527 },
      { ath: 126.56560134035513, atv: -18.113935973618158 },
    ],
    id: "hs155",
  },
  {
    points: [
      { ath: 126.7485013552567, atv: -18.23596536278472 },
      { ath: 126.95968720579, atv: -18.25473182699973 },
      { ath: 126.95163393451395, atv: -18.248595806118843 },
      { ath: 126.95342233277773, atv: -18.132218166079085 },
      { ath: 126.76492504207494, atv: -18.1211575523352 },
    ],
    id: "hs156",
  },
  {
    points: [
      { ath: 126.96775199764372, atv: -18.25321081310227 },
      { ath: 126.96953809566514, atv: -18.130707585819092 },
      { ath: 127.15484336162339, atv: -18.13544328096724 },
      { ath: 127.1499661096255, atv: -18.256416911667817 },
    ],
    id: "hs157",
  },
  {
    points: [
      { ath: 127.15802940185574, atv: -18.25488791898796 },
      { ath: 127.34831263186783, atv: -18.25790840281028 },
      { ath: 127.35627826802687, atv: -18.14458416098751 },
      { ath: 127.16773508762711, atv: -18.133915249314274 },
    ],
    id: "hs158",
  },
  {
    points: [
      { ath: 126.95701475916707, atv: -18.399852460683167 },
      { ath: 126.96568013915635, atv: -18.27892538348738 },
      { ath: 127.14947490075596, atv: -18.287289525028804 },
      { ath: 127.152248514983, atv: -18.40520565135606 },
    ],
    id: "hs159",
  },
  {
    points: [
      { ath: 127.15868262324798, atv: -18.405226510672154 },
      { ath: 127.35879236525349, atv: -18.407322720498755 },
      { ath: 127.3526446195956, atv: -18.290929762705378 },
      { ath: 127.15751712221152, atv: -18.287315719917785 },
    ],
    id: "hs160",
  },
  {
    points: [
      { ath: 127.08437925023895, atv: -18.42180739318993 },
      { ath: 127.26672968663527, atv: -18.43005849876903 },
      { ath: 127.26469375027375, atv: -18.563279044367945 },
      { ath: 127.08217981284014, atv: -18.559615420163713 },
    ],
    id: "hs161",
  },
  {
    points: [
      { ath: 127.14021560470357, atv: -18.58126554128804 },
      { ath: 127.31790659992865, atv: -18.586393129106334 },
      { ath: 127.31107643556689, atv: -18.71348010487877 },
      { ath: 127.13486249017518, atv: -18.70987946333759 },
    ],
    id: "hs162",
  },
  {
    points: [
      { ath: 127.15899436040823, atv: -18.7329336445943 },
      { ath: 127.34169408679719, atv: -18.739590895483133 },
      { ath: 127.34460190561799, atv: -18.860578219251842 },
      { ath: 127.15205028639511, atv: -18.856949847372054 },
    ],
    id: "hs163",
  },
  {
    points: [
      { ath: 127.215093000796, atv: -18.87553325964667 },
      { ath: 127.42060435811379, atv: -18.885249506237685 },
      { ath: 127.41869527697855, atv: -19.013883090480558 },
      { ath: 127.2097823481302, atv: -19.005682401700373 },
    ],
    id: "hs164",
  },
  {
    points: [
      { ath: 127.27309671644821, atv: -18.431608405956702 },
      { ath: 127.46033480052913, atv: -18.432031670328335 },
      { ath: 127.45844573955674, atv: -18.568320090149232 },
      { ath: 127.27268121035962, atv: -18.56483365721329 },
    ],
    id: "hs165",
  },
  {
    points: [
      { ath: 127.32265379899764, atv: -18.592530746878325 },
      { ath: 127.50845194868836, atv: -18.595968807446965 },
      { ath: 127.50177856679127, atv: -18.715408228123028 },
      { ath: 127.33037880300375, atv: -18.719654745081677 },
    ],
    id: "hs166",
  },
  {
    points: [
      { ath: 127.34650766932958, atv: -18.74419579452205 },
      { ath: 127.54217322285149, atv: -18.750689531427824 },
      { ath: 127.5387661953996, atv: -18.86247864896807 },
      { ath: 127.34944767660963, atv: -18.854463408766026 },
    ],
    id: "hs167",
  },
  {
    points: [
      { ath: 127.42380583199954, atv: -18.889849243823644 },
      { ath: 127.61640911346294, atv: -18.896261614538 },
      { ath: 127.6292350034538, atv: -19.018787140724275 },
      { ath: 127.42515574293327, atv: -19.010832465775326 },
    ],
    id: "hs168",
  },
  {
    points: [
      { ath: 127.3532135089857, atv: -18.257495630349503 },
      { ath: 127.55312129388949, atv: -18.26284845472041 },
      { ath: 127.55679007081429, atv: -18.1434271705202 },
      { ath: 127.36182661327697, atv: -18.144228106862755 },
    ],
    id: "hs169",
  },
  {
    points: [
      { ath: 127.5611551966499, atv: -18.262874478021622 },
      { ath: 127.74013074988733, atv: -18.266433212057688 },
      { ath: 127.74529093491543, atv: -18.14241509704696 },
      { ath: 127.56321263807965, atv: -18.141916567718845 },
    ],
    id: "hs170",
  },
  {
    points: [
      { ath: 127.35781909464484, atv: -18.294263716713346 },
      { ath: 127.56262614415817, atv: -18.29503524213954 },
      { ath: 127.55570768409666, atv: -18.420576908509023 },
      { ath: 127.36207511129285, atv: -18.41371686440155 },
    ],
    id: "hs171",
  },
  {
    points: [
      { ath: 127.46361518313802, atv: -18.437097186414892 },
      { ath: 127.6443772691866, atv: -18.442287851647205 },
      { ath: 127.63748865661702, atv: -18.575494795963436 },
      { ath: 127.46303897904286, atv: -18.57184918332391 },
    ],
    id: "hs172",
  },
  {
    points: [
      { ath: 127.5146382030085, atv: -18.59500825008233 },
      { ath: 127.6955787198878, atv: -18.598623874333807 },
      { ath: 127.69195358683208, atv: -18.73337602467866 },
      { ath: 127.50603650907436, atv: -18.725141571576515 },
    ],
    id: "hs173",
  },
  {
    points: [
      { ath: 127.54634101934852, atv: -18.755909499232768 },
      { ath: 127.74201223064674, atv: -18.759533976675176 },
      { ath: 127.73846948699239, atv: -18.875911807789407 },
      { ath: 127.54268073815314, atv: -18.86768619270559 },
    ],
    id: "hs174",
  },
  { points: [], id: "hs175" },
  {
    points: [
      { ath: 127.6344291977374, atv: -19.014987570392154 },
      { ath: 127.626716898101, atv: -18.89858086627489 },
      { ath: 127.80475822055425, atv: -18.900558493865013 },
      { ath: 127.80126986114959, atv: -19.010811856882302 },
    ],
    id: "hs176",
  },
  {
    points: [
      { ath: 127.80931971974366, atv: -19.020018001250257 },
      { ath: 127.98587123223083, atv: -19.027947231349884 },
      { ath: 127.98278378513868, atv: -18.900836581903473 },
      { ath: 127.80959280712787, atv: -18.89750686447142 },
    ],
    id: "hs177",
  },
  {
    points: [
      { ath: 127.74168005553491, atv: -18.871324717162604 },
      { ath: 127.92941119004209, atv: -18.871674469889346 },
      { ath: 127.93282424676511, atv: -18.753759564778402 },
      { ath: 127.74522844617084, atv: -18.751883996944724 },
    ],
    id: "hs178",
  },
  {
    points: [
      { ath: 127.69680331120327, atv: -18.73032390455619 },
      { ath: 127.88114691364362, atv: -18.727647922813304 },
      { ath: 127.88621482060248, atv: -18.605143236509814 },
      { ath: 127.7004200979666, atv: -18.597102967387713 },
    ],
    id: "hs179",
  },
  {
    points: [
      { ath: 127.64394034129094, atv: -18.575511327368577 },
      { ath: 127.83779366923349, atv: -18.57902719098103 },
      { ath: 127.84128662501269, atv: -18.450398045561418 },
      { ath: 127.65404259392585, atv: -18.44537563819218 },
    ],
    id: "hs180",
  },
  {
    points: [
      { ath: 127.84425284544767, atv: -18.58057027100299 },
      { ath: 128.02197107797343, atv: -18.579274222183237 },
      { ath: 128.03016960842658, atv: -18.44758135175762 },
      { ath: 127.85097869507186, atv: -18.447352506517646 },
    ],
    id: "hs181",
  },
  {
    points: [
      { ath: 127.56053316579741, atv: -18.414466974514433 },
      { ath: 127.75902583355202, atv: -18.422668325982816 },
      { ath: 127.75287517211618, atv: -18.30321224513317 },
      { ath: 127.5674141979728, atv: -18.29811258685617 },
    ],
    id: "hs182",
  },
  {
    points: [
      { ath: 127.74655059866436, atv: -18.26338488224827 },
      { ath: 127.93843701356656, atv: -18.2683195001296 },
      { ath: 127.93860417797944, atv: -18.15347504229886 },
      { ath: 127.75167850818767, atv: -18.150084982256026 },
    ],
    id: "hs183",
  },
  {
    points: [
      { ath: 127.98916473698841, atv: -19.02641800137493 },
      { ath: 128.17059590550244, atv: -19.018862917477986 },
      { ath: 128.16412996790416, atv: -18.90706857251257 },
      { ath: 127.98768817402791, atv: -18.90390334203846 },
    ],
    id: "hs184",
  },
  {
    points: [
      { ath: 128.17383502687235, atv: -19.028051593807024 },
      { ath: 128.3455508654633, atv: -19.03104482257091 },
      { ath: 128.3519131413088, atv: -18.91312026570019 },
      { ath: 128.17222415872604, atv: -18.902474985984192 },
    ],
    id: "hs185",
  },
  {
    points: [
      { ath: 127.9342955284933, atv: -18.886993935335465 },
      { ath: 128.11881392808277, atv: -18.88255530232543 },
      { ath: 128.11724671219997, atv: -18.753914380435607 },
      { ath: 127.9377346757816, atv: -18.749170482058837 },
    ],
    id: "hs186",
  },
  {
    points: [
      { ath: 128.12528591431789, atv: -18.888683179187247 },
      { ath: 128.30494758304124, atv: -18.88559078900878 },
      { ath: 128.30647499577364, atv: -18.760012461091158 },
      { ath: 128.1253326636081, atv: -18.755448523578305 },
    ],
    id: "hs187",
  },
  {
    points: [
      { ath: 128.31144946712627, atv: -18.88865028507573 },
      { ath: 128.4927434067129, atv: -18.89609635519386 },
      { ath: 128.50059472429638, atv: -18.767444383199653 },
      { ath: 128.31134892408465, atv: -18.75541588092268 },
    ],
    id: "hs188",
  },
  {
    points: [
      { ath: 127.88509588312962, atv: -18.72750941696652 },
      { ath: 128.0710482502064, atv: -18.730856322390004 },
      { ath: 128.0663385525487, atv: -18.611400584843185 },
      { ath: 127.89020974849802, atv: -18.609601760710518 },
    ],
    id: "hs189",
  },
  {
    points: [
      { ath: 128.07754072257725, atv: -18.732393696104324 },
      { ath: 128.26188877665808, atv: -18.732487848841387 },
      { ath: 128.25381167739502, atv: -18.61609895355113 },
      { ath: 128.07606515678663, atv: -18.606815689662902 },
    ],
    id: "hs190",
  },
  {
    points: [
      { ath: 128.26997428414904, atv: -18.734019282193017 },
      { ath: 128.45109487962745, atv: -18.738522602609887 },
      { ath: 128.45419225235509, atv: -18.61600577265459 },
      { ath: 128.26189156644054, atv: -18.61609929821284 },
    ],
    id: "hs191",
  },
  {
    points: [
      { ath: 128.0261147024577, atv: -18.585320328915202 },
      { ath: 128.21514839833054, atv: -18.585463650034058 },
      { ath: 128.22002850964188, atv: -18.45988996206281 },
      { ath: 128.03598912980712, atv: -18.452101483219703 },
    ],
    id: "hs192",
  },
  {
    points: [
      { ath: 128.22322644041216, atv: -18.58699699249207 },
      { ath: 128.4106498475319, atv: -18.590007922118605 },
      { ath: 128.40730811493847, atv: -18.459841620816682 },
      { ath: 128.2297163418531, atv: -18.455297819217545 },
    ],
    id: "hs193",
  },
  {
    points: [
      { ath: 127.76161646170112, atv: -18.420949104217986 },
      { ath: 127.94723297678655, atv: -18.41981595323494 },
      { ath: 127.94906161050525, atv: -18.304969147164908 },
      { ath: 127.75711507847916, atv: -18.307620823282395 },
    ],
    id: "hs194",
  },
  {
    points: [
      { ath: 127.9585032640575, atv: -18.42136483189411 },
      { ath: 128.14250971599262, atv: -18.421570753462447 },
      { ath: 128.13935863234968, atv: -18.317436952522268 },
      { ath: 127.95708993346099, atv: -18.311106551637515 },
    ],
    id: "hs195",
  },
  {
    points: [
      { ath: 128.15703572392528, atv: -18.42311083425573 },
      { ath: 128.35396022324068, atv: -18.424651350206535 },
      { ath: 128.35390823023252, atv: -18.32664476074184 },
      { ath: 128.1474252582575, atv: -18.315910706008314 },
    ],
    id: "hs196",
  },
  {
    points: [
      { ath: 128.3588026453028, atv: -18.424649018431907 },
      { ath: 128.54604637581647, atv: -18.42752745617602 },
      { ath: 128.55070691566132, atv: -18.32032680838933 },
      { ath: 128.36197336392024, atv: -18.325109496254804 },
    ],
    id: "hs197",
  },
  {
    points: [
      { ath: 128.55251075598838, atv: -18.432111893889527 },
      { ath: 128.74462003678536, atv: -18.440913492971468 },
      { ath: 128.74431518794188, atv: -18.33372211296348 },
      { ath: 128.56039898432746, atv: -18.327968699643012 },
    ],
    id: "hs198",
  },
  {
    points: [
      { ath: 127.94423893722072, atv: -18.268210622716808 },
      { ath: 128.12807823077702, atv: -18.26842856340431 },
      { ath: 128.12978114321993, atv: -18.155115361989065 },
      { ath: 127.94606504473066, atv: -18.154900406853518 },
    ],
    id: "hs199",
  },
  {
    points: [
      { ath: 128.13452409793436, atv: -18.27455824624987 },
      { ath: 128.32643925368018, atv: -18.282248581534343 },
      { ath: 128.3328419458854, atv: -18.16280626834358 },
      { ath: 128.14105985124485, atv: -18.158185367341517 },
    ],
    id: "hs200",
  },
  {
    points: [
      { ath: 128.3345044346172, atv: -18.285308438760808 },
      { ath: 128.51675758977785, atv: -18.289749517461246 },
      { ath: 128.52302830597588, atv: -18.17183288561561 },
      { ath: 128.34251392008954, atv: -18.16739635856144 },
    ],
    id: "hs201",
  },
  {
    points: [
      { ath: 128.52162655649158, atv: -18.291273012087775 },
      { ath: 128.70872860330815, atv: -18.297056776568542 },
      { ath: 128.71483687112396, atv: -18.168417372956895 },
      { ath: 128.52788678431892, atv: -18.168762476837298 },
    ],
    id: "hs202",
  },
  {
    points: [
      { ath: 128.49810303836708, atv: -18.89128588375804 },
      { ath: 128.5047871969263, atv: -18.76571962966608 },
      { ath: 128.6810784752219, atv: -18.773557177843305 },
      { ath: 128.68423784724874, atv: -18.899136343142192 },
    ],
    id: "hs203",
  },
  {
    points: [
      { ath: 128.35048998792564, atv: -19.027293253813397 },
      { ath: 128.5383847326885, atv: -19.03529494024908 },
      { ath: 128.5353159692266, atv: -18.92043489818648 },
      { ath: 128.35723899576874, atv: -18.921642958910287 },
    ],
    id: "hs204",
  },
  {
    points: [
      { ath: 128.54486466419107, atv: -19.03530344185804 },
      { ath: 128.72630248046903, atv: -19.041575405704478 },
      { ath: 128.72634454666292, atv: -18.922124441986984 },
      { ath: 128.54502921583915, atv: -18.92044765205324 },
    ],
    id: "hs205",
  },
  {
    points: [
      { ath: 128.73443091928831, atv: -19.040048115537882 },
      { ath: 128.9239785354369, atv: -19.041534899815783 },
      { ath: 128.92388125920112, atv: -18.9236156369925 },
    ],
    id: "hs206",
  },
  {
    points: [
      { ath: 128.9320895255459, atv: -19.041527462135484 },
      { ath: 128.93360143139836, atv: -18.919012693284493 },
      { ath: 129.13113674350248, atv: -18.93559710786679 },
      { ath: 129.12325743339818, atv: -19.042809637031084 },
      { ath: 128.9320895255459, atv: -19.041527462135484 },
    ],
    id: "hs207",
  },
  {
    points: [
      { ath: 129.13101697198766, atv: -19.052289416263214 },
      { ath: 129.29465847219566, atv: -19.059945049750166 },
      { ath: 129.29783803883814, atv: -18.9420241475193 },
      { ath: 129.14240469329332, atv: -18.938968706134162 },
    ],
    id: "hs208",
  },
  {
    points: [
      { ath: 129.30278364039197, atv: -19.061472574167937 },
      { ath: 129.47777772225615, atv: -19.065897977991305 },
      { ath: 129.48407190400027, atv: -18.951033234547722 },
      { ath: 129.30919667991844, atv: -18.945081330116505 },
    ],
    id: "hs209",
  },
  {
    points: [
      { ath: 129.48909399586591, atv: -19.064350015645328 },
      { ath: 129.66573197076616, atv: -19.076252471888434 },
      { ath: 129.67192282567524, atv: -18.97057195981939 },
      { ath: 129.49215230865013, atv: -18.95561551780138 },
    ],
    id: "hs210",
  },
  {
    points: [
      { ath: 128.68596772363313, atv: -18.89689500132133 },
      { ath: 128.87858002267328, atv: -18.897336853161924 },
      { ath: 128.87556023067603, atv: -18.788601103964417 },
      { ath: 128.69118874360066, atv: -18.77899301152138 },
    ],
    id: "hs211",
  },
  {
    points: [
      { ath: 128.88826078371608, atv: -18.897352864981293 },
      { ath: 129.0743987019023, atv: -18.906770262150108 },
      { ath: 129.07288091092659, atv: -18.784254695511073 },
      { ath: 128.88847869535778, atv: -18.784028530833574 },
    ],
    id: "hs212",
  },
  {
    points: [
      { ath: 129.0824670640544, atv: -18.909838939931138 },
      { ath: 129.24758558109036, atv: -18.914473998997668 },
      { ath: 129.2588839577068, atv: -18.796551058091254 },
      { ath: 129.08417856332935, atv: -18.787325616999148 },
    ],
    id: "hs213",
  },
  {
    points: [
      { ath: 129.25406096079658, atv: -18.914472624412877 },
      { ath: 129.43700173039832, atv: -18.92352959621578 },
      { ath: 129.43846090243278, atv: -18.80560814102673 },
      { ath: 129.26212076739665, atv: -18.80114450766222 },
    ],
    id: "hs214",
  },
  {
    points: [
      { ath: 129.44185845636423, atv: -18.9235236601993 },
      { ath: 129.62320333271222, atv: -18.935462818154207 },
      { ath: 129.62129986502896, atv: -18.819080406222135 },
      { ath: 129.44655619832164, atv: -18.810192438124353 },
    ],
    id: "hs215",
  },
  {
    points: [
      { ath: 128.4542955951649, atv: -18.73629660750573 },
      { ath: 128.64349560490635, atv: -18.735114435184514 },
      { ath: 128.63397694926059, atv: -18.617182481608694 },
      { ath: 128.45946072956247, atv: -18.615328703020655 },
    ],
    id: "hs216",
  },
  {
    points: [
      { ath: 128.65322352917855, atv: -18.736660322385795 },
      { ath: 128.82787087524807, atv: -18.741399320426243 },
      { ath: 128.82953562327947, atv: -18.621948595168597 },
      { ath: 128.6420750704454, atv: -18.623320805518897 },
    ],
    id: "hs217",
  },
  {
    points: [
      { ath: 128.83597655626505, atv: -18.752120553078594 },
      { ath: 129.02196381478865, atv: -18.752088595112603 },
      { ath: 129.03318785895297, atv: -18.6326295900422 },
      { ath: 128.83763729157235, atv: -18.628075401766854 },
    ],
    id: "hs218",
  },
  {
    points: [
      { ath: 129.03005018576457, atv: -18.7520830366919 },
      { ath: 129.22737025149289, atv: -18.75949680155215 },
      { ath: 129.22715681737287, atv: -18.65076706087407 },
      { ath: 129.04126586123476, atv: -18.629560752552177 },
    ],
    id: "hs219",
  },
  {
    points: [
      { ath: 129.2338158747949, atv: -18.75948688572612 },
      { ath: 129.39396173676758, atv: -18.77138282329002 },
      { ath: 129.40492256472544, atv: -18.651905636920464 },
      { ath: 129.23521452068033, atv: -18.650754320637144 },
    ],
    id: "hs220",
  },
  {
    points: [
      { ath: 129.40039885964418, atv: -18.769832552003084 },
      { ath: 129.57672623780684, atv: -18.779996949731128 },
      { ath: 129.59080863208823, atv: -18.665094787009476 },
      { ath: 129.41460147030077, atv: -18.654940378989966 },
    ],
    id: "hs221",
  },
  {
    points: [
      { ath: 128.41231308136352, atv: -18.586242280547502 },
      { ath: 128.41262753555466, atv: -18.462199805577857 },
      { ath: 128.5821398284035, atv: -18.46710253813138 },
      { ath: 128.5932723593789, atv: -18.58197406544365 },
    ],
    id: "hs222",
  },
  {
    points: [
      { ath: 128.5997216864995, atv: -18.59117103874302 },
      { ath: 128.78391460003786, atv: -18.594382231447895 },
      { ath: 128.7823390609154, atv: -18.484118593826377 },
      { ath: 128.59342808752297, atv: -18.476306233529805 },
    ],
    id: "hs223",
  },
  {
    points: [
      { ath: 128.79360834844064, atv: -18.59744795003624 },
      { ath: 128.98104301294995, atv: -18.59893703876968 },
      { ath: 128.9857989954074, atv: -18.488670626555198 },
      { ath: 128.7904117487888, atv: -18.4871839147492 },
    ],
    id: "hs224",
  },
  {
    points: [
      { ath: 128.9875062639265, atv: -18.5989322726943 },
      { ath: 129.174956115425, atv: -18.607886644616077 },
      { ath: 129.18282855777503, atv: -18.50373670327112 },
      { ath: 128.99549166469671, atv: -18.493257469076347 },
    ],
    id: "hs225",
  },
  {
    points: [
      { ath: 129.183032344218, atv: -18.606340973046816 },
      { ath: 129.36727140630356, atv: -18.61817329715592 },
      { ath: 129.36696608793193, atv: -18.518634222145295 },
      { ath: 129.19252087042133, atv: -18.505250640665068 },
    ],
    id: "hs226",
  },
  {
    points: [
      { ath: 128.7516866967135, atv: -18.43356910128241 },
      { ath: 128.94379356797623, atv: -18.451942920650037 },
      { ath: 128.94050694463354, atv: -18.349339730918274 },
      { ath: 128.75012330609508, atv: -18.341683509370394 },
    ],
    id: "hs227",
  },
  {
    points: [
      { ath: 128.95186761564503, atv: -18.455001396913172 },
      { ath: 128.94857327608395, atv: -18.34780411704687 },
      { ath: 129.16154755157717, atv: -18.34910166156973 },
      { ath: 129.1553115331281, atv: -18.46856114706036 },
    ],
    id: "hs228",
  },
  {
    points: [
      { ath: 129.1633842322248, atv: -18.468547818131963 },
      { ath: 129.17123386017914, atv: -18.352148177970328 },
      { ath: 129.34876680307354, atv: -18.371671156685196 },
      { ath: 129.3361673512346, atv: -18.4789002806368 },
    ],
    id: "hs229",
  },
  {
    points: [
      { ath: 129.3750598115223, atv: -18.524737342778725 },
      { ath: 129.51721741597288, atv: -18.53500708562638 },
      { ath: 129.5337328084688, atv: -18.62529733814442 },
      { ath: 129.37373490642153, atv: -18.61815534393844 },
    ],
    id: "hs230",
  },
  {
    points: [
      { ath: 129.34424468676474, atv: -18.480410655240785 },
      { ath: 129.35523016817243, atv: -18.374716760845484 },
      { ath: 129.4972565973289, atv: -18.38347336225256 },
      { ath: 129.50248721961518, atv: -18.48452405667303 },
    ],
    id: "hs231",
  },
  {
    points: [
      { ath: 128.7211964778035, atv: -18.1839348296104 },
      { ath: 128.71788185662413, atv: -18.306442761741966 },
      { ath: 128.90983898689962, atv: -18.317193163036357 },
      { ath: 128.91140230469793, atv: -18.19008802376002 },
    ],
    id: "hs232",
  },
  {
    points: [
      { ath: 128.9146771823739, atv: -18.314128658279067 },
      { ath: 129.10664432072844, atv: -18.31855675012687 },
      { ath: 129.12257598952274, atv: -18.196024875280866 },
      { ath: 128.9210733216949, atv: -18.188553073839287 },
    ],
    id: "hs233",
  },
  {
    points: [
      { ath: 129.11311978844017, atv: -18.317017267772876 },
      { ath: 129.3034929479547, atv: -18.327376186698544 },
      { ath: 129.30477563244543, atv: -18.204865437562606 },
      { ath: 129.12905177415666, atv: -18.197547642025455 },
    ],
    id: "hs234",
  },
  {
    points: [
      { ath: 129.311537538894, atv: -18.32888726242653 },
      { ath: 129.48415839865655, atv: -18.332983571696424 },
      { ath: 129.49179984296413, atv: -18.22117227330935 },
      { ath: 129.31442644473233, atv: -18.20637263591724 },
    ],
    id: "hs235",
  },
  {
    points: [
      { ath: 120.03355880566394, atv: -19.402235483702224 },
      { ath: 120.25872246833845, atv: -18.057641601393712 },
      { ath: 124.61468903037135, atv: -18.089298940936906 },
      { ath: 124.68654739998112, atv: -19.416825637343585 },
    ],
    id: "hs236",
  },
  {
    points: [
      { ath: 114.90688175432899, atv: -19.44088090700308 },
      { ath: 119.56709628445321, atv: -19.414602148689866 },
      { ath: 119.71477902306316, atv: -18.053938681146345 },
      { ath: 115.30715194231445, atv: -18.027649752535396 },
    ],
    id: "hs237",
  },
  {
    points: [
      { ath: 109.78308149894727, atv: -19.567426217855033 },
      { ath: 110.39794253541902, atv: -18.19891824148454 },
      { ath: 114.78270127653386, atv: -18.072899000593544 },
      { ath: 114.44561876729128, atv: -19.42957750875428 },
    ],
    id: "hs238",
  },
  {
    points: [
      { ath: 104.21875265736983, atv: -19.72755703270214 },
      { ath: 104.70358360445266, atv: -18.33435734033633 },
      { ath: 109.88592326693478, atv: -18.212904601930934 },
      { ath: 109.29807695377141, atv: -19.561240822863887 },
    ],
    id: "hs239",
  },
  {
    points: [
      { ath: 98.74097122854457, atv: -19.84716226084974 },
      { ath: 99.06309221663159, atv: -18.38382374982313 },
      { ath: 104.17816212286291, atv: -18.359492382914095 },
      { ath: 103.7610306324647, atv: -19.736554800295853 },
    ],
    id: "hs240",
  },
  {
    points: [
      { ath: 98.25080672793422, atv: -19.705343518318656 },
      { ath: 96.21571983634644, atv: -18.475157559044263 },
      { ath: 98.5268435272178, atv: -18.470796218778908 },
    ],
    id: "hs241",
  },
  {
    points: [
      { ath: 80.41663646327379, atv: -19.525538720187708 },
      { ath: 80.14583389342215, atv: -18.266251986909438 },
      { ath: 82.4737471555506, atv: -18.34402618853896 },
    ],
    id: "hs242",
  },
  {
    points: [
      { ath: 74.85247683276418, atv: -19.371508578148575 },
      { ath: 74.4644381565505, atv: -18.037184414348914 },
      { ath: 79.6231484994679, atv: -18.239550796265878 },
      { ath: 79.8888856260387, atv: -19.62512343541196 },
    ],
    id: "hs243",
  },
  {
    points: [
      { ath: 69.37535532396453, atv: -19.063512598468417 },
      { ath: 68.88779942819048, atv: -17.728955584821207 },
      { ath: 73.9327741726288, atv: -18.01302109452869 },
      { ath: 74.40274168410849, atv: -19.310415906318045 },
    ],
    id: "hs244",
  },
  {
    points: [
      { ath: 64.32715709022597, atv: -18.791263462700748 },
      { ath: 68.89124519313413, atv: -19.061791807712854 },
      { ath: 68.46373612700756, atv: -17.713902912639995 },
      { ath: 64.104703795982, atv: -17.47167887999093 },
    ],
    id: "hs245",
  },
  {
    points: [
      { ath: 59.1825217931314, atv: -18.662332767668715 },
      { ath: 59.12506875365358, atv: -17.2553188733806 },
      { ath: 63.57063535521695, atv: -17.41721036318463 },
      { ath: 63.83440937988712, atv: -18.77453636729472 },
    ],
    id: "hs246",
  },
  {
    points: [
      { ath: 54.04974245462927, atv: -18.5912030817184 },
      { ath: 54.17196093546272, atv: -17.186473200363555 },
      { ath: 58.65989424041766, atv: -17.27921207199766 },
      { ath: 58.69342418542408, atv: -18.662052226318977 },
    ],
    id: "hs247",
  },
  {
    points: [
      { ath: 48.94817226710188, atv: -18.64469226643725 },
      { ath: 49.2775864391067, atv: -17.212714913428712 },
      { ath: 53.67779710961241, atv: -17.218632027124517 },
      { ath: 53.5669085509968, atv: -18.620804863540446 },
    ],
    id: "hs248",
  },
  {
    points: [
      { ath: 43.67631430299946, atv: -18.813009219287895 },
      { ath: 44.28840117596735, atv: -17.372382740125342 },
      { ath: 48.77869276617844, atv: -17.25749041902903 },
      { ath: 48.46269238672704, atv: -18.673832412344197 },
    ],
    id: "hs249",
  },
  {
    points: [
      { ath: 43.198973528429576, atv: -18.821213600359012 },
      { ath: 43.70386706300292, atv: -17.432577039803295 },
      { ath: 39.302030946205946, atv: -17.677524271135457 },
      { ath: 38.566344044484936, atv: -18.967657557304076 },
    ],
    id: "hs250",
  },
  { points: [], id: "hs251" },
  {
    points: [
      { ath: 38.119693155684104, atv: -19.10077611934259 },
      { ath: 38.79999814785981, atv: -17.789109053170122 },
      { ath: 34.28633463509567, atv: -18.168938778221857 },
      { ath: 33.31623052067255, atv: -19.50043883161085 },
    ],
    id: "hs252",
  },
  {
    points: [
      { ath: 32.878446305925024, atv: -19.517492938927152 },
      { ath: 33.91396649089978, atv: -18.110214873388095 },
      { ath: 29.602350317446735, atv: -18.53787295636102 },
      { ath: 28.282984389523847, atv: -20.030971418735316 },
    ],
    id: "hs253",
  },
  {
    points: [
      { ath: 27.84332068250933, atv: -20.098382450537922 },
      { ath: 28.96363972692336, atv: -18.677286192453476 },
      { ath: 23.857528676959532, atv: -19.19603722688479 },
      { ath: 22.880060367591398, atv: -20.593921502256418 },
    ],
    id: "hs254",
  },
  {
    points: [
      { ath: 22.525007714122523, atv: -20.567918562187224 },
      { ath: 23.378537217292603, atv: -19.19672590343167 },
      { ath: 17.979043305971345, atv: -19.627408337019816 },
      { ath: 17.189654051644595, atv: -21.00385687314523 },
    ],
    id: "hs255",
  },
  {
    points: [
      { ath: 16.701568217076215, atv: -21.091432412312223 },
      { ath: 17.39407191347533, atv: -19.649628331986676 },
      { ath: 11.84073636566859, atv: -19.99474928726642 },
      { ath: 11.203560484270781, atv: -21.37394845109164 },
    ],
    id: "hs256",
  },
  {
    points: [
      { ath: 10.729520820404105, atv: -21.416471740413545 },
      { ath: 11.201895724151996, atv: -20.050108098694444 },
      { ath: 5.457227420231732, atv: -20.255570950788783 },
      { ath: 5.149773098577668, atv: -21.645094981010992 },
    ],
    id: "hs257",
  },
  {
    points: [
      { ath: 4.6211288314410695, atv: -21.655039107461864 },
      { ath: 4.834971069718733, atv: -20.245046849520232 },
      { ath: -0.020693793143038874, atv: -20.28952878521244 },
      { ath: -0.03691463917868987, atv: -21.70257786683086 },
    ],
    id: "hs258",
  },
  {
    points: [
      { ath: -0.5811810964086703, atv: -21.698073779501907 },
      { ath: -0.5831592207259746, atv: -20.30973549663679 },
      { ath: -5.395730081286274, atv: -20.258810397708327 },
      { ath: -5.264896822549588, atv: -21.631444222315956 },
    ],
    id: "hs259",
  },
  {
    points: [
      { ath: -5.813344527581648, atv: -21.597277338728233 },
      { ath: -6.032053210340393, atv: -20.208166935624366 },
      { ath: -11.820468367723606, atv: -20.024019957264873 },
      { ath: -11.430615507060793, atv: -21.417783870526183 },
    ],
    id: "hs260",
  },
  {
    points: [
      { ath: -11.940567645344629, atv: -21.386613174000853 },
      { ath: -12.46164186239082, atv: -19.965992519816172 },
      { ath: -18.12429675948033, atv: -19.650244419221462 },
      { ath: -17.278412814219678, atv: -21.06239844508278 },
    ],
    id: "hs261",
  },
  {
    points: [
      { ath: -17.780306113513916, atv: -21.040639515232765 },
      { ath: -18.547367564166507, atv: -19.708484210919647 },
      { ath: -23.99500397844588, atv: -19.21488992859606 },
      { ath: -23.053758642904995, atv: -20.63904079038597 },
    ],
    id: "hs262",
  },
  {
    points: [
      { ath: -29.706911926143903, atv: -18.56008646173215 },
      { ath: -28.702623464272847, atv: -19.982816518810047 },
      { ath: -23.551564388155498, atv: -20.46673541012907 },
      { ath: -24.449577389008482, atv: -19.161657793205794 },
    ],
    id: "hs263",
  },
  {
    points: [
      { ath: -29.097527302658193, atv: -19.937986963851777 },
      { ath: -30.132455388864685, atv: -18.569378543722664 },
      { ath: -34.44727439323742, atv: -18.23586624175397 },
      { ath: -33.760578627491554, atv: -19.428349499116674 },
    ],
    id: "hs264",
  },
  {
    points: [
      { ath: -33.97796763495376, atv: -19.448300389310624 },
      { ath: -34.9294068458118, atv: -18.104290283622948 },
      { ath: -39.4093008694698, atv: -17.807058651691865 },
      { ath: -38.77422714366105, atv: -19.094009740145633 },
    ],
    id: "hs265",
  },
  {
    points: [
      { ath: -39.307911254428234, atv: -18.850343275920213 },
      { ath: -39.90884831558617, atv: -17.69989246048004 },
      { ath: -44.54891310831454, atv: -17.44364807647785 },
      { ath: -43.96266542530441, atv: -18.801021448224446 },
    ],
    id: "hs266",
  },
  {
    points: [
      { ath: -44.32224883897834, atv: -18.77668516573195 },
      { ath: -44.965077913998016, atv: -17.364902461491344 },
      { ath: -49.48117493940157, atv: -17.24485814075049 },
      { ath: -49.173500674715285, atv: -18.666138073514134 },
    ],
    id: "hs267",
  },
  {
    points: [
      { ath: -49.607101906697835, atv: -18.638523015859512 },
      { ath: -49.95841658963144, atv: -17.296855066719615 },
      { ath: -54.37910997329078, atv: -17.21936489379464 },
      { ath: -54.25233649144542, atv: -18.603128072213796 },
    ],
    id: "hs268",
  },
  {
    points: [
      { ath: -54.70292560549484, atv: -18.560288149425833 },
      { ath: -54.84011731332794, atv: -17.197256430800405 },
      { ath: -59.299914011299165, atv: -17.308595613095722 },
      { ath: -59.33772289704996, atv: -18.70203140760444 },
    ],
    id: "hs269",
  },
  {
    points: [
      { ath: -59.77158183538302, atv: -18.695910149159424 },
      { ath: -59.7330687803759, atv: -17.277811316977022 },
      { ath: -64.10427570968449, atv: -17.41894001181033 },
      { ath: -64.38587754138894, atv: -18.813027975736954 },
    ],
    id: "hs270",
  },
  {
    points: [
      { ath: -64.8580255090094, atv: -18.82116699365821 },
      { ath: -64.6383682551446, atv: -17.46344644535309 },
      { ath: -69.03847879039932, atv: -17.701863280024963 },
      { ath: -69.62018624376003, atv: -19.085032770250493 },
    ],
    id: "hs271",
  },
  {
    points: [
      { ath: -70.01896513143754, atv: -19.06416468339838 },
      { ath: -69.56456278485126, atv: -17.715897562688472 },
      { ath: -74.71883102683825, atv: -17.994572255739147 },
      { ath: -75.13377852123466, atv: -19.35287310285507 },
    ],
    id: "hs272",
  },
  {
    points: [
      { ath: -75.5978710898084, atv: -19.39647132671957 },
      { ath: -75.25425314332222, atv: -18.030181294595717 },
      { ath: -80.34174952613273, atv: -18.276016378056937 },
      { ath: -80.56569894971926, atv: -19.667216917371274 },
    ],
    id: "hs273",
  },
  {
    points: [
      { ath: -81.07251550616883, atv: -19.5651707447677 },
      { ath: -80.82197311900296, atv: -18.295517364236677 },
      { ath: -83.09999099033388, atv: -18.362133743649537 },
    ],
    id: "hs274",
  },
  {
    points: [
      { ath: -98.93968803348116, atv: -19.748678414313634 },
      { ath: -96.9240972266482, atv: -18.545617000010598 },
      { ath: -99.19571847552567, atv: -18.565483663377783 },
    ],
    id: "hs275",
  },
  {
    points: [
      { ath: -104.42401678699986, atv: -19.819370984172245 },
      { ath: -104.8844804953431, atv: -18.491406218042975 },
      { ath: -99.80765458991937, atv: -18.55552508393632 },
      { ath: -99.45869288074726, atv: -19.890502755132268 },
    ],
    id: "hs276",
  },
  {
    points: [
      { ath: -104.90609956778752, atv: -19.720839121394754 },
      { ath: -105.38681178305183, atv: -18.44282044618015 },
      { ath: -110.53845902969192, atv: -18.265765646529342 },
      { ath: -110.0372949355253, atv: -19.63284320111999 },
    ],
    id: "hs277",
  },
  {
    points: [
      { ath: -110.54952564969318, atv: -19.57935670576187 },
      { ath: -111.05584425467123, atv: -18.228785271546865 },
      { ath: -115.48197467327716, atv: -18.13476611950015 },
      { ath: -115.21128616565821, atv: -19.44273090194437 },
    ],
    id: "hs278",
  },
  {
    points: [
      { ath: -115.70232516727341, atv: -19.436190154839792 },
      { ath: -116.02990145855748, atv: -18.1346156553494 },
      { ath: -120.39391977905828, atv: -18.100585034146675 },
      { ath: -120.26859543141859, atv: -19.449697651141403 },
    ],
    id: "hs279",
  },
  {
    points: [
      { ath: -120.77097601024826, atv: -19.423286743694845 },
      { ath: -120.88296052507206, atv: -18.10958205394334 },
      { ath: -125.29421545170996, atv: -18.143524416694277 },
      { ath: -125.49132629813738, atv: -19.493149223788727 },
    ],
    id: "hs280",
  },
  {
    points: [
      { ath: -125.91245397735986, atv: -19.48857651419708 },
      { ath: -125.81115250742079, atv: -18.176218101329773 },
      { ath: -130.15406821443085, atv: -18.27709650152915 },
      { ath: -130.51816545163362, atv: -19.636392008351955 },
    ],
    id: "hs281",
  },
  {
    points: [
      { ath: -130.92613889755157, atv: -19.64566952316548 },
      { ath: -130.69987336345756, atv: -18.26049419705707 },
      { ath: -135.4057051903191, atv: -18.590534513607164 },
      { ath: -135.95420586302205, atv: -19.9841209201701 },
    ],
    id: "hs282",
  },
  {
    points: [
      { ath: -136.49233715323732, atv: -20.005437974847005 },
      { ath: -136.02052219229398, atv: -18.555468804425157 },
      { ath: -140.45491567179687, atv: -19.01020424827805 },
      { ath: -141.15498874646573, atv: -20.31785586663196 },
    ],
    id: "hs283",
  },
  {
    points: [
      { ath: -141.62203536198587, atv: -20.368086507834114 },
      { ath: -140.99996675149316, atv: -18.92561344607229 },
      { ath: -145.4700646531916, atv: -19.393021362168195 },
      { ath: -146.3892542479299, atv: -20.753240042662693 },
    ],
    id: "hs284",
  },
  {
    points: [
      { ath: -146.78806415278513, atv: -20.856102174256282 },
      { ath: -145.7862114810623, atv: -19.452905183327342 },
      { ath: -150.1223419462077, atv: -19.99909732655304 },
      { ath: -151.04987213465486, atv: -21.289037385722832 },
    ],
    id: "hs285",
  },
  {
    points: [
      { ath: -151.8816380325075, atv: -21.475149213660703 },
      { ath: -150.77038240283443, atv: -20.109020414352297 },
      { ath: -155.90998273522774, atv: -20.68606207539848 },
      { ath: -156.94154751829325, atv: -22.06748499307469 },
    ],
    id: "hs286",
  },
  {
    points: [
      { ath: -157.35471510556843, atv: -22.103457076763853 },
      { ath: -156.4855121779844, atv: -20.628979109092164 },
      { ath: -161.8228298840248, atv: -21.221423441124298 },
      { ath: -162.66982441890298, atv: -22.619642323522235 },
    ],
    id: "hs287",
  },
  {
    points: [
      { ath: -163.16655610278667, atv: -22.61150362786652 },
      { ath: -162.40257253245932, atv: -21.229369278474408 },
      { ath: -168.16197370809752, atv: -21.57178840220128 },
      { ath: -168.67014414657996, atv: -22.92097927531762 },
    ],
    id: "hs288",
  },
  {
    points: [
      { ath: -169.23319904977492, atv: -22.990926418938145 },
      { ath: -168.83499175963726, atv: -21.619105301998054 },
      { ath: -174.57922880762084, atv: -21.825464236532667 },
      { ath: -174.8488335589939, atv: -23.170423822105555 },
    ],
    id: "hs289",
  },
  {
    points: [
      { ath: -175.41256529569097, atv: -23.181286270143328 },
      { ath: -175.1886890818546, atv: -21.889235485056716 },
      { ath: 179.78733104672824, atv: -21.928893394562934 },
      { ath: 179.7888366829464, atv: -23.337999279689868 },
    ],
    id: "hs290",
  },
  {
    points: [
      { ath: 179.214614971267, atv: -23.19853275248698 },
      { ath: 179.20707796367316, atv: -21.8850174682705 },
      { ath: 174.37841893486137, atv: -21.821824171265806 },
      { ath: 174.49835120337713, atv: -23.06319496142699 },
    ],
    id: "hs291",
  },
  {
    points: [
      { ath: 173.97686866090305, atv: -23.046801694845236 },
      { ath: 173.75403208871253, atv: -21.774807766160926 },
      { ath: 167.90944634038152, atv: -21.593850388736634 },
      { ath: 168.36552890345837, atv: -22.865718170565923 },
    ],
    id: "hs292",
  },
  {
    points: [
      { ath: 162.24432225425687, atv: -22.485172155941736 },
      { ath: 161.5929130719441, atv: -21.19521474703492 },
      { ath: 167.21231395647166, atv: -21.51317678336993 },
      { ath: 167.76313779877512, atv: -22.844086659285292 },
    ],
    id: "hs293",
  },
  {
    points: [
      { ath: 160.90655075741336, atv: -21.12976768930185 },
      { ath: 161.82779913438668, atv: -22.459343460516312 },
      { ath: 156.51852947537762, atv: -22.017412994278008 },
      { ath: 155.67744745293092, atv: -20.63902964280593 },
    ],
    id: "hs294",
  },
  {
    points: [
      { ath: 155.046261951132, atv: -20.521069840939358 },
      { ath: 156.1281374106614, atv: -21.949052446173063 },
      { ath: 151.11696360900964, atv: -21.4119453985502 },
      { ath: 149.92690622334726, atv: -19.95249352703051 },
    ],
    id: "hs295",
  },
  {
    points: [
      { ath: 149.61058715251102, atv: -19.952738709802478 },
      { ath: 150.66843982411734, atv: -21.300714573634384 },
      { ath: 145.9154471047696, atv: -20.76204777296535 },
      { ath: 144.99996799866165, atv: -19.381230980702984 },
    ],
    id: "hs296",
  },
  {
    points: [
      { ath: 144.6301870182615, atv: -19.392185683652073 },
      { ath: 145.58456658483112, atv: -20.729313770844712 },
      { ath: 140.77407076238674, atv: -20.30137438835262 },
      { ath: 140.0575206523632, atv: -18.895319693177033 },
    ],
    id: "hs297",
  },
  {
    points: [
      { ath: 139.6911097944444, atv: -18.923669158074407 },
      { ath: 140.30103111874448, atv: -20.202306533582366 },
      { ath: 135.59480401475517, atv: -19.870800898653982 },
      { ath: 135.13988043253045, atv: -18.54203594111892 },
    ],
    id: "hs298",
  },
  {
    points: [
      { ath: 134.55322855089983, atv: -18.532983864863642 },
      { ath: 135.07273306634974, atv: -19.806074816164003 },
      { ath: 130.25245701998236, atv: -19.59856663981438 },
      { ath: 130.07793095268013, atv: -18.34490454786784 },
    ],
    id: "hs299",
  },
  {
    points: [
      { ath: 130.4682766620418, atv: -16.530363120357578 },
      { ath: 130.85098250552977, atv: -17.940148630523616 },
      { ath: 127.42128793715429, atv: -17.768995160145707 },
      { ath: 127.25105388422412, atv: -16.153523415018494 },
    ],
    id: "hs300",
  },
  {
    points: [
      { ath: 126.63610768207062, atv: -16.02430215993653 },
      { ath: 126.96109842562836, atv: -17.770797044850163 },
      { ath: 122.61236296974468, atv: -17.778476407185135 },
      { ath: 122.68566998622651, atv: -15.77477576246055 },
    ],
    id: "hs301",
  },
  {
    points: [
      { ath: 124.18249357649708, atv: -14.2336475142994 },
      { ath: 124.38997634122782, atv: -15.514562365972425 },
      { ath: 120.42299590124858, atv: -15.31312167526106 },
      { ath: 120.52835515299677, atv: -14.344198058636055 },
    ],
    id: "hs302",
  },
  {
    points: [
      { ath: 122.1399015458683, atv: -15.851658328907922 },
      { ath: 122.06438073031029, atv: -17.679770429492798 },
      { ath: 117.85167185669681, atv: -17.693962192812645 },
      { ath: 118.25154368109554, atv: -15.762130491402052 },
    ],
    id: "hs303",
  },
  {
    points: [
      { ath: 117.50974261208461, atv: -15.862373215297449 },
      { ath: 117.26548147273363, atv: -17.68926903063295 },
      { ath: 112.98039092377346, atv: -17.785483139313058 },
      { ath: 113.62830537482077, atv: -15.881269748830467 },
    ],
    id: "hs304",
  },
  {
    points: [
      { ath: 120.11055412715746, atv: -14.204663174106306 },
      { ath: 119.92443307491874, atv: -15.429192997930711 },
      { ath: 116.05705183135478, atv: -15.368566456051234 },
      { ath: 116.3730781107543, atv: -14.26955519171689 },
    ],
    id: "hs305",
  },
  {
    points: [
      { ath: 127.61612143995424, atv: -13.399599359291166 },
      { ath: 127.66636525611094, atv: -14.068226818188336 },
      { ath: 124.76975322755473, atv: -13.936223346571769 },
      { ath: 124.77596514148516, atv: -13.376002809573146 },
    ],
    id: "hs306",
  },
  {
    points: [
      { ath: 124.03844938600201, atv: -13.391698132833532 },
      { ath: 124.18776882355411, atv: -13.867247708457686 },
      { ath: 120.7716296482256, atv: -13.812586347724034 },
      { ath: 120.99260392224346, atv: -13.237671818136093 },
    ],
    id: "hs307",
  },
  {
    points: [
      { ath: 124.49317064583067, atv: -13.012171380918497 },
      { ath: 124.15055309846349, atv: -10.599981995474797 },
      { ath: 118.01870511151455, atv: -10.557924987123062 },
      { ath: 117.64338497232825, atv: -13.070274337073217 },
    ],
    id: "hs308",
  },
  {
    points: [
      { ath: 117.06473761093275, atv: -13.037963616547932 },
      { ath: 110.20523144559496, atv: -13.175693832963987 },
      { ath: 111.34963606387922, atv: -10.570182269476188 },
      { ath: 117.4262051130433, atv: -10.528292954174445 },
    ],
    id: "hs309",
  },
  {
    points: [
      { ath: 119.56013911629688, atv: -13.335101715415085 },
      { ath: 119.43920507555532, atv: -13.876860028521737 },
      { ath: 116.43717653194113, atv: -13.825436298650772 },
      { ath: 116.6344689843989, atv: -13.266992876508375 },
    ],
    id: "hs310",
  },
  {
    points: [
      { ath: 115.99045587559053, atv: -13.252278316545882 },
      { ath: 115.83262817002841, atv: -13.89724530389595 },
      { ath: 112.5513331599243, atv: -13.962667750570022 },
      { ath: 112.72211549777646, atv: -13.281518483116848 },
    ],
    id: "hs311",
  },
  {
    points: [
      { ath: 115.77391732643227, atv: -14.264021880507858 },
      { ath: 115.48053500260599, atv: -15.449893039244705 },
      { ath: 110.97596686480352, atv: -15.570457519899701 },
      { ath: 111.42630296425938, atv: -14.409218184508955 },
    ],
    id: "hs312",
  },
  {
    points: [
      { ath: 113.1484407297697, atv: -15.794841937165284 },
      { ath: 112.42022712525986, atv: -17.843543853796813 },
      { ath: 107.70114948603592, atv: -18.015407353005664 },
      { ath: 108.47353092223153, atv: -15.975542564994988 },
    ],
    id: "hs313",
  },
  {
    points: [
      { ath: 107.94871945233288, atv: -15.955445520526812 },
      { ath: 107.10015662718857, atv: -18.059054785115336 },
      { ath: 102.02796919822708, atv: -18.157942897103485 },
      { ath: 102.59277388282396, atv: -16.227905597060875 },
    ],
    id: "hs314",
  },
  {
    points: [
      { ath: 102.02924447664253, atv: -16.127051911319178 },
      { ath: 101.40644786092412, atv: -18.22680027197136 },
      { ath: 96.75024388553402, atv: -18.261492289750823 },
      { ath: 97.19826641630962, atv: -16.20870973973856 },
    ],
    id: "hs315",
  },
  {
    points: [
      { ath: 110.85346917082364, atv: -14.383260431044977 },
      { ath: 110.5175724937269, atv: -15.672750311946091 },
      { ath: 105.74052627640108, atv: -15.730482319663457 },
      { ath: 106.18328371698769, atv: -14.447132737552934 },
    ],
    id: "hs316",
  },
  {
    points: [
      { ath: 111.39028857203064, atv: -13.334723433664735 },
      { ath: 111.09358950644634, atv: -14.054092023239374 },
      { ath: 106.32078224778115, atv: -14.165112618810838 },
      { ath: 106.52763095624132, atv: -13.492970814306998 },
    ],
    id: "hs317",
  },
  {
    points: [
      { ath: 105.89844771160918, atv: -13.576536277776556 },
      { ath: 105.71148971442591, atv: -14.248357752138597 },
      { ath: 100.30727994635058, atv: -14.255788084881168 },
      { ath: 100.45050909544943, atv: -13.52623227828338 },
    ],
    id: "hs318",
  },
  {
    points: [
      { ath: 105.55046897368604, atv: -14.551602475994873 },
      { ath: 105.05812049618768, atv: -15.767671760919239 },
      { ath: 100.35082420766983, atv: -15.79529954204243 },
      { ath: 100.60233616158507, atv: -14.653877868716913 },
    ],
    id: "hs319",
  },
  {
    points: [
      { ath: 110.6443385623511, atv: -10.635364869545178 },
      { ath: 109.66713598663344, atv: -13.26870375965634 },
      { ath: 103.25880560438978, atv: -13.41620167545085 },
      { ath: 104.03043547717601, atv: -10.8031864761717 },
    ],
    id: "hs320",
  },
  {
    points: [
      { ath: 103.32785530109939, atv: -10.773235683399928 },
      { ath: 102.65823140582339, atv: -13.362252194542913 },
      { ath: 96.1136812756514, atv: -13.43122272923969 },
      { ath: 96.46425763936409, atv: -10.859543286022642 },
    ],
    id: "hs321",
  },
  {
    points: [
      { ath: 98.9860991760147, atv: -13.556159703612556 },
      { ath: 98.85601337377383, atv: -14.296285398413072 },
      { ath: 94.8751150439432, atv: -14.316645851937176 },
      { ath: 94.91259945235612, atv: -13.64158689233344 },
    ],
    id: "hs322",
  },
  {
    points: [
      { ath: 100.14257948217704, atv: -14.608143626011634 },
      { ath: 99.83347719893302, atv: -15.79072048424752 },
      { ath: 94.70974074445331, atv: -15.824818734605934 },
      { ath: 94.85517824083061, atv: -14.55659540040835 },
    ],
    id: "hs323",
  },
  {
    points: [
      { ath: 94.32765363376114, atv: -13.645296300528583 },
      { ath: 94.26502047363599, atv: -14.298766715391793 },
      { ath: 90.11245464560838, atv: -14.218785395166485 },
      { ath: 90.19196060486519, atv: -13.523863993902806 },
    ],
    id: "hs324",
  },
  {
    points: [
      { ath: 94.17702402347007, atv: -14.691765558384022 },
      { ath: 94.04843610949328, atv: -15.872663425908046 },
      { ath: 89.72055175708198, atv: -15.784250042398199 },
      { ath: 89.63039620212197, atv: -14.647976330372598 },
    ],
    id: "hs325",
  },
  {
    points: [
      { ath: 96.46870576473691, atv: -16.19780305218259 },
      { ath: 96.2931475422462, atv: -17.690172604344372 },
      { ath: 92.07631659828164, atv: -17.71473437748671 },
      { ath: 92.17648042312095, atv: -16.201805401931814 },
    ],
    id: "hs326",
  },
  {
    points: [
      { ath: 91.55976934415176, atv: -16.198444775821358 },
      { ath: 91.4076324623961, atv: -17.73231700980804 },
      { ath: 87.16227374572179, atv: -17.60802423868812 },
      { ath: 87.09882747197878, atv: -16.20844525476328 },
    ],
    id: "hs327",
  },
  {
    points: [
      { ath: 86.5131123814287, atv: -16.147741749240684 },
      { ath: 86.66243560823091, atv: -17.54888213522345 },
      { ath: 82.33167609850665, atv: -17.42771357463454 },
      { ath: 82.04284730245956, atv: -16.072516730955147 },
    ],
    id: "hs328",
  },
  {
    points: [
      { ath: 89.11232032237604, atv: -14.6180860768749 },
      { ath: 89.06055954957878, atv: -15.838790467722779 },
      { ath: 84.57453166702413, atv: -15.775965038223124 },
      { ath: 84.38908839412801, atv: -14.621185861314242 },
    ],
    id: "hs329",
  },
  {
    points: [
      { ath: 88.60083596430718, atv: -13.544255314743124 },
      { ath: 88.60586705572621, atv: -14.195761458036682 },
      { ath: 84.36658057714845, atv: -14.209937609446037 },
      { ath: 84.35390250238834, atv: -13.605738236526808 },
    ],
    id: "hs330",
  },
  {
    points: [
      { ath: 95.90511924139571, atv: -10.864112249464993 },
      { ath: 95.54318460151623, atv: -13.449078914963067 },
      { ath: 89.6486522955671, atv: -13.42903122400563 },
      { ath: 89.48909543576929, atv: -10.857155294642254 },
    ],
    id: "hs331",
  },
  {
    points: [
      { ath: 88.86915146283548, atv: -10.773781192242097 },
      { ath: 89.06464362691815, atv: -13.432421353874133 },
      { ath: 83.19006960881632, atv: -13.26166939215271 },
      { ath: 82.99330194853007, atv: -10.844118272035724 },
    ],
    id: "hs332",
  },
  {
    points: [
      { ath: 83.77471691547373, atv: -13.61967638727283 },
      { ath: 83.76762657356619, atv: -14.208056842102968 },
      { ath: 79.81831433437662, atv: -14.010667310249326 },
      { ath: 79.70354774128657, atv: -13.399617909679417 },
    ],
    id: "hs333",
  },
  {
    points: [
      { ath: 83.86622437713777, atv: -14.533310325532453 },
      { ath: 84.03501256010509, atv: -15.75309251710352 },
      { ath: 79.1176252461184, atv: -15.543931984096808 },
      { ath: 78.8236993525885, atv: -14.404801646925092 },
    ],
    id: "hs334",
  },
  {
    points: [
      { ath: 81.46957916432234, atv: -16.036226000959577 },
      { ath: 81.84252559790161, atv: -18.05094161028317 },
      { ath: 77.2014065375381, atv: -17.883724066637946 },
      { ath: 76.69932172850679, atv: -15.881023946580163 },
    ],
    id: "hs335",
  },
  {
    points: [
      { ath: 76.12831627019182, atv: -15.939585182289044 },
      { ath: 76.601146345303, atv: -17.898797450234248 },
      { ath: 71.54838771002318, atv: -17.57146324686045 },
      { ath: 70.84997280088692, atv: -15.584408918927108 },
    ],
    id: "hs336",
  },
  {
    points: [
      { ath: 78.30279348863405, atv: -14.359802806542174 },
      { ath: 78.52495093909283, atv: -15.521129398013048 },
      { ath: 73.35894671272592, atv: -15.184665964771247 },
      { ath: 73.2127966979026, atv: -14.178573251886949 },
    ],
    id: "hs337",
  },
  {
    points: [
      { ath: 72.67511670092074, atv: -14.186445082714016 },
      { ath: 73.04122744736003, atv: -15.241731748693784 },
      { ath: 68.26493514637946, atv: -14.953710816150846 },
      { ath: 67.74755935910753, atv: -13.788448718911225 },
    ],
    id: "hs338",
  },
  {
    points: [
      { ath: 82.30622427351238, atv: -10.670788179333318 },
      { ath: 82.64398487870449, atv: -13.245522665870897 },
      { ath: 76.17712241839826, atv: -13.012590224781523 },
      { ath: 75.20551906084336, atv: -10.425081677245082 },
    ],
    id: "hs339",
  },
  {
    points: [
      { ath: 74.63364783593306, atv: -10.396056515650098 },
      { ath: 75.52698852522076, atv: -13.04787496863078 },
      { ath: 69.14927934983791, atv: -12.580370289764668 },
      { ath: 68.23282917631423, atv: -10.179451358824323 },
    ],
    id: "hs340",
  },
  {
    points: [
      { ath: 78.23938649937878, atv: -13.349753339573489 },
      { ath: 78.39239270071766, atv: -13.933550540990415 },
      { ath: 73.14721297307176, atv: -13.719974064496668 },
      { ath: 72.8752056611932, atv: -13.130923617153767 },
    ],
    id: "hs341",
  },
  {
    points: [
      { ath: 72.24520867643696, atv: -13.109295646740534 },
      { ath: 72.51513173475911, atv: -13.677152430541893 },
      { ath: 67.70449917358656, atv: -13.435401146528763 },
      { ath: 67.45495292015602, atv: -12.75645881868187 },
    ],
    id: "hs342",
  },
  {
    points: [
      { ath: 70.35151159287574, atv: -15.506499109796914 },
      { ath: 70.99473867888275, atv: -17.55365208111685 },
      { ath: 66.3482154485356, atv: -17.180470563688814 },
      { ath: 65.75871334934311, atv: -15.32788242354949 },
    ],
    id: "hs343",
  },
  {
    points: [
      { ath: 67.22287625983222, atv: -13.839526090643357 },
      { ath: 67.63935147314646, atv: -15.026734241793578 },
      { ath: 63.3302501345309, atv: -14.678030902279648 },
      { ath: 63.03333823548809, atv: -13.584828718492465 },
    ],
    id: "hs344",
  },
  {
    points: [
      { ath: 65.98943184988764, atv: -12.70352934971274 },
      { ath: 66.18927038206391, atv: -13.337634515329144 },
      { ath: 62.93947659880689, atv: -13.279946222325767 },
      { ath: 62.82064936475649, atv: -12.629169973244244 },
    ],
    id: "hs345",
  },
  {
    points: [
      { ath: 67.43874995772944, atv: -10.16260479230859 },
      { ath: 68.52991136805633, atv: -12.601007327401685 },
      { ath: 62.13986814270936, atv: -12.324542295528808 },
      { ath: 61.55461872064086, atv: -9.808533753306408 },
    ],
    id: "hs346",
  },
  {
    points: [
      { ath: 65.13909269843754, atv: -15.240521720279416 },
      { ath: 65.7846177365389, atv: -17.242873120437544 },
      { ath: 61.47632681218204, atv: -17.148447653578973 },
      { ath: 61.223741825917955, atv: -15.108222491923524 },
    ],
    id: "hs347",
  },
  {
    points: [
      { ath: 60.68150080316241, atv: -15.16904772623274 },
      { ath: 60.88628868988383, atv: -16.99274966015191 },
      { ath: 56.77289767772396, atv: -16.950264875732056 },
      { ath: 56.751308751774786, atv: -14.983555783505764 },
    ],
    id: "hs348",
  },
  {
    points: [
      { ath: 59.22419327343982, atv: -13.445719453379661 },
      { ath: 62.54233437476398, atv: -13.470217902576069 },
      { ath: 62.85447969336883, atv: -14.74829096111954 },
      { ath: 59.19813235117306, atv: -14.628732679842035 },
    ],
    id: "hs349",
  },
  {
    points: [
      { ath: 59.275150246206636, atv: -12.439865744879071 },
      { ath: 62.268553249458364, atv: -12.584244832515633 },
      { ath: 62.44744480008819, atv: -13.298327825688709 },
      { ath: 59.35436696349626, atv: -13.159781317282263 },
    ],
    id: "hs350",
  },
  {
    points: [
      { ath: 57.908151727371205, atv: -12.365000891565245 },
      { ath: 57.98259750191045, atv: -13.107775070061228 },
      { ath: 54.785916427197265, atv: -13.023712298634186 },
      { ath: 54.81279652285025, atv: -12.367915410032841 },
    ],
    id: "hs351",
  },
  {
    points: [
      { ath: 54.692942873110894, atv: -13.461245338702602 },
      { ath: 58.57247181746038, atv: -13.496553434404941 },
      { ath: 58.586123527954214, atv: -14.548099470293138 },
      { ath: 54.57197841473317, atv: -14.512723052189777 },
    ],
    id: "hs352",
  },
  {
    points: [
      { ath: 60.948810382967736, atv: -9.761133665963426 },
      { ath: 61.51753519915417, atv: -12.337459982332485 },
      { ath: 54.45165173368184, atv: -12.163495994717309 },
      { ath: 54.889007027401476, atv: -9.743610320355417 },
    ],
    id: "hs353",
  },
  {
    points: [
      { ath: 56.156243595615365, atv: -14.928579772107852 },
      { ath: 56.30343844012884, atv: -16.872088927498343 },
      { ath: 51.86640068680913, atv: -16.929021800123262 },
      { ath: 52.23855744999486, atv: -14.949006856570444 },
    ],
    id: "hs354",
  },
  {
    points: [
      { ath: 51.6023806418325, atv: -14.99706427444567 },
      { ath: 51.292410595858996, atv: -16.95419984299911 },
      { ath: 46.861655031466796, atv: -16.99081377361222 },
      { ath: 47.57776781204484, atv: -15.00424866643829 },
    ],
    id: "hs355",
  },
  {
    points: [
      { ath: 54.13873962940244, atv: -13.392282297549903 },
      { ath: 54.00198054951443, atv: -14.555558296979356 },
      { ath: 50.237923487344005, atv: -14.58259061569104 },
      { ath: 50.577564780364185, atv: -13.35352578185193 },
    ],
    id: "hs356",
  },
  {
    points: [
      { ath: 54.2101277367118, atv: -12.407130667771554 },
      { ath: 54.1322924819508, atv: -13.042231705735675 },
      { ath: 50.9378045780029, atv: -13.046148306676674 },
      { ath: 51.0260348092479, atv: -12.410791487203616 },
    ],
    id: "hs357",
  },
  {
    points: [
      { ath: 54.0733053041165, atv: -9.774920953093693 },
      { ath: 53.80339911516552, atv: -12.150281982639173 },
      { ath: 47.75086796339042, atv: -12.28895778138854 },
      { ath: 48.403371617753805, atv: -9.81000720341802 },
    ],
    id: "hs358",
  },
  {
    points: [
      { ath: 49.70070879074194, atv: -12.498020856435579 },
      { ath: 49.54065516338312, atv: -13.132787203161266 },
      { ath: 46.23341229564903, atv: -13.165912039535264 },
      { ath: 46.37680462731856, atv: -12.730902871942645 },
    ],
    id: "hs359",
  },
  {
    points: [
      { ath: 50.013148368269185, atv: -13.506745378203407 },
      { ath: 49.647436883631485, atv: -14.647253183867386 },
      { ath: 45.59004220988163, atv: -14.751836457278209 },
      { ath: 46.22564437082315, atv: -13.53770344193455 },
    ],
    id: "hs360",
  },
  {
    points: [
      { ath: 46.986463385873435, atv: -15.06193420112421 },
      { ath: 46.2650502443031, atv: -17.045326616256855 },
      { ath: 42.09660112464206, atv: -17.22265492796806 },
      { ath: 43.053236436081875, atv: -15.230480532948986 },
    ],
    id: "hs361",
  },
  {
    points: [
      { ath: 45.390529233322695, atv: -13.697264865469547 },
      { ath: 44.8665299129965, atv: -14.801266843228474 },
      { ath: 41.164869372872204, atv: -14.986792739458014 },
      { ath: 41.95075186552151, atv: -13.77759073593256 },
    ],
    id: "hs362",
  },
  {
    points: [
      { ath: 45.88611384042326, atv: -12.657450877724814 },
      { ath: 45.65026790624802, atv: -13.177617205122962 },
      { ath: 42.632361816351136, atv: -13.386684843097093 },
      { ath: 42.83517682252585, atv: -12.718634488038965 },
    ],
    id: "hs363",
  },
  {
    points: [
      { ath: 47.71750834921909, atv: -9.78354775016564 },
      { ath: 47.12372515740094, atv: -12.303988733580715 },
      { ath: 41.21282073661291, atv: -12.515678181468147 },
      { ath: 42.80058162620401, atv: -10.140280628895828 },
    ],
    id: "hs364",
  },
  {
    points: [
      { ath: 42.44282870418067, atv: -15.258292139243915 },
      { ath: 41.48470877880112, atv: -17.245271548416458 },
      { ath: 37.205461324526425, atv: -17.57346436286906 },
      { ath: 38.59457643159135, atv: -15.45352542734231 },
    ],
    id: "hs365",
  },
  {
    points: [
      { ath: 41.11225578711634, atv: -13.82260826387074 },
      { ath: 40.621378736802285, atv: -14.968027799582737 },
      { ath: 36.47819225858768, atv: -15.326248183905733 },
      { ath: 37.716245231229095, atv: -14.15249453217614 },
    ],
    id: "hs366",
  },
  {
    points: [
      { ath: 37.9092968660558, atv: -15.71371083114939 },
      { ath: 36.67557903547214, atv: -17.676541455643193 },
      { ath: 32.24526725896635, atv: -18.06753633260579 },
      { ath: 34.12765402037593, atv: -15.896714753312118 },
    ],
    id: "hs367",
  },
  {
    points: [
      { ath: 37.12789306524053, atv: -14.12576485554591 },
      { ath: 36.020593806659974, atv: -15.517379693885239 },
      { ath: 31.371774771155458, atv: -15.943239160442953 },
      { ath: 32.26097407241804, atv: -14.926530021090697 },
    ],
    id: "hs368",
  },
  {
    points: [
      { ath: 34.91348953120519, atv: -13.087406826139757 },
      { ath: 40.51771222969376, atv: -12.5968441274071 },
      { ath: 42.105095045631344, atv: -10.18079111447249 },
      { ath: 37.38849825728039, atv: -10.469608816424122 },
    ],
    id: "hs369",
  },
  {
    points: [
      { ath: 35.32499463964905, atv: -12.269191303399005 },
      { ath: 34.472055385816134, atv: -13.060385485819848 },
      { ath: 33.41224515485317, atv: -13.124559531438498 },
      { ath: 33.730228850408366, atv: -12.335976236272854 },
    ],
    id: "hs370",
  },
  {
    points: [
      { ath: 33.32575050702911, atv: -12.444352600945825 },
      { ath: 32.69117745539927, atv: -13.142887230972327 },
      { ath: 30.391953188677803, atv: -13.358880050697865 },
      { ath: 30.67280271892031, atv: -12.727890975341298 },
    ],
    id: "hs371",
  },
  {
    points: [
      { ath: 33.491198341428344, atv: -15.980692370432132 },
      { ath: 31.890247507066647, atv: -18.074772905280273 },
      { ath: 27.174122249965706, atv: -18.458802841391304 },
      { ath: 28.875887512397696, atv: -16.438577833011035 },
    ],
    id: "hs372",
  },
  {
    points: [
      { ath: 36.803387174725685, atv: -10.644237935317523 },
      { ath: 35.55208047620795, atv: -11.699527098799729 },
      { ath: 23.147016959321604, atv: -12.675159225089832 },
      { ath: 23.89891092150407, atv: -11.631426804226672 },
    ],
    id: "hs373",
  },
  {
    points: [
      { ath: 30.317757367729826, atv: -12.787742720022765 },
      { ath: 29.50219548112142, atv: -13.63223187170249 },
      { ath: 27.290715284797727, atv: -13.653127379572906 },
      { ath: 27.758261346187226, atv: -12.816749142516528 },
    ],
    id: "hs374",
  },
  {
    points: [
      { ath: 27.06155724379778, atv: -12.930019835299744 },
      { ath: 26.658598305401938, atv: -13.765289748187014 },
      { ath: 24.05632794626763, atv: -13.955714056829727 },
      { ath: 24.40231135562766, atv: -13.102383740040594 },
    ],
    id: "hs375",
  },
  {
    points: [
      { ath: 23.83748014613451, atv: -13.229791084598538 },
      { ath: 23.33095527916356, atv: -14.080435982410998 },
      { ath: 22.088760728330953, atv: -14.085328749413529 },
      { ath: 22.487959378417543, atv: -13.170892415085769 },
    ],
    id: "hs376",
  },
  {
    points: [
      { ath: 21.676114938163494, atv: -13.333570054114265 },
      { ath: 21.25229450591047, atv: -14.20166631686488 },
      { ath: 18.66698573088479, atv: -14.354450317190352 },
      { ath: 19.082427794038267, atv: -13.297828453278193 },
    ],
    id: "hs377",
  },
  {
    points: [
      { ath: 31.742664132710274, atv: -14.970242302446012 },
      { ath: 30.85428719118397, atv: -16.005695899622975 },
      { ath: 26.17784645378589, atv: -16.35707380092534 },
      { ath: 26.795554538589386, atv: -15.468788364019721 },
    ],
    id: "hs378",
  },
  {
    points: [
      { ath: 28.163894733730388, atv: -16.531739986034648 },
      { ath: 26.708380125272697, atv: -18.605521142479933 },
      { ath: 21.286965393137052, atv: -19.264505521897753 },
      { ath: 22.450144213453655, atv: -17.136930781772087 },
    ],
    id: "hs379",
  },
  {
    points: [
      { ath: 26.0033555830625, atv: -15.548233063849985 },
      { ath: 25.472113570893328, atv: -16.433820618541713 },
      { ath: 19.74100929727399, atv: -16.921423933036873 },
      { ath: 20.211063724423923, atv: -15.982761158322951 },
    ],
    id: "hs380",
  },
  {
    points: [
      { ath: 21.89744910635079, atv: -17.246103802882597 },
      { ath: 20.612894073907228, atv: -19.193219083869305 },
      { ath: 15.000830393828664, atv: -19.64104662166896 },
      { ath: 16.222595939811242, atv: -17.58885879963707 },
    ],
    id: "hs381",
  },
  {
    points: [
      { ath: 19.71035622673702, atv: -16.03619259441236 },
      { ath: 19.225565371669518, atv: -16.946944646708214 },
      { ath: 13.095748849834308, atv: -17.2726644134612 },
      { ath: 13.391394464452674, atv: -16.481094101663594 },
    ],
    id: "hs382",
  },
  {
    points: [
      { ath: 15.493613283485615, atv: -17.691868358941218 },
      { ath: 14.268459839034165, atv: -19.683058269815156 },
      { ath: 8.901233012274474, atv: -19.893411029352496 },
      { ath: 9.472718564097136, atv: -17.95099795501634 },
    ],
    id: "hs383",
  },
  {
    points: [
      { ath: 12.817897456672426, atv: -16.438491035914343 },
      { ath: 12.381429755080148, atv: -17.382930795984606 },
      { ath: 6.612910139355222, atv: -17.59949626370468 },
      { ath: 6.826129099403033, atv: -16.686989569272225 },
    ],
    id: "hs384",
  },
  {
    points: [
      { ath: 22.954678192178108, atv: -11.722917661356286 },
      { ath: 21.95970253970455, atv: -12.849270973012556 },
      { ath: 12.193351189690304, atv: -13.29338081866757 },
      { ath: 12.615453729959995, atv: -12.233227734509716 },
    ],
    id: "hs385",
  },
  {
    points: [
      { ath: 18.510723083579137, atv: -13.388283776468844 },
      { ath: 18.075716051644065, atv: -14.440728115210803 },
      { ath: 15.427454036258155, atv: -14.562268490700765 },
      { ath: 15.85681990307694, atv: -13.49142185917043 },
    ],
    id: "hs386",
  },
  {
    points: [
      { ath: 15.248129196722061, atv: -13.57845072483679 },
      { ath: 14.859926207310025, atv: -14.71421583284937 },
      { ath: 12.1414706222738, atv: -14.754564408492678 },
      { ath: 12.346644306224732, atv: -13.46969769659482 },
    ],
    id: "hs387",
  },
  {
    points: [
      { ath: 8.69109931197022, atv: -17.844282516511193 },
      { ath: 8.074482703207991, atv: -19.985310725804467 },
      { ath: 2.3051372939851262, atv: -20.043532187324534 },
      { ath: 2.5808664028203907, atv: -17.91506988844118 },
    ],
    id: "hs388",
  },
  {
    points: [
      { ath: 6.213936125936755, atv: -16.657479798192725 },
      { ath: 5.857865128247795, atv: -17.67093585504843 },
      { ath: -0.04003328557610075, atv: -17.65826728892023 },
      { ath: 0.028470401456274885, atv: -16.695056879016715 },
    ],
    id: "hs389",
  },
  {
    points: [
      { ath: 1.750535907229903, atv: -17.885026627220434 },
      { ath: 1.579495484506424, atv: -20.144577418022546 },
      { ath: -2.330443108648808, atv: -20.133863903322908 },
      { ath: -2.3259435970630875, atv: -18.16613662119949 },
    ],
    id: "hs390",
  },
  {
    points: [
      { ath: -2.3616752276790294, atv: -17.902540777579986 },
      { ath: -2.2353075779777782, atv: -20.071709978467027 },
      { ath: 1.5106046985748662, atv: -20.077761696593832 },
      { ath: 1.7948141171827956, atv: -18.039289313769185 },
    ],
    id: "hs391",
  },
  {
    points: [
      { ath: -0.6868496020902057, atv: -17.634291906106526 },
      { ath: -0.7299286151920796, atv: -16.733453521975704 },
      { ath: -6.64391528784995, atv: -16.632698163479702 },
      { ath: -6.402513805829926, atv: -17.4926814919091 },
    ],
    id: "hs392",
  },
  {
    points: [
      { ath: -3.1562193773328318, atv: -17.89755987792392 },
      { ath: -2.9137654138455673, atv: -20.03064692946105 },
      { ath: -6.239552172768924, atv: -20.013403479788703 },
      { ath: -6.376071432762956, atv: -18.674664050002104 },
    ],
    id: "hs393",
  },
  {
    points: [
      { ath: -6.327749440540288, atv: -19.93362061179213 },
      { ath: -6.4419355146361, atv: -18.637093983589015 },
      { ath: -7.065137922998815, atv: -18.602862601805498 },
      { ath: -7.164912397673504, atv: -17.94272540355196 },
      { ath: -9.33777444409617, atv: -17.775186710435154 },
      { ath: -8.788659562322323, atv: -19.84927442555503 },
    ],
    id: "hs394",
  },
  {
    points: [
      { ath: 11.836592156377492, atv: -13.839177199808056 },
      { ath: 11.494175021061892, atv: -14.804531198871128 },
      { ath: 8.711746087599408, atv: -14.778036585210176 },
      { ath: 8.813441652201448, atv: -13.97013996732572 },
    ],
    id: "hs395",
  },
  {
    points: [
      { ath: 8.27889733492185, atv: -13.98559192487498 },
      { ath: 8.084128736910998, atv: -14.774934810970485 },
      { ath: 5.156686746339176, atv: -14.883329432514113 },
      { ath: 5.25351232415079, atv: -14.094637952731143 },
    ],
    id: "hs396",
  },
  {
    points: [
      { ath: 11.655526645010866, atv: -12.167504549651827 },
      { ath: 11.320765782970454, atv: -13.23453079397901 },
      { ath: -4.002937513065888, atv: -13.516029510786534 },
      { ath: -4.083125610995069, atv: -12.492768403662923 },
    ],
    id: "hs397",
  },
  {
    points: [
      { ath: 4.779147937593962, atv: -14.03592914150446 },
      { ath: 4.6813376295573335, atv: -14.890348194626116 },
      { ath: 1.776899660804986, atv: -14.890021596506847 },
      { ath: 1.7756695689822664, atv: -14.035801667346906 },
    ],
    id: "hs398",
  },
  {
    points: [
      { ath: 1.2782804345110321, atv: -14.079593248494351 },
      { ath: 1.2544385803737441, atv: -14.933866179483966 },
      { ath: -1.7857974178352265, atv: -14.888442126590958 },
      { ath: -1.815749942768491, atv: -14.05685967441655 },
    ],
    id: "hs399",
  },
  {
    points: [
      { ath: -10.085398256314932, atv: -17.833850666147015 },
      { ath: -9.37328137451658, atv: -19.878277014863002 },
      { ath: -11.985391672512776, atv: -19.854644332685016 },
      { ath: -12.781468295753598, atv: -17.801265961368305 },
    ],
    id: "hs400",
  },
  {
    points: [
      { ath: -7.257917919311922, atv: -16.641943923806565 },
      { ath: -7.0184303058301225, atv: -17.584328657071616 },
      { ath: -12.981359762923375, atv: -17.468349928296238 },
      { ath: -13.346806811889792, atv: -16.408330145741385 },
    ],
    id: "hs401",
  },
  {
    points: [
      { ath: -14.008012321623255, atv: -16.415772879020512 },
      { ath: -13.484500913681359, atv: -17.392068618580254 },
      { ath: -19.532921913866517, atv: -16.885734687435114 },
      { ath: -20.196749734979107, atv: -16.07183408565992 },
    ],
    id: "hs402",
  },
  {
    points: [
      { ath: -2.396763273739566, atv: -14.14224989421444 },
      { ath: -2.3674058598678016, atv: -14.970090997836747 },
      { ath: -5.3472114866550555, atv: -14.906482948682736 },
      { ath: -5.364443672414552, atv: -14.031080016455599 },
    ],
    id: "hs403",
  },
  {
    points: [
      { ath: -6.019711205917929, atv: -13.952603224298771 },
      { ath: -5.8228047013082005, atv: -14.913660653876539 },
      { ath: -8.842743450482772, atv: -14.782700872687395 },
      { ath: -8.911532205754838, atv: -13.949525491452347 },
    ],
    id: "hs404",
  },
  {
    points: [
      { ath: -9.499201743624553, atv: -14.01490999772619 },
      { ath: -9.296379833086178, atv: -14.60717058929222 },
      { ath: -12.289304550610098, atv: -14.650780726337802 },
      { ath: -12.497889633439229, atv: -13.684842406074901 },
    ],
    id: "hs405",
  },
  {
    points: [
      { ath: -12.913979835099383, atv: -17.557401832792422 },
      { ath: -12.098865978617027, atv: -19.699389957486826 },
      { ath: -15.111417688222787, atv: -19.62345621422425 },
      { ath: -16.08924132430775, atv: -17.47053600206402 },
    ],
    id: "hs406",
  },
  {
    points: [
      { ath: -16.727483855146488, atv: -17.534224926211188 },
      { ath: -15.825906310198377, atv: -19.619877307360277 },
      { ath: -21.07987888031971, atv: -19.195399037877074 },
      { ath: -22.613188632292008, atv: -17.026343825033187 },
      { ath: -20.681922741586845, atv: -17.147499066403064 },
      { ath: -20.31985335313692, atv: -17.7737979935583 },
      { ath: -18.618019802863728, atv: -17.821453959938857 },
      { ath: -18.724632049986667, atv: -17.273159573920957 },
    ],
    id: "hs407",
  },
  {
    points: [
      { ath: -4.852344136833551, atv: -12.35445105612659 },
      { ath: -4.805397341268645, atv: -13.513170675079595 },
      { ath: -20.13158978302272, atv: -12.994654807169544 },
      { ath: -20.17387691810484, atv: -11.839520838308676 },
    ],
    id: "hs408",
  },
  {
    points: [
      { ath: -12.88944435869422, atv: -13.725695219153144 },
      { ath: -12.625317182992887, atv: -14.625900930304983 },
      { ath: -15.434623585480495, atv: -14.552148903083435 },
      { ath: -15.7748281524606, atv: -13.754381918252871 },
    ],
    id: "hs409",
  },
  {
    points: [
      { ath: -16.09024133449651, atv: -13.675293151625862 },
      { ath: -15.974435470182755, atv: -14.501946868546332 },
      { ath: -18.659125059073858, atv: -14.318401729454513 },
      { ath: -18.892619499614398, atv: -13.88360038510826 },
      { ath: -19.36623525513835, atv: -13.889831421106894 },
      { ath: -19.246930818376597, atv: -14.304864956533661 },
      { ath: -20.582506287897218, atv: -14.274641775914558 },
      { ath: -21.086734873469823, atv: -13.356517483052237 },
    ],
    id: "hs410",
  },
  {
    points: [
      { ath: -20.74047551952208, atv: -14.297711545223311 },
      { ath: -21.108214219414776, atv: -13.46624152543268 },
      { ath: -22.417361686635616, atv: -13.359697778135194 },
      { ath: -22.009727045201146, atv: -14.215123263871636 },
    ],
    id: "hs411",
  },
  {
    points: [
      { ath: -20.167753782875593, atv: -16.87237085803031 },
      { ath: -20.67740678434052, atv: -15.99920866503466 },
      { ath: -26.810498480559886, atv: -15.415675680689194 },
      { ath: -26.06824462561866, atv: -16.440179206869907 },
    ],
    id: "hs412",
  },
  {
    points: [
      { ath: -23.177613941547634, atv: -17.01936689994156 },
      { ath: -21.648027555772046, atv: -19.045676025017535 },
      { ath: -24.34639705526547, atv: -18.866196868407105 },
      { ath: -25.811178578331464, atv: -16.81007410658793 },
    ],
    id: "hs413",
  },
  {
    points: [
      { ath: -25.92790816827727, atv: -16.657964882766468 },
      { ath: -24.462298915145197, atv: -18.846962135321597 },
      { ath: -27.243170321668515, atv: -18.494216937657335 },
      { ath: -28.839624198996773, atv: -16.63226050052148 },
    ],
    id: "hs414",
  },
  {
    points: [
      { ath: -23.079950255461767, atv: -13.320627077474358 },
      { ath: -22.767280358062067, atv: -14.055595802197496 },
      { ath: -24.026659849542852, atv: -13.995606515042061 },
      { ath: -24.380952400702995, atv: -13.236680450776088 },
    ],
    id: "hs415",
  },
  {
    points: [
      { ath: -25.01085845294824, atv: -13.137158724549701 },
      { ath: -24.682074854101415, atv: -13.854056981567846 },
      { ath: -27.39058150189402, atv: -13.773642628516505 },
      { ath: -27.75599045490935, atv: -12.964576498514571 },
    ],
    id: "hs416",
  },
  {
    points: [
      { ath: -28.318318996837945, atv: -12.965977264683493 },
      { ath: -27.932959476507676, atv: -13.666263051427848 },
      { ath: -30.55191661023281, atv: -13.550895762428425 },
      { ath: -30.791069721581835, atv: -12.71716769112725 },
    ],
    id: "hs417",
  },
  {
    points: [
      { ath: -31.485238893160385, atv: -12.601504654623886 },
      { ath: -31.02284264606658, atv: -13.327956654642254 },
      { ath: -33.87203776771753, atv: -13.198710005704008 },
      { ath: -34.31689487676647, atv: -12.339399743684758 },
    ],
    id: "hs418",
  },
  {
    points: [
      { ath: -35.79946944902957, atv: -12.21806183030212 },
      { ath: -34.51921745879133, atv: -12.400471692953122 },
      { ath: -34.09414593423958, atv: -13.128684392040931 },
      { ath: -35.450389855449146, atv: -13.00968250504587 },
    ],
    id: "hs419",
  },
  {
    points: [
      { ath: -27.40289076850479, atv: -15.397907000756055 },
      { ath: -26.84814233150462, atv: -16.250708247531303 },
      { ath: -31.487805495325006, atv: -15.889025172168667 },
      { ath: -32.33657257319112, atv: -14.977180068710172 },
    ],
    id: "hs420",
  },
  {
    points: [
      { ath: -20.73821123896164, atv: -12.8674146370401 },
      { ath: -21.15549755227562, atv: -11.794866991997962 },
      { ath: -37.14160447411206, atv: -10.603823325930566 },
      { ath: -36.30853584386625, atv: -11.662714489191998 },
      { ath: -36.30853584386625, atv: -11.662714489191998 },
    ],
    id: "hs421",
  },
  {
    points: [
      { ath: -29.30584764841109, atv: -16.498180675613888 },
      { ath: -27.821222358460545, atv: -18.436623135222437 },
      { ath: -32.45000482641865, atv: -18.03512523320109 },
      { ath: -34.17170073186395, atv: -15.94360590211293 },
      { ath: -32.82635446571726, atv: -15.844817782076824 },
      { ath: -32.72081426705063, atv: -16.85307480445658 },
      { ath: -30.913432367924486, atv: -16.822753724291637 },
      { ath: -31.34198327251852, atv: -16.04217291641682 },
    ],
    id: "hs422",
  },
  {
    points: [
      { ath: -34.5709502612911, atv: -15.95687572439369 },
      { ath: -32.908494241593075, atv: -17.855731142789146 },
      { ath: -37.25127953471207, atv: -17.60576588649137 },
      { ath: -38.68565625595318, atv: -15.524595189956903 },
    ],
    id: "hs423",
  },
  {
    points: [
      { ath: -37.59963261253472, atv: -14.21613077973323 },
      { ath: -36.73786933618493, atv: -15.315664920692246 },
      { ath: -32.155953472293106, atv: -15.778215751969485 },
      { ath: -32.98343218318195, atv: -14.790553341116405 },
    ],
    id: "hs424",
  },
  {
    points: [
      { ath: -35.377493748902964, atv: -13.42843916112959 },
      { ath: -35.068234477030785, atv: -13.986724003623733 },
      { ath: -37.87712741092588, atv: -13.848796376313514 },
      { ath: -38.341060626872604, atv: -13.068717623726407 },
    ],
    id: "hs425",
  },
  {
    points: [
      { ath: -35.57122812693552, atv: -12.954339148134324 },
      { ath: -37.63078029874089, atv: -10.603422423458701 },
      { ath: -43.08474576620563, atv: -10.21282212503394 },
      { ath: -41.73534859949223, atv: -12.630062113862985 },
    ],
    id: "hs426",
  },
  {
    points: [
      { ath: -38.64211033027806, atv: -13.203494194019253 },
      { ath: -38.44551236299009, atv: -13.697973703839956 },
      { ath: -41.806310028766404, atv: -13.555004043072135 },
      { ath: -42.134522017527786, atv: -12.839180970004472 },
    ],
    id: "hs427",
  },
  {
    points: [
      { ath: -37.93373254197081, atv: -14.247710151972893 },
      { ath: -36.92593743455723, atv: -15.364202306988348 },
      { ath: -41.09842974343087, atv: -14.964107209905016 },
      { ath: -41.88737315642015, atv: -13.993490855425506 },
    ],
    id: "hs428",
  },
  {
    points: [
      { ath: -39.046177903301555, atv: -15.634218961718364 },
      { ath: -37.84638154442314, atv: -17.426153184304994 },
      { ath: -42.09425643128753, atv: -17.328795508074318 },
      { ath: -42.578250837034375, atv: -15.844340560672 },
      { ath: -42.577885681961504, atv: -15.866276381384894 },
    ],
    id: "hs429",
  },
  {
    points: [
      { ath: -43.65569793160563, atv: -15.308297642174669 },
      { ath: -42.71642344088673, atv: -17.18527956756137 },
      { ath: -46.968986802755694, atv: -17.027322674948913 },
      { ath: -47.47938015349621, atv: -15.156815108773596 },
    ],
    id: "hs430",
  },
  {
    points: [
      { ath: -42.38580386168803, atv: -13.825738713847919 },
      { ath: -41.80229222939124, atv: -14.823825435754026 },
      { ath: -45.590861548743135, atv: -14.702938857821099 },
      { ath: -46.04299448519362, atv: -13.672444940773575 },
    ],
    id: "hs431",
  },
  {
    points: [
      { ath: -43.52550529792592, atv: -12.766903710545312 },
      { ath: -43.361177240010534, atv: -13.355531968706492 },
      { ath: -46.244911564742495, atv: -13.234324304364652 },
      { ath: -46.445186743476256, atv: -12.621756259129056 },
    ],
    id: "hs432",
  },
  {
    points: [
      { ath: -43.7760983620924, atv: -10.158800365633711 },
      { ath: -42.361606636848364, atv: -12.623976231563635 },
      { ath: -48.683711703860695, atv: -12.366263580760057 },
      { ath: -49.395008756695916, atv: -9.88437949388159 },
    ],
    id: "hs433",
  },
  {
    points: [
      { ath: -46.713117738320136, atv: -13.535157167534017 },
      { ath: -46.146346111750574, atv: -14.723930385636768 },
      { ath: -54.7917950874164, atv: -14.556314015189127 },
      { ath: -54.84609233739144, atv: -13.503703809404309 },
    ],
    id: "hs434",
  },
  {
    points: [
      { ath: -48.327966028015794, atv: -15.073229457871076 },
      { ath: -47.90670177080699, atv: -17.060147989011874 },
      { ath: -51.86197108338706, atv: -16.90494632239885 },
      { ath: -52.15368881375082, atv: -15.091770658127556 },
    ],
    id: "hs435",
  },
  {
    points: [
      { ath: -50.19855241895522, atv: -9.890624780884137 },
      { ath: -49.92353813863383, atv: -11.595386483536075 },
      { ath: -49.37031762715435, atv: -12.255053861100563 },
      { ath: -55.66459585200198, atv: -12.018990861616166 },
      { ath: -55.695375108217775, atv: -10.01418135136354 },
    ],
    id: "hs436",
  },
  {
    points: [
      { ath: -52.832284486439676, atv: -14.936142253630857 },
      { ath: -52.54790116046627, atv: -16.906055803307954 },
      { ath: -56.79145151732524, atv: -16.932295917566407 },
      { ath: -56.78901185058686, atv: -14.959320853238651 },
    ],
    id: "hs437",
  },
  {
    points: [
      { ath: -55.365749058603285, atv: -13.419572086722912 },
      { ath: -55.28710611408235, atv: -14.59797206410886 },
      { ath: -59.12962441346605, atv: -14.575426678014196 },
      { ath: -59.116532975642315, atv: -13.631594559308002 },
    ],
    id: "hs438",
  },
  {
    points: [
      { ath: -55.461106849501164, atv: -12.395906225120566 },
      { ath: -55.46697850086076, atv: -12.98512141169804 },
      { ath: -58.559679023434455, atv: -13.03459041280523 },
      { ath: -58.61126063815476, atv: -12.509550026647247 },
    ],
    id: "hs439",
  },
  {
    points: [
      { ath: -47.089065472551226, atv: -12.744764690506097 },
      { ath: -46.84810560787099, atv: -13.23655157635396 },
      { ath: -50.21195686997851, atv: -13.130406964387744 },
      { ath: -50.40786489984208, atv: -12.435094994188729 },
      { ath: -50.40786489984208, atv: -12.435094994188729 },
    ],
    id: "hs440",
  },
  {
    points: [
      { ath: -51.746763951071955, atv: -12.587605278212154 },
      { ath: -51.69412649263535, atv: -13.024543173513086 },
    ],
    id: "hs441",
  },
  {
    points: [
      { ath: -51.79402108740152, atv: -12.43483666067738 },
      { ath: -51.829479439032866, atv: -12.960452178182184 },
      { ath: -54.821316477118444, atv: -13.006305491015183 },
      { ath: -54.73265474563243, atv: -12.56766774616038 },
    ],
    id: "hs442",
  },
  {
    points: [
      { ath: -57.38449482004148, atv: -15.011970252513938 },
      { ath: -57.4993286287409, atv: -16.946397292172122 },
      { ath: -61.632014979368535, atv: -17.02916288405835 },
      { ath: -61.24984656120722, atv: -15.111065158845783 },
      { ath: -60.2032571852514, atv: -15.038728630833438 },
      { ath: -60.11619686496215, atv: -15.546870928792453 },
      { ath: -58.571707215456115, atv: -15.444604226101188 },
      { ath: -58.501271109869094, atv: -15.096743846986339 },
    ],
    id: "hs443",
  },
  {
    points: [
      { ath: -61.779351551191894, atv: -12.367385354585622 },
      { ath: -61.387356118998696, atv: -9.918411046594082 },
      { ath: -56.58697912998656, atv: -9.769664593837108 },
      { ath: -56.33723145229334, atv: -12.28533887517347 },
    ],
    id: "hs444",
  },
  {
    points: [
      { ath: -62.08011548275658, atv: -10.054581960213016 },
      { ath: -62.54269815822454, atv: -12.32148431637087 },
      { ath: -66.94006595106828, atv: -12.486696270308443 },
      { ath: -66.14256769926612, atv: -10.114157995370208 },
    ],
    id: "hs445",
  },
  {
    points: [
      { ath: -59.88384771202516, atv: -12.510333193344287 },
      { ath: -59.77059881126024, atv: -14.63154194369014 },
      { ath: -63.475740106854175, atv: -14.68976282193033 },
      { ath: -62.87070807686132, atv: -12.762382490094643 },
    ],
    id: "hs446",
  },
  {
    points: [
      { ath: -62.001843854566914, atv: -15.079585739943115 },
      { ath: -62.03788008251752, atv: -15.936312209166848 },
      { ath: -65.98476741404352, atv: -15.72867873299536 },
    ],
    id: "hs447",
  },
  {
    points: [
      { ath: -66.53409464026487, atv: -15.463215537119176 },
      { ath: -66.93947916718497, atv: -17.096873768598616 },
      { ath: -71.58875318717969, atv: -17.415530799767115 },
      { ath: -71.20183493265785, atv: -15.492388120588675 },
      { ath: -69.29755183991307, atv: -15.412440268754825 },
      { ath: -69.32774347860556, atv: -16.16674108544891 },
      { ath: -67.85947205891563, atv: -16.09085039934489 },
      { ath: -67.56965331745619, atv: -15.183823149565315 },
    ],
    id: "hs448",
  },
  {
    points: [
      { ath: -66.31409574304308, atv: -17.144894941437528 },
      { ath: -66.0864811680022, atv: -15.916326716856117 },
      { ath: -61.937149225822, atv: -15.74620071114543 },
      { ath: -62.107955059245626, atv: -17.205386844824005 },
    ],
    id: "hs449",
  },
  {
    points: [
      { ath: -66.89631408456302, atv: -10.156665898151298 },
      { ath: -67.51224231915819, atv: -12.642389169881756 },
      { ath: -71.62986425776705, atv: -12.885616228609425 },
      { ath: -70.74109075874844, atv: -10.416458213443512 },
    ],
    id: "hs450",
  },
  {
    points: [
      { ath: -63.61924366442054, atv: -12.811661642812965 },
      { ath: -63.61266626173568, atv: -13.190273420894316 },
      { ath: -67.0263471916071, atv: -13.338353619855804 },
      { ath: -67.0587116881818, atv: -12.990445774549906 },
    ],
    id: "hs451",
  },
  {
    points: [
      { ath: -63.927430367167176, atv: -13.857984421758049 },
      { ath: -64.07677549680551, atv: -14.777784581821848 },
      { ath: -68.24242013842866, atv: -14.950413579271444 },
      { ath: -67.9420929899295, atv: -14.001143567108222 },
    ],
    id: "hs452",
  },
  {
    points: [
      { ath: -68.49685305467648, atv: -13.934919989300278 },
      { ath: -68.7689499702966, atv: -15.105354017864462 },
      { ath: -73.80925885190891, atv: -15.324522608915359 },
      { ath: -73.38275933039859, atv: -14.23345436090894 },
    ],
    id: "hs453",
  },
  {
    points: [
      { ath: -71.47990861175384, atv: -10.563358394618472 },
      { ath: -72.27253391949012, atv: -12.74748161787219 },
      { ath: -76.94481359553782, atv: -12.95574574002416 },
      { ath: -76.32992522079206, atv: -10.705220735118058 },
    ],
    id: "hs454",
  },
  {
    points: [
      { ath: -73.74474852478335, atv: -13.40968819237001 },
      { ath: -79.14578668308076, atv: -13.457045575439455 },
      { ath: -79.12791435701024, atv: -14.05762396275524 },
      { ath: -73.77652119188434, atv: -13.78998340124505 },
    ],
    id: "hs455",
  },
  {
    points: [
      { ath: -73.97183479357608, atv: -14.297462714460343 },
      { ath: -74.26676795083608, atv: -15.439700436349364 },
      { ath: -79.06938618188144, atv: -15.67176918237857 },
      { ath: -78.8443012214587, atv: -14.442631844343847 },
    ],
    id: "hs456",
  },
  {
    points: [
      { ath: -71.56627889266264, atv: -15.645919606721737 },
      { ath: -72.27142299677286, atv: -17.58811621131417 },
      { ath: -77.33315795526318, atv: -17.953318008186763 },
      { ath: -76.67493250890453, atv: -15.870176498798218 },
    ],
    id: "hs457",
  },
  {
    points: [
      { ath: -77.01492525431172, atv: -10.72386230795023 },
      { ath: -77.55672634420404, atv: -13.219715764957078 },
      { ath: -83.2399814359556, atv: -13.13443471143622 },
      { ath: -83.21179599349938, atv: -10.806800452879072 },
    ],
    id: "hs458",
  },
  {
    points: [
      { ath: -80.42542585611363, atv: -13.524751600425123 },
      { ath: -80.46503406963882, atv: -14.09437778693372 },
      { ath: -84.56080112445096, atv: -14.080622063869058 },
      { ath: -84.48184075849264, atv: -13.641557131147184 },
    ],
    id: "hs459",
  },
  {
    points: [
      { ath: -79.48959194239382, atv: -14.641481238793656 },
      { ath: -79.5961530240682, atv: -15.655832874392388 },
      { ath: -84.64782581742168, atv: -15.71840063454383 },
      { ath: -84.57984753656956, atv: -14.647708133923212 },
    ],
    id: "hs460",
  },
  {
    points: [
      { ath: -77.41963646791748, atv: -16.169013071623134 },
      { ath: -78.04822461902194, atv: -17.851304428159523 },
      { ath: -82.54372698278894, atv: -18.05091992184692 },
      { ath: -82.23458219887755, atv: -16.032170036152642 },
      { ath: -80.88660088658372, atv: -16.118079624686352 },
    ],
    id: "hs461",
  },
  {
    points: [
      { ath: -89.74827014779663, atv: -11.096620877343772 },
      { ath: -89.84082887704443, atv: -13.42170393155782 },
      { ath: -83.82603280494027, atv: -13.376997173691125 },
      { ath: -83.99839611726617, atv: -10.823221616312066 },
    ],
    id: "hs462",
  },
  {
    points: [
      { ath: -85.22821509052312, atv: -13.789513678611057 },
      { ath: -85.2289585008649, atv: -14.074531742296625 },
      { ath: -89.44070788097622, atv: -14.250362349422486 },
      { ath: -89.42540191941504, atv: -13.619105628373841 },
    ],
    id: "hs463",
  },
  {
    points: [
      { ath: -85.19750354358092, atv: -14.803522223330324 },
      { ath: -85.36492757559824, atv: -15.818187359845417 },
      { ath: -84.67226581329862, atv: -15.723929352024037 },
    ],
    id: "hs464",
  },
  {
    points: [
      { ath: -85.29509978228037, atv: -14.708183333572089 },
      { ath: -85.46356334056094, atv: -15.881335097211561 },
      { ath: -89.80751476424854, atv: -15.88645443472989 },
      { ath: -89.81187841421115, atv: -14.81136423296874 },
    ],
    id: "hs465",
  },
  {
    points: [
      { ath: -83.1075390223599, atv: -16.312868896112846 },
      { ath: -83.14806463751114, atv: -17.421882540674765 },
      { ath: -87.33175901851962, atv: -17.564639542285786 },
      { ath: -87.13450795153602, atv: -16.326586933329434 },
    ],
    id: "hs466",
  },
  {
    points: [
      { ath: -87.89067568943483, atv: -16.360050196222744 },
      { ath: -87.93470082209569, atv: -17.564838387266555 },
      { ath: -92.29072537138217, atv: -17.467792959084615 },
      { ath: -92.25274783145619, atv: -16.293767885073077 },
      { ath: -90.93006952558574, atv: -16.29448735187195 },
      { ath: -90.89530125805209, atv: -16.86568472014447 },
      { ath: -89.30293969358439, atv: -16.981937192926004 },
      { ath: -89.21129818866632, atv: -16.31485648756055 },
    ],
    id: "hs467",
  },
  {
    points: [
      { ath: -92.88137004742055, atv: -16.290546862212643 },
      { ath: -92.7575200904829, atv: -17.560515995674162 },
      { ath: -97.16965469813738, atv: -17.609673217655082 },
      { ath: -97.20570460502819, atv: -16.597353191409187 },
    ],
    id: "hs468",
  },
  {
    points: [
      { ath: -90.40963202737282, atv: -14.83307318062214 },
      { ath: -90.37128035949672, atv: -15.81630969892588 },
      { ath: -94.88700608958646, atv: -15.824171315588217 },
      { ath: -94.90375374227523, atv: -14.905165942637954 },
    ],
    id: "hs469",
  },
  {
    points: [
      { ath: -90.83907231992231, atv: -13.788711714968343 },
      { ath: -90.83753185528258, atv: -14.263961867541934 },
      { ath: -94.95739297273724, atv: -14.27119801448893 },
      { ath: -94.98379242155693, atv: -13.922801678562521 },
    ],
    id: "hs470",
  },
  {
    points: [
      { ath: -90.55854816137656, atv: -11.072117972146303 },
      { ath: -90.48217034159387, atv: -13.470919210579417 },
      { ath: -96.11320819158601, atv: -13.525077601455763 },
      { ath: -96.69629794342916, atv: -11.091402079470216 },
    ],
    id: "hs471",
  },
  {
    points: [
      { ath: -97.52343175397846, atv: -10.982105427331753 },
      { ath: -96.92186680635479, atv: -13.477344991681449 },
      { ath: -102.70501873215056, atv: -13.47312550598907 },
      { ath: -103.28329491820438, atv: -11.06086785538039 },
    ],
    id: "hs472",
  },
  {
    points: [
      { ath: -95.7016914024058, atv: -13.94310767257728 },
      { ath: -95.57956378074101, atv: -14.32462998445334 },
      { ath: -99.6935018715892, atv: -14.344186145809385 },
      { ath: -99.83129867482342, atv: -13.805615332909225 },
    ],
    id: "hs473",
  },
  {
    points: [
      { ath: -95.5280926962721, atv: -14.99000030749212 },
      { ath: -95.48145250819755, atv: -15.909102982974785 },
      { ath: -100.37305215937647, atv: -15.889490160465495 },
      { ath: -100.61763600957329, atv: -14.906272116304523 },
    ],
    id: "hs474",
  },
  {
    points: [
      { ath: -97.8680616230464, atv: -16.530946165374104 },
      { ath: -99.15917530194093, atv: -16.52830094792082 },
      { ath: -99.19321676680858, atv: -16.940843728620315 },
      { ath: -100.78359402568054, atv: -16.894638489957895 },
      { ath: -100.87472920430622, atv: -16.32267430558423 },
      { ath: -102.71994601324826, atv: -16.322323155620232 },
      { ath: -102.16896281852223, atv: -18.29680560168934 },
      { ath: -97.43012226695987, atv: -18.274995574455826 },
    ],
    id: "hs475",
  },
  {
    points: [
      { ath: -101.27865031395743, atv: -14.827654388451768 },
      { ath: -100.93416459634653, atv: -15.909705506612292 },
      { ath: -105.8530446278566, atv: -15.85416567324088 },
      { ath: -106.34040373423554, atv: -14.705196170683235 },
    ],
    id: "hs476",
  },
  {
    points: [
      { ath: -101.2441086484261, atv: -13.728616028662447 },
      { ath: -101.19399140874958, atv: -14.329240234984765 },
      { ath: -106.37765612265017, atv: -14.203155204025366 },
      { ath: -106.76177300405706, atv: -13.65636735554942 },
    ],
    id: "hs477",
  },
  {
    points: [
      { ath: -103.40767151373575, atv: -16.355782744919658 },
      { ath: -102.71433000268559, atv: -18.145983594800036 },
      { ath: -107.71070458290922, atv: -17.9839875114137 },
      { ath: -108.62407085188929, atv: -16.184152268857073 },
    ],
    id: "hs478",
  },
  {
    points: [
      { ath: -104.03007340382362, atv: -10.925207403736579 },
      { ath: -103.29271305028226, atv: -13.469106263150138 },
      { ath: -108.04165829883141, atv: -13.215684115794014 },
      { ath: -109.00458767113497, atv: -10.84811140889892 },
    ],
    id: "hs479",
  },
  {
    points: [
      { ath: -109.6148464159433, atv: -10.934688227967477 },
      { ath: -108.5968989031895, atv: -13.399176003248638 },
      { ath: -112.7301720937333, atv: -13.317152687177654 },
      { ath: -113.52725258572985, atv: -10.952218352987343 },
    ],
    id: "hs480",
  },
  {
    points: [
      { ath: -107.30076850631093, atv: -13.790247251932698 },
      { ath: -107.23995128818999, atv: -14.29671412905644 },
      { ath: -111.95653682234871, atv: -14.186912429371201 },
      { ath: -112.15952874761985, atv: -13.521005715288005 },
    ],
    id: "hs481",
  },
  {
    points: [
      { ath: -106.88325656569975, atv: -14.647103540213793 },
      { ath: -106.52827301182174, atv: -15.75806193490565 },
      { ath: -111.32093778880079, atv: -15.62271044860736 },
      { ath: -111.61254931820869, atv: -14.542383024658934 },
    ],
    id: "hs482",
  },
  {
    points: [
      { ath: -109.29956960886835, atv: -16.077428513580124 },
      { ath: -108.47375834170339, atv: -18.055634702444745 },
      { ath: -113.00863694761941, atv: -17.936192505790412 },
      { ath: -113.71362874039878, atv: -16.051099427248225 },
      { ath: -112.6371941361412, atv: -15.96296513947169 },
      { ath: -112.49314198959317, atv: -16.472224040377135 },
      { ath: -110.8896343243224, atv: -16.550271358898076 },
      { ath: -110.90621670563036, atv: -15.980742922689405 },
    ],
    id: "hs483",
  },
  {
    points: [
      { ath: -112.20110588626031, atv: -14.473758815597838 },
      { ath: -111.92021655888482, atv: -15.640739523552257 },
      { ath: -116.23119412697673, atv: -15.436933781437512 },
      { ath: -116.4205711700308, atv: -14.422744107962552 },
    ],
    id: "hs484",
  },
  {
    points: [
      { ath: -113.54872523359711, atv: -13.442944973444638 },
      { ath: -113.38071644723794, atv: -14.041913578805982 },
      { ath: -116.44911223577301, atv: -14.01175180981806 },
      { ath: -116.5095010698484, atv: -13.41138961311912 },
    ],
    id: "hs485",
  },
  {
    points: [
      { ath: -114.4045553342788, atv: -10.712050678531385 },
      { ath: -113.29151501257016, atv: -13.188956032921327 },
      { ath: -117.99894715977655, atv: -13.051709415580003 },
      { ath: -118.28088726384041, atv: -10.695830698310964 },
    ],
    id: "hs486",
  },
  {
    points: [
      { ath: -114.38640748845583, atv: -15.946620863368095 },
      { ath: -113.77909553231112, atv: -17.910946828550227 },
      { ath: -117.91124107873966, atv: -17.798615345906587 },
      { ath: -118.27582174990823, atv: -15.891619497311517 },
    ],
    id: "hs487",
  },
  {
    points: [
      { ath: -117.32211658278467, atv: -13.405351817530411 },
      { ath: -117.1328861845796, atv: -13.912182293695334 },
      { ath: -120.3182668340329, atv: -13.927189885368499 },
      { ath: -120.36277160438297, atv: -13.234142976574786 },
    ],
    id: "hs488",
  },
  {
    points: [
      { ath: -117.07357219540785, atv: -14.38669877452252 },
      { ath: -116.69121538967133, atv: -15.46588950317922 },
      { ath: -120.65743132753673, atv: -15.464182994089063 },
      { ath: -120.58910426265692, atv: -14.29905834941436 },
    ],
    id: "hs489",
  },
  {
    points: [
      { ath: -119.01449622920708, atv: -10.656644324383453 },
      { ath: -118.58334805600114, atv: -13.139037203936693 },
      { ath: -123.81645971780446, atv: -13.04547932168205 },
      { ath: -124.07573983283265, atv: -10.582925781175417 },
    ],
    id: "hs490",
  },
  {
    points: [
      { ath: -121.3333582506398, atv: -14.280562993720652 },
      { ath: -121.24583907014483, atv: -15.511577995956563 },
      { ath: -125.09331746863268, atv: -15.52670964586528 },
      { ath: -124.99473280225948, atv: -14.312011452227594 },
    ],
    id: "hs491",
  },
  {
    points: [
      { ath: -118.93489715062464, atv: -15.944111674203437 },
      { ath: -118.67675649571987, atv: -17.81760953443501 },
      { ath: -122.9369177227785, atv: -17.786872999218726 },
      { ath: -122.82240786975487, atv: -15.902133350818403 },
      { ath: -121.74574425313489, atv: -15.812176879962648 },
      { ath: -121.67116442400794, atv: -16.445246416057895 },
      { ath: -120.09786084541736, atv: -16.457477493256583 },
      { ath: -120.045728640129, atv: -15.763213808431512 },
    ],
    id: "hs492",
  },
  {
    points: [
      { ath: -124.83643041185752, atv: -10.758867830724547 },
      { ath: -124.39856534411419, atv: -13.146283097336827 },
      { ath: -128.0316171872337, atv: -13.266378246812021 },
      { ath: -127.74114693808531, atv: -11.88987123209094 },
      { ath: -130.58720337070918, atv: -11.896157699282202 },
      { ath: -130.29125087138686, atv: -10.782367349961412 },
    ],
    id: "hs493",
  },
  {
    points: [
      { ath: -123.48783897462891, atv: -15.898980311205946 },
      { ath: -123.48699973806441, atv: -17.707379398674128 },
      { ath: -127.58507075449609, atv: -17.951740475220802 },
      { ath: -127.44604440146094, atv: -16.149280277703276 },
    ],
    id: "hs494",
  },
  {
    points: [
      { ath: -128.1451514298367, atv: -16.51507118734973 },
      { ath: -128.31610763126028, atv: -17.99861802122346 },
      { ath: -131.68765862303474, atv: -18.10756192801243 },
      { ath: -131.4609933626453, atv: -16.699878379554068 },
    ],
    id: "hs495",
  },
  {
    points: [
      { ath: -131.91186639885765, atv: -18.003557778783467 },
      { ath: -132.83353212875193, atv: -18.1186656258326 },
      { ath: -132.3091132138503, atv: -16.7600395138339 },
      { ath: -131.5268899497908, atv: -16.72880963892237 },
    ],
    id: "hs496",
  },
  {
    points: [
      { ath: -132.9873725870132, atv: -16.69878482935172 },
      { ath: -133.48776738707636, atv: -18.148753422997306 },
      { ath: -137.74582114221482, atv: -18.34573342884323 },
      { ath: -136.97502104468444, atv: -16.974718732337244 },
    ],
    id: "hs497",
  },
  {
    points: [
      { ath: -131.11253607686578, atv: -10.98432244359023 },
      { ath: -131.2407769321375, atv: -11.484266036451684 },
      { ath: -131.58371293497044, atv: -11.496863366191029 },
      { ath: -131.77435116807587, atv: -12.031423616108391 },
      { ath: -144.55438678947303, atv: -13.031299833304818 },
      { ath: -143.79804960360065, atv: -11.964945707435648 },
    ],
    id: "hs498",
  },
  {
    points: [
      { ath: -144.44454154132004, atv: -12.023223422617177 },
      { ath: -145.4991096157937, atv: -13.273043017377379 },
      { ath: -152.7464188414296, atv: -13.852652403441807 },
      { ath: -152.18158005551342, atv: -12.753835213597288 },
    ],
    id: "hs499",
  },
  {
    points: [
      { ath: -152.91171740832226, atv: -12.849169619357484 },
      { ath: -153.64303883677042, atv: -14.06057521228447 },
      { ath: -160.11775374625972, atv: -14.602197183643797 },
      { ath: -159.5584447373065, atv: -13.399425000029439 },
    ],
    id: "hs500",
  },
  {
    points: [
      { ath: -137.6759552386652, atv: -17.147883339713783 },
      { ath: -138.27987095080312, atv: -18.36367510606324 },
      { ath: -142.42153874343248, atv: -18.844821158432477 },
      { ath: -141.62039954815026, atv: -17.4805856455329 },
    ],
    id: "hs501",
  },
  {
    points: [
      { ath: -142.35309112926745, atv: -17.544362326132383 },
      { ath: -143.2267638614273, atv: -18.904975429851334 },
      { ath: -147.41888479734374, atv: -19.40046044815044 },
      { ath: -146.3192757561058, atv: -18.069165597754747 },
    ],
    id: "hs502",
  },
  {
    points: [
      { ath: -147.04904053573378, atv: -18.051806306694917 },
      { ath: -147.95283688398138, atv: -19.415560193957237 },
      { ath: -152.43927191066763, atv: -19.90306438894982 },
      { ath: -151.65619704221874, atv: -18.725620621575263 },
    ],
    id: "hs503",
  },
  {
    points: [
      { ath: -152.21247333149722, atv: -18.75880697348897 },
      { ath: -153.0095398428403, atv: -20.149368092062282 },
      { ath: -158.4792855338083, atv: -20.60998360347521 },
      { ath: -157.77546601357966, atv: -19.387587296296793 },
    ],
    id: "hs504",
  },
  {
    points: [
      { ath: -158.50284033692014, atv: -19.34992751519591 },
      { ath: -159.76488230292028, atv: -20.7755391126632 },
      { ath: -164.81848619050902, atv: -21.173185426600035 },
      { ath: -164.2370852152354, atv: -19.747822385728554 },
    ],
    id: "hs505",
  },
  {
    points: [
      { ath: -160.48377222980542, atv: -13.436618947603066 },
      { ath: -160.8776296879615, atv: -14.623860269237461 },
      { ath: -173.42638106827678, atv: -15.132631402454765 },
      { ath: -173.1861691736004, atv: -13.845460404771533 },
    ],
    id: "hs506",
  },
  {
    points: [
      { ath: -174.39457953900683, atv: -13.98105305558394 },
      { ath: -174.34848356965955, atv: -15.122852817376401 },
      { ath: 167.8854789323405, atv: -15.040427495399625 },
      { ath: 167.44299790824073, atv: -13.825941789566096 },
    ],
    id: "hs507",
  },
  {
    points: [
      { ath: 163.24490679899688, atv: -19.708639638342852 },
      { ath: 163.89514816510336, atv: -20.985686464050442 },
      { ath: 158.1991217655355, atv: -20.604023340705297 },
      { ath: 157.35975707870875, atv: -19.14248712019417 },
    ],
    id: "hs508",
  },
  {
    points: [
      { ath: 166.49075186541302, atv: -13.893860974162525 },
      { ath: 166.9077321649386, atv: -15.09049044603299 },
      { ath: 152.62998820081262, atv: -13.97024450557044 },
      { ath: 151.90298284500682, atv: -12.865744279779612 },
    ],
    id: "hs509",
  },
  {
    points: [
      { ath: 156.754285303281, atv: -19.26551774718062 },
      { ath: 157.52097712558867, atv: -20.602635298105916 },
      { ath: 152.5055916288826, atv: -20.03603275231712 },
      { ath: 150.99727955048894, atv: -18.720067354922 },
    ],
    id: "hs510",
  },
  {
    points: [
      { ath: 150.6660558516295, atv: -18.738507764196257 },
      { ath: 151.64092656991656, atv: -19.911373172499676 },
    ],
    id: "hs511",
  },
  {
    points: [
      { ath: 151.9101189493122, atv: -19.857941693232988 },
      { ath: 150.5638065414405, atv: -18.79732860778899 },
      { ath: 145.89237961779793, atv: -18.018797158573253 },
      { ath: 147.21414841146657, atv: -19.34906436299746 },
    ],
    id: "hs512",
  },
  {
    points: [
      { ath: 146.60661833111465, atv: -19.23176507424963 },
      { ath: 145.59385817049127, atv: -18.00037264482353 },
      { ath: 141.46515633659726, atv: -17.71693172519366 },
      { ath: 142.27810474500455, atv: -18.871179605721103 },
    ],
    id: "hs513",
  },
  {
    points: [
      { ath: 151.21287090342867, atv: -12.61621543530955 },
      { ath: 151.74540206298747, atv: -13.979641946598441 },
      { ath: 137.5632312707625, atv: -12.432104348860598 },
      { ath: 136.91453340758565, atv: -11.327179346678829 },
    ],
    id: "hs514",
  },
  {
    points: [
      { ath: 136.15926144363215, atv: -11.166831267848012 },
      { ath: 136.4569354471498, atv: -12.359148979844285 },
      { ath: 126.94920179157586, atv: -11.928103904046266 },
      { ath: 127.08637440975599, atv: -13.149841871040632 },
      { ath: 125.08691547405462, atv: -13.11050813856831 },
      { ath: 124.9058163388836, atv: -10.686351401344876 },
    ],
    id: "hs515",
  },
  {
    points: [
      { ath: 140.83853437041205, atv: -17.535238381185106 },
      { ath: 141.70845469409142, atv: -18.802662367536914 },
      { ath: 137.36965960837975, atv: -18.429008231581598 },
      { ath: 136.65484737648808, atv: -16.88126350710389 },
    ],
    id: "hs516",
  },
  {
    points: [
      { ath: 135.96044736906828, atv: -17.170790458637114 },
      { ath: 136.70214497550938, atv: -18.467350799779027 },
      { ath: 132.42735498500775, atv: -18.07382249623721 },
      { ath: 132.01753743978796, atv: -16.736263959963583 },
    ],
    id: "hs517",
  },
  {
    points: [
      { ath: 129.5424849148414, atv: 0.8852957826437023 },
      { ath: 135.01407008610963, atv: -3.280879654145153 },
      { ath: 126.14246763889008, atv: -3.718473561809298 },
      { ath: 124.55161370535282, atv: 0.5843291280124681 },
    ],
    id: "hs518",
  },
  {
    points: [
      { ath: 123.7102551540329, atv: 0.6455790411754732 },
      { ath: 124.62030887953205, atv: -1.4476036230805416 },
      { ath: 119.23143044028305, atv: -1.6289591261791283 },
      { ath: 120.12943245347861, atv: 0.4611148356992895 },
    ],
    id: "hs519",
  },
  {
    points: [
      { ath: 124.71491119509642, atv: -1.6024822806656907 },
      { ath: 125.67031877168964, atv: -3.8762136818687667 },
      { ath: 119.23237092949347, atv: -3.8047842153334126 },
      { ath: 118.48170622525785, atv: -3.6446762166498754 },
    ],
    id: "hs520",
  },
  {
    points: [
      { ath: 124.68310449529002, atv: -1.4483267656485692 },
      { ath: 119.16941611256217, atv: -1.6296240623849954 },
      { ath: 118.5493627509218, atv: -3.3651943724050417 },
    ],
    id: "hs521",
  },
  {
    points: [
      { ath: 118.00413953918209, atv: -3.538518752991325 },
      { ath: 118.60537224909356, atv: -1.517563766695269 },
      { ath: 112.24123473204409, atv: -1.4767093182837396 },
      { ath: 110.88843736255228, atv: -3.690477806105273 },
    ],
    id: "hs522",
  },
  {
    points: [
      { ath: 118.7940188286095, atv: -1.455721296728222 },
      { ath: 119.54192566717452, atv: 0.4504170811346602 },
      { ath: 114.16626763917165, atv: 0.6011400887180127 },
      { ath: 112.58708898559053, atv: -1.2013217024816256 },
    ],
    id: "hs523",
  },
  {
    points: [
      { ath: 135.06240547355932, atv: -4.5328174310104545 },
      { ath: 135.47881362068892, atv: -5.024483874298439 },
      { ath: 133.2669806461578, atv: -5.019315095167477 },
      { ath: 132.81780010900854, atv: -4.493488304141849 },
    ],
    id: "hs524",
  },
  {
    points: [
      { ath: 132.56987201332817, atv: -4.620166795826775 },
      { ath: 132.73208389271352, atv: -4.89952189485983 },
      { ath: 127.81194064136844, atv: -4.799468584063003 },
      { ath: 127.62079011145889, atv: -4.392450400918112 },
    ],
    id: "hs525",
  },
  {
    points: [
      { ath: 127.03806714317639, atv: -4.263949740616193 },
      { ath: 127.1679263822607, atv: -4.638556055836142 },
      { ath: 121.19430473666685, atv: -4.638290692275563 },
      { ath: 121.19948074864192, atv: -4.138347469273007 },
    ],
    id: "hs526",
  },
  {
    points: [
      { ath: 120.73062247765131, atv: -4.334166145068241 },
      { ath: 120.60511507831541, atv: -4.740950310690301 },
      { ath: 119.1500624537907, atv: -4.740652577200998 },
      { ath: 119.34086790209912, atv: -4.302941960957027 },
    ],
    id: "hs527",
  },
  {
    points: [
      { ath: 116.82519514837509, atv: -4.262006829047367 },
      { ath: 116.70376507220652, atv: -4.636950749883317 },
      { ath: 113.48301367167159, atv: -4.582208492765548 },
      { ath: 113.54607066983584, atv: -4.332172305974425 },
    ],
    id: "hs528",
  },
  {
    points: [
      { ath: 113.00933650503993, atv: -4.269643461706039 },
      { ath: 112.85097378485034, atv: -4.550761382347806 },
      { ath: 105.81304651009162, atv: -4.604909000810036 },
      { ath: 105.85067856732181, atv: -4.357320933881335 },
    ],
    id: "hs529",
  },
  {
    points: [
      { ath: 105.31856040954358, atv: -4.2577025388134215 },
      { ath: 105.31508884760663, atv: -4.570434572333769 },
      { ath: 104.33844652002674, atv: -4.627559773160795 },
      { ath: 104.3441444416413, atv: -4.253248552859083 },
    ],
    id: "hs530",
  },
  {
    points: [
      { ath: 113.40915578218636, atv: 0.39544269888583555 },
      { ath: 111.7265041379859, atv: -1.4386738639006516 },
      { ath: 104.76365216718546, atv: -1.1914897800328434 },
      { ath: 105.77809700209013, atv: 0.5480952832140588 },
    ],
    id: "hs531",
  },
  {
    points: [
      { ath: 111.66536210875142, atv: -1.5618619236577553 },
      { ath: 110.27088256619226, atv: -3.7653441523892046 },
      { ath: 103.50726847134123, atv: -3.719960379253083 },
      { ath: 104.637456218475, atv: -1.34573293903931 },
    ],
    id: "hs532",
  },
  {
    points: [
      { ath: 103.85129840041395, atv: -1.5056201357519763 },
      { ath: 102.758316135364, atv: -3.8618824900761695 },
      { ath: 96.47800334953033, atv: -3.685047774940651 },
      { ath: 97.30170128648311, atv: -1.3463478864149125 },
    ],
    id: "hs533",
  },
  {
    points: [
      { ath: 104.9892694180937, atv: 0.6035982500313584 },
      { ath: 103.9441898708454, atv: -1.3209560230673334 },
      { ath: 97.30568107436252, atv: -1.1320588392295912 },
      { ath: 98.10978463158733, atv: 0.6930491489402137 },
    ],
    id: "hs534",
  },
  {
    points: [
      { ath: 96.5794241411889, atv: -1.262685827502238 },
      { ath: 95.77188877419036, atv: -3.7651732456526554 },
      { ath: 89.74334235528795, atv: -3.537573374549856 },
      { ath: 89.96576908925874, atv: -1.4008178071034711 },
    ],
    id: "hs535",
  },
  {
    points: [
      { ath: 97.27832291124955, atv: 0.7180188620560386 },
      { ath: 96.61163326006533, atv: -1.1716935365077892 },
      { ath: 89.85282517902533, atv: -1.127427209723504 },
      { ath: 89.97615816858587, atv: 0.799124341262139 },
    ],
    id: "hs536",
  },
  {
    points: [
      { ath: 89.04003523895528, atv: 0.9760649811329046 },
      { ath: 89.02116905712586, atv: -1.2732963374494144 },
      { ath: 82.53595125612424, atv: -1.2292002300702196 },
      { ath: 81.93566203173663, atv: 0.7851484267389272 },
    ],
    id: "hs537",
  },
  {
    points: [
      { ath: 89.14367743701564, atv: -1.4277623375337964 },
      { ath: 89.06286120941115, atv: -3.712413775991168 },
      { ath: 83.29167315138864, atv: -3.617649924859164 },
      { ath: 82.71446480345742, atv: -1.382479834542644 },
    ],
    id: "hs538",
  },
  {
    points: [
      { ath: 81.13179441618831, atv: 0.9230118880473248 },
      { ath: 81.78316535885858, atv: -1.0337411551353786 },
      { ath: 75.05913046535932, atv: -0.9827423449795315 },
      { ath: 73.93358324724079, atv: 0.9591188948050544 },
    ],
    id: "hs539",
  },
  {
    points: [
      { ath: 81.99389338979529, atv: -1.2164232484372117 },
      { ath: 82.932284549354, atv: -3.549468569188569 },
      { ath: 76.2535024385545, atv: -3.3946002247050284 },
      { ath: 75.16378218770421, atv: -1.2509390911169653 },
    ],
    id: "hs540",
  },
  {
    points: [
      { ath: 101.62908548203603, atv: -4.303128438044792 },
      { ath: 101.49043594326625, atv: -4.673274571110005 },
      { ath: 99.17665075639266, atv: -4.548884046722002 },
      { ath: 99.3530359076776, atv: -4.238393571817785 },
    ],
    id: "hs541",
  },
  {
    points: [
      { ath: 98.76738000186884, atv: -4.306071757647272 },
      { ath: 98.71396518151107, atv: -4.61568383359199 },
      { ath: 92.63066845748943, atv: -4.466519109377447 },
      { ath: 92.62923223650532, atv: -4.186309943073895 },
    ],
    id: "hs542",
  },
  {
    points: [
      { ath: 92.03094734722907, atv: -4.280504388331201 },
      { ath: 92.03148701726343, atv: -4.467390063571008 },
      { ath: 90.7379915580008, atv: -4.52994670571249 },
      { ath: 90.73859737032333, atv: -4.218455245754031 },
    ],
    id: "hs543",
  },
  {
    points: [
      { ath: 88.28528801927234, atv: -3.9954881628683316 },
      { ath: 88.21753568669317, atv: -4.4304097171685655 },
      { ath: 86.92914590571746, atv: -4.455097068992544 },
      { ath: 86.96549631253822, atv: -4.144762271368999 },
    ],
    id: "hs544",
  },
  {
    points: [
      { ath: 86.27636671582235, atv: -4.059375015847677 },
      { ath: 86.30993780990445, atv: -4.557491016971655 },
      { ath: 80.26652681238158, atv: -4.3553062614711235 },
      { ath: 80.27449781600603, atv: -3.92130097798193 },
    ],
    id: "hs545",
  },
  {
    points: [
      { ath: 79.4686655864532, atv: -3.998382135364593 },
      { ath: 79.62326964214668, atv: -4.340618486639763 },
      { ath: 77.4240513290124, atv: -4.3005996497328125 },
      { ath: 77.33706438680042, atv: -3.866154364297855 },
    ],
    id: "hs546",
  },
  {
    points: [
      { ath: 74.6821641930726, atv: -3.839264533538247 },
      { ath: 74.9048924641703, atv: -4.243176382743221 },
      { ath: 73.70719180718692, atv: -4.307132137460207 },
      { ath: 73.70629186682817, atv: -3.840356304460245 },
    ],
    id: "hs547",
  },
  {
    points: [
      { ath: 73.23408268771283, atv: -3.809431259888354 },
      { ath: 73.2971521388082, atv: -4.089330034017777 },
      { ath: 66.24473542338592, atv: -3.935910995239187 },
      { ath: 66.2192731620795, atv: -3.71968912901832 },
    ],
    id: "hs548",
  },
  {
    points: [
      { ath: 65.57078810136215, atv: -3.6527369136910495 },
      { ath: 65.83910955363046, atv: -4.025002398831782 },
      { ath: 62.338335064792204, atv: -3.985925901910643 },
      { ath: 62.20130244664392, atv: -3.617808891683197 },
    ],
    id: "hs549",
  },
  {
    points: [
      { ath: 74.60368195191529, atv: -0.8507624346682525 },
      { ath: 75.71534805378525, atv: -3.340569245686053 },
      { ath: 68.69004126843538, atv: -3.1790678892969133 },
      { ath: 67.12297970860334, atv: -0.8766228861123162 },
    ],
    id: "hs550",
  },
  {
    points: [
      { ath: 73.38831768993464, atv: 1.0889656771860334 },
      { ath: 74.44767114208048, atv: -0.7592780357184045 },
      { ath: 66.85175672429642, atv: -0.6335792065859802 },
      { ath: 65.46224491275228, atv: 1.1088998770507614 },
    ],
    id: "hs551",
  },
  {
    points: [
      { ath: 65.15839982472914, atv: 1.1186710705210967 },
      { ath: 66.67245482594694, atv: -0.6984539219411462 },
      { ath: 63.40847033644633, atv: -0.7277834684067881 },
      { ath: 62.44706185472319, atv: 1.1760131203798485 },
    ],
    id: "hs552",
  },
  {
    points: [
      { ath: 66.92207915312997, atv: -0.8510132972367793 },
      { ath: 68.40037483182834, atv: -3.2183599440682316 },
      { ath: 64.6961227766459, atv: -3.1250947085829646 },
      { ath: 63.53090001623093, atv: -0.9412382501110669 },
    ],
    id: "hs553",
  },
  {
    points: [
      { ath: 59.698612930605464, atv: -3.651001713312193 },
      { ath: 59.76269374445951, atv: -3.868288059935167 },
      { ath: 58.40931149471146, atv: -3.900675496431272 },
      { ath: 58.440597210443514, atv: -3.5900337793223813 },
    ],
    id: "hs554",
  },
  {
    points: [
      { ath: 57.90587653589154, atv: -3.652065043834675 },
      { ath: 57.77980195282419, atv: -3.931617048189307 },
      { ath: 51.919225104177826, atv: -3.8457070457888474 },
      { ath: 52.02027348370109, atv: -3.5077942768666093 },
    ],
    id: "hs555",
  },
  {
    points: [
      { ath: 51.42911029137565, atv: -3.5652749642346833 },
      { ath: 51.30114557094862, atv: -3.7184082652080406 },
      { ath: 44.86324374496189, atv: -3.91639348531444 },
      { ath: 45.06141429209049, atv: -3.526254363012915 },
    ],
    id: "hs556",
  },
  {
    points: [
      { ath: 44.52242313622901, atv: -3.578665708574474 },
      { ath: 44.269635995565864, atv: -3.876367767462186 },
      { ath: 43.76506067215388, atv: -3.8978530838344967 },
      { ath: 44.14033690211318, atv: -3.512539704056251 },
    ],
    id: "hs557",
  },
  {
    points: [
      { ath: 59.46816626807015, atv: 1.0579628874869917 },
      { ath: 59.57055095973948, atv: 0.48674574602544346 },
      { ath: 51.31658232952623, atv: 0.6082240613220353 },
      { ath: 51.878424124206504, atv: 1.180347709009824 },
    ],
    id: "hs558",
  },
  {
    points: [
      { ath: 59.666138612029044, atv: 0.30583035987702856 },
      { ath: 59.84059994416259, atv: -0.7842356454313727 },
      { ath: 53.039257716326574, atv: -0.5736040232546483 },
      { ath: 53.88165225441361, atv: 0.3674407925177671 },
    ],
    id: "hs559",
  },
  {
    points: [
      { ath: 53.01352185648989, atv: 0.1554277799148656 },
      { ath: 52.16922599060672, atv: -0.6354423568611326 },
      { ath: 44.9910740921182, atv: -0.5801105175517043 },
      { ath: 47.259053121088755, atv: 0.4768968655714811 },
    ],
    id: "hs560",
  },
  {
    points: [
      { ath: 50.6295047306387, atv: 0.5380610168508072 },
      { ath: 51.06184162969669, atv: 1.0497841892678932 },
      { ath: 34.007426155138546, atv: 1.5517076684249742 },
      { ath: 33.20882580460972, atv: 0.9730894298521138 },
    ],
    id: "hs561",
  },
  {
    points: [
      { ath: 44.399639833425, atv: -0.6489253743662141 },
      { ath: 45.73453275544398, atv: 0.26018603346763114 },
      { ath: 39.566906284300785, atv: 0.43895357267685964 },
      { ath: 37.501166243030866, atv: -0.31190608521566726 },
    ],
    id: "hs562",
  },
  {
    points: [
      { ath: 36.98437135965116, atv: -0.3115010638982891 },
      { ath: 38.71025070716803, atv: 0.40827524664901776 },
      { ath: 33.135307750357754, atv: 0.7531928724534058 },
      { ath: 31.018417620581715, atv: 0.01632911919425059 },
    ],
    id: "hs563",
  },
  {
    points: [
      { ath: 39.18917405270349, atv: -3.9158344794913997 },
      { ath: 40.99587348636015, atv: -4.051201506165666 },
      { ath: 41.37633018627423, atv: -3.7436341859456097 },
      { ath: 39.41186316586288, atv: -3.7015113729317854 },
    ],
    id: "hs564",
  },
  {
    points: [
      { ath: 35.389715686403406, atv: -4.123601939509652 },
      { ath: 38.81303335171043, atv: -4.067166605489289 },
      { ath: 39.06915969431083, atv: -3.7607450101502087 },
      { ath: 35.80174230533237, atv: -3.7307784724820774 },
    ],
    id: "hs565",
  },
  {
    points: [
      { ath: 28.59182293255111, atv: -4.335625480113739 },
      { ath: 34.65685115853921, atv: -4.283293343045101 },
      { ath: 35.25944516795295, atv: -3.8227890515419527 },
      { ath: 28.973000299594844, atv: -3.914289245105229 },
    ],
    id: "hs566",
  },
  {
    points: [
      { ath: 17.332704806875597, atv: -4.468145559796131 },
      { ath: 25.475138978497682, atv: -4.371017871725874 },
      { ath: 25.694430563439482, atv: -3.9627172869537803 },
      { ath: 17.55909181551965, atv: -4.095664627074746 },
    ],
    id: "hs567",
  },
  {
    points: [
      { ath: 14.41036909692491, atv: -4.559955856477453 },
      { ath: 16.610774518431526, atv: -4.617153250792231 },
      { ath: 17.089600519126066, atv: -4.122932783242405 },
      { ath: 14.577226078645765, atv: -4.12821672129918 },
    ],
    id: "hs568",
  },
  {
    points: [
      { ath: 4.307077896810938, atv: -4.782661413878435 },
      { ath: 4.336312649373326, atv: -4.247015390731182 },
      { ath: 10.099773159343442, atv: -4.338470261878686 },
      { ath: 9.949562097582486, atv: -4.621344975690774 },
    ],
    id: "hs569",
  },
  {
    points: [
      { ath: -4.165137889731341, atv: -4.562480054292875 },
      { ath: -4.159928918788978, atv: -4.312450383634276 },
      { ath: 3.6712816750854813, atv: -4.405840125840399 },
      { ath: 3.5133226893382243, atv: -4.658130035932724 },
    ],
    id: "hs570",
  },
  {
    points: [
      { ath: -10.5583954240509, atv: -4.644012310414482 },
      { ath: -10.55790189852354, atv: -4.329359395963799 },
      { ath: -4.929967192715935, atv: -4.406424841329357 },
      { ath: -4.95855380349991, atv: -4.5947077306133135 },
    ],
    id: "hs571",
  },
  {
    points: [
      { ath: -17.227375144582766, atv: -4.524800072099693 },
      { ath: -17.44810378349507, atv: -4.116190613989939 },
      { ath: -15.359402369292525, atv: -4.147042984894839 },
      { ath: -15.104514836545263, atv: -4.555237480739607 },
    ],
    id: "hs572",
  },
  {
    points: [
      { ath: -26.13167454128245, atv: -4.481353710345866 },
      { ath: -26.248989641115486, atv: -3.9177668901909013 },
      { ath: -18.200408980479438, atv: -4.05020766573929 },
      { ath: -17.94303209515789, atv: -4.519033487354183 },
    ],
    id: "hs573",
  },
  {
    points: [
      { ath: -29.45554478574951, atv: -3.7861802014443415 },
      { ath: -29.137538143270945, atv: -4.2551854902075394 },
      { ath: -33.15220279255465, atv: -4.221580585375679 },
      { ath: -33.62135878191128, atv: -3.7821767570685836 },
    ],
    id: "hs574",
  },
  {
    points: [
      { ath: -34.089658469385995, atv: -3.761516012494854 },
      { ath: -33.70988286750037, atv: -4.042068992664194 },
      { ath: -39.19712617656569, atv: -4.002086248424213 },
      { ath: -39.69372382865828, atv: -3.625878910390519 },
    ],
    id: "hs575",
  },
  {
    points: [
      { ath: -40.00910760597378, atv: -3.6068571065514794 },
      { ath: -39.63317109515083, atv: -3.9818943889036227 },
      { ath: -41.86815839428971, atv: -3.9124186692446266 },
      { ath: -42.20922079878511, atv: -3.5996087498304683 },
    ],
    id: "hs576",
  },
  {
    points: [
      { ath: -34.191370282348885, atv: 1.4876940558264093 },
      { ath: -34.09088839242281, atv: 0.9139412624103691 },
      { ath: -44.02527001662315, atv: 0.8509488251971495 },
      { ath: -44.38295851891229, atv: 1.3320642221540127 },
    ],
    id: "hs577",
  },
  {
    points: [
      { ath: -33.28177399415847, atv: 0.4891739276217079 },
      { ath: -35.65957988657732, atv: -0.1187923745237285 },
      { ath: -38.14952282332405, atv: -0.14945938081659654 },
      { ath: -39.392135976342104, atv: 0.5211996200130147 },
    ],
    id: "hs578",
  },
  {
    points: [
      { ath: -39.949311398087445, atv: 0.703349122941064 },
      { ath: -38.585312818644354, atv: -0.33293615036129526 },
      { ath: -45.24795061435714, atv: -0.3000024952105419 },
      { ath: -46.63230811819608, atv: 0.45538860378901874 },
    ],
    id: "hs579",
  },
  {
    points: [
      { ath: -44.961681990259024, atv: 1.339218023525333 },
      { ath: -44.7395716856866, atv: 0.8865586050467762 },
      { ath: -51.33749974668882, atv: 0.675860920305124 },
      { ath: -51.70316173512856, atv: 1.2196924377342835 },
    ],
    id: "hs580",
  },
  {
    points: [
      { ath: -47.21256401807227, atv: 0.5246567676934479 },
      { ath: -46.089028802868484, atv: -0.26659911863013436 },
      { ath: -52.932065913066765, atv: -0.4794926452777555 },
      { ath: -53.537279139357736, atv: 0.3413246041884619 },
    ],
    id: "hs581",
  },
  {
    points: [
      { ath: -54.51955969970305, atv: 0.5221776926247244 },
      { ath: -53.73660928109757, atv: -0.44851065727310824 },
      { ath: -57.02551509250014, atv: -0.47578623272399984 },
      { ath: -60.82971932272261, atv: -0.5299670471097645 },
      { ath: -60.37266583503066, atv: 0.4248223836779288 },
    ],
    id: "hs582",
  },
  {
    points: [
      { ath: -52.907554101787525, atv: 1.1880088042338195 },
      { ath: -52.54501663451873, atv: 0.6448174797527346 },
      { ath: -60.30698629751663, atv: 0.5736773079490167 },
      { ath: -60.19111151469741, atv: 1.2266816966744525 },
    ],
    id: "hs583",
  },
  {
    points: [
      { ath: -44.743428237238334, atv: -3.311020160430831 },
      { ath: -44.29572447457576, atv: -3.8990623134978555 },
      { ath: -46.46370109091362, atv: -3.938897180115485 },
      { ath: -46.75088618310457, atv: -3.410357605604201 },
    ],
    id: "hs584",
  },
  {
    points: [
      { ath: -47.09700598311463, atv: -3.379915189546884 },
      { ath: -46.87440930556045, atv: -3.752949780427732 },
      { ath: -51.94638118558095, atv: -3.8736278221156057 },
      { ath: -52.034921016912676, atv: -3.3757717049704707 },
    ],
    id: "hs585",
  },
  {
    points: [
      { ath: -52.47436443085894, atv: -3.4052346511365816 },
      { ath: -52.57382905976556, atv: -3.8088151364225773 },
      { ath: -58.66072892493224, atv: -3.82191550102353 },
      { ath: -58.65220863582539, atv: -3.576382913107067 },
    ],
    id: "hs586",
  },
  {
    points: [
      { ath: -58.895223148153946, atv: -3.500582640936311 },
      { ath: -58.992137035918915, atv: -3.843067721765582 },
      { ath: -60.44011245688796, atv: -3.900536608706553 },
      { ath: -60.340946528438224, atv: -3.4963466648116728 },
    ],
    id: "hs587",
  },
  {
    points: [
      { ath: -62.68470722959006, atv: -3.564943611780923 },
      { ath: -62.81192372474209, atv: -4.095201167147552 },
      { ath: -66.4662101732277, atv: -4.1151159068843 },
      { ath: -66.17619781625632, atv: -3.5874649209841474 },
    ],
    id: "hs588",
  },
  {
    points: [
      { ath: -66.64858514292064, atv: -3.678509028362479 },
      { ath: -66.9378856436021, atv: -4.143576943574304 },
      { ath: -73.89316385807513, atv: -4.224486232445401 },
      { ath: -73.75728995603612, atv: -3.8592337090698337 },
    ],
    id: "hs589",
  },
  {
    points: [
      { ath: -63.07159004675606, atv: 1.1624364945447108 },
      { ath: -64.22994439305438, atv: -0.8687420817485897 },
      { ath: -67.40077732956388, atv: -0.7492413431837182 },
      { ath: -65.6855755990016, atv: 1.2577994119225733 },
    ],
    id: "hs590",
  },
  {
    points: [
      { ath: -64.4135095888816, atv: -1.0219477210740044 },
      { ath: -65.43777810576756, atv: -3.1826017374455833 },
      { ath: -69.52191287574601, atv: -3.1597695569527 },
      { ath: -67.64963797594862, atv: -0.9027218791002486 },
    ],
    id: "hs591",
  },
  {
    points: [
      { ath: -66.3320875950962, atv: 1.1076472813556049 },
      { ath: -67.93016627798096, atv: -0.8414859791521774 },
      { ath: -75.09281487210114, atv: -0.868474539737648 },
      { ath: -73.82647356586926, atv: 0.9250927143883113 },
    ],
    id: "hs592",
  },
  {
    points: [
      { ath: -68.27262471196309, atv: -1.118026862133528 },
      { ath: -69.83665038299836, atv: -3.2219513023169637 },
      { ath: -76.26417717232823, atv: -3.44742716716789 },
      { ath: -75.19271215816013, atv: -1.2047568078159119 },
    ],
    id: "hs593",
  },
  {
    points: [
      { ath: -74.65801773409112, atv: 0.9843737583578421 },
      { ath: -75.62319332835864, atv: -1.0815046701441402 },
      { ath: -82.39569155897533, atv: -1.0904140964434315 },
      { ath: -81.74917148543142, atv: 0.8178451090752468 },
    ],
    id: "hs594",
  },
  {
    points: [
      { ath: -75.874922579371, atv: -1.295022693163789 },
      { ath: -76.88484126028396, atv: -3.4428522919929296 },
      { ath: -83.01054991432534, atv: -3.4974343872358524 },
      { ath: -82.41029806232979, atv: -1.4204663219989113 },
    ],
    id: "hs595",
  },
  {
    points: [
      { ath: -74.18348372457751, atv: -3.9883366604944324 },
      { ath: -74.59599213855233, atv: -4.297220738989718 },
      { ath: -75.63079328248222, atv: -4.38303282092683 },
      { ath: -75.46499035269022, atv: -3.9180463162030206 },
    ],
    id: "hs596",
  },
  {
    points: [
      { ath: -78.0506115222135, atv: -4.17397421191362 },
      { ath: -78.05784545076409, atv: -4.421379767265515 },
      { ath: -80.24092886659861, atv: -4.423904767092376 },
      { ath: -80.38208110422693, atv: -4.11459445232322 },
    ],
    id: "hs597",
  },
  {
    points: [
      { ath: -83.09394014471616, atv: -1.184793838399712 },
      { ath: -83.6793446145333, atv: -3.574025300953305 },
      { ath: -90.07469861136514, atv: -3.7426175817639438 },
      { ath: -89.98411889483344, atv: -1.278712289715894 },
    ],
    id: "hs598",
  },
  {
    points: [
      { ath: -82.58554576177613, atv: 0.8430300996114592 },
      { ath: -83.05873403095859, atv: -1.0021680126817576 },
      { ath: -89.9695876975486, atv: -0.9806781866821311 },
      { ath: -89.85803722532057, atv: 0.7069117157348591 },
    ],
    id: "hs599",
  },
  {
    points: [
      { ath: -80.68055049014373, atv: -4.188942230707303 },
      { ath: -80.7414363530483, atv: -4.532721572075879 },
      { ath: -85.00691824891084, atv: -4.593376939906803 },
      { ath: -85.00343525221098, atv: -4.187382295943502 },
    ],
    id: "hs600",
  },
  {
    points: [
      { ath: -85.53803363523332, atv: -4.1543494320031185 },
      { ath: -85.60498970325887, atv: -4.5286071113718265 },
      { ath: -94.90111948373624, atv: -4.552873525986903 },
      { ath: -94.89062489732646, atv: -4.308533125284661 },
    ],
    id: "hs601",
  },
  {
    points: [
      { ath: -90.6236613412483, atv: 0.6996926486836564 },
      { ath: -90.68882479721094, atv: -1.0068821924963636 },
      { ath: -97.48467398246049, atv: -1.1204176139066735 },
      { ath: -97.98743626235722, atv: 0.6331931767021921 },
    ],
    id: "hs602",
  },
  {
    points: [
      { ath: -90.68895173014647, atv: -1.3751837413305734 },
      { ath: -90.59875186610253, atv: -3.695514891735691 },
      { ath: -96.90445068134505, atv: -3.8867616041608275 },
      { ath: -97.33843797759681, atv: -1.4569892372973052 },
    ],
    id: "hs603",
  },
  {
    points: [
      { ath: -95.38251665827045, atv: -4.270829687111693 },
      { ath: -95.29427546656098, atv: -4.582549074122168 },
      { ath: -99.55865037108151, atv: -4.724646349207552 },
      { ath: -99.6692170343976, atv: -4.353125024451606 },
    ],
    id: "hs604",
  },
  {
    points: [
      { ath: -98.05527457328554, atv: -1.3347835504701981 },
      { ath: -97.60073073676199, atv: -3.7386889435491857 },
      { ath: -102.8584264973797, atv: -3.8380220381620274 },
      { ath: -103.9408431109369, atv: -1.6571692960674542 },
    ],
    id: "hs605",
  },
  {
    points: [
      { ath: -98.72047455877163, atv: 0.7287861952642821 },
      { ath: -98.14788523242026, atv: -1.1513685382405299 },
      { ath: -104.1448825879771, atv: -1.2923764845683994 },
      { ath: -105.1850876046137, atv: 0.3927528436141105 },
    ],
    id: "hs606",
  },
  {
    points: [
      { ath: -100.14498613791363, atv: -4.31576041264341 },
      { ath: -100.07936821627226, atv: -4.587616179403999 },
      { ath: -102.26548229462048, atv: -4.710343148119685 },
      { ath: -102.32554738105449, atv: -4.345174551466526 },
    ],
    id: "hs607",
  },
  {
    points: [
      { ath: -104.91592937187269, atv: -4.37638792533866 },
      { ath: -104.80245313426809, atv: -4.798428218044102 },
      { ath: -106.02844573921635, atv: -4.740554005054067 },
      { ath: -106.14017027885984, atv: -4.374767798828571 },
    ],
    id: "hs608",
  },
  {
    points: [
      { ath: -106.56605914090824, atv: -4.261425705674249 },
      { ath: -106.6543456142534, atv: -4.626414622162055 },
      { ath: -113.7627443352896, atv: -4.681023073043253 },
      { ath: -113.83415104277512, atv: -4.346886333076839 },
    ],
    id: "hs609",
  },
  {
    points: [
      { ath: -114.19335458095836, atv: -4.314642925792482 },
      { ath: -114.20676320198231, atv: -4.675013575187991 },
      { ath: -117.52754739498187, atv: -4.703574855890762 },
      { ath: -117.61934387081459, atv: -4.372785054360876 },
    ],
    id: "hs610",
  },
  {
    points: [
      { ath: -120.4472576674047, atv: -4.396993384484231 },
      { ath: -120.14147226564472, atv: -4.706624742447913 },
      { ath: -121.58367505933637, atv: -4.7242646150898215 },
      { ath: -121.63296769741785, atv: -4.41668767737161 },
    ],
    id: "hs611",
  },
  {
    points: [
      { ath: -121.96731121739708, atv: -4.3914840435369715 },
      { ath: -121.99815938070103, atv: -4.729355475112428 },
      { ath: -128.00254139593204, atv: -4.771283742471428 },
      { ath: -127.88232986168816, atv: -4.493274155583909 },
    ],
    id: "hs612",
  },
  {
    points: [
      { ath: -128.52676311004393, atv: -4.5140214152777105 },
      { ath: -128.67520945974266, atv: -4.79115564783663 },
      { ath: -133.66057907384572, atv: -4.903014263736279 },
      { ath: -133.42577144957616, atv: -4.551167322864039 },
    ],
    id: "hs613",
  },
  {
    points: [
      { ath: -133.80722912030072, atv: -4.626141114067688 },
      { ath: -134.0680039808338, atv: -4.977240406486202 },
      { ath: -136.06745613830748, atv: -4.904163563557825 },
      { ath: -135.84153860635516, atv: -4.664938816611724 },
    ],
    id: "hs614",
  },
  {
    points: [
      { ath: -124.49442586806504, atv: 0.34332836973295805 },
      { ath: -124.96483746203026, atv: -1.6212322880224488 },
      { ath: -120.28276234494962, atv: -1.6321023471343137 },
      { ath: -121.16410252979753, atv: 0.1771117034139678 },
    ],
    id: "hs615",
  },
  {
    points: [
      { ath: -120.1393193572994, atv: -1.8243507145587767 },
      { ath: -119.54203714904702, atv: -3.2025783018665566 },
      { ath: -126.15904870192051, atv: -3.0514300611063248 },
      { ath: -125.6127567707776, atv: -1.6226894874293225 },
    ],
    id: "hs616",
  },
  {
    points: [
      { ath: -126.6695606404565, atv: -3.0530549696481213 },
      { ath: -126.37608797026061, atv: -1.456873565909456 },
      { ath: -133.15821643337557, atv: -1.0395780432763237 },
      { ath: -134.9324052342724, atv: -2.8494667569506262 },
    ],
    id: "hs617",
  },
  {
    points: [
      { ath: -120.29999960022607, atv: -3.7108585268613106 },
      { ath: -120.56649810057581, atv: -4.0764447132433315 },
      { ath: -127.97031376371294, atv: -4.014974986983595 },
      { ath: -127.8595917928821, atv: -3.5065881063097915 },
    ],
    id: "hs618",
  },
  {
    points: [
      { ath: -128.05724672514611, atv: -3.7044781050457245 },
      { ath: -128.14097967151744, atv: -4.015348207842411 },
      { ath: -135.66167991283174, atv: -4.1371586271332434 },
      { ath: -135.23116008807028, atv: -3.8024027691691584 },
    ],
    id: "hs619",
  },
  {
    points: [
      { ath: -125.38583750467393, atv: 0.17862055068900465 },
      { ath: -125.64239483378788, atv: -1.4834702761026026 },
      { ath: -132.84599066403607, atv: -0.8731301904503338 },
      { ath: -130.52807225650903, atv: 0.703062066142809 },
    ],
    id: "hs620",
  },
  {
    points: [
      { ath: -138.13632482340756, atv: -4.357858022987659 },
      { ath: -138.89772070778483, atv: -5.100980754982944 },
      { ath: -140.44431185685644, atv: -5.079668348817965 },
      { ath: -139.96162034927556, atv: -4.508735319910629 },
    ],
    id: "hs621",
  },
  {
    points: [
      { ath: -140.44717786417368, atv: -4.567261240701255 },
      { ath: -141.16002786477645, atv: -5.281284793559481 },
      { ath: -144.80799984691967, atv: -5.336457603523177 },
      { ath: -143.91522371895232, atv: -4.598063354467081 },
    ],
    id: "hs622",
  },
  {
    points: [
      { ath: -144.689126094262, atv: -4.738588486053934 },
      { ath: -145.46768077898366, atv: -5.390818707750164 },
      { ath: -149.3233140792297, atv: -5.531173480327766 },
      { ath: -148.80289070886846, atv: -5.0261652982169895 },
    ],
    id: "hs623",
  },
  {
    points: [
      { ath: -149.368841083283, atv: -4.9639846661669615 },
      { ath: -150.0068916844205, atv: -5.693070411986906 },
      { ath: -153.79373716995542, atv: -5.772916542644479 },
      { ath: -153.27479422437557, atv: -5.16544882301773 },
    ],
    id: "hs624",
  },
  {
    points: [
      { ath: -139.803497081806, atv: 0.4979076017696423 },
      { ath: -143.2496240808719, atv: -1.2895962434307406 },
      { ath: -149.85669098710463, atv: -1.3352531768086935 },
      { ath: -147.0224770828991, atv: 0.4970910925139686 },
    ],
    id: "hs625",
  },
  {
    points: [
      { ath: -147.97578913315385, atv: 0.4687140093059017 },
      { ath: -150.86015190564495, atv: -1.3320997330403643 },
      { ath: -158.19467699547653, atv: -1.3508622028271706 },
      { ath: -156.31637020047017, atv: 0.3763784838303694 },
    ],
    id: "hs626",
  },
  {
    points: [
      { ath: -153.79269526072216, atv: -5.101279848158097 },
      { ath: -154.42067927097588, atv: -5.846334923710026 },
      { ath: -161.75096976386365, atv: -6.049682478236867 },
      { ath: -161.1412259326642, atv: -5.343038517347169 },
    ],
    id: "hs627",
  },
  {
    points: [
      { ath: -162.08199544715336, atv: -5.334768875944537 },
      { ath: -162.49475178202945, atv: -6.183884005138597 },
      { ath: -169.84840401500568, atv: -6.297250876096218 },
      { ath: -169.68475420885335, atv: -5.549559539992205 },
    ],
    id: "hs628",
  },
  {
    points: [
      { ath: -157.13961872472322, atv: 0.5797845294207545 },
      { ath: -159.6720285042997, atv: -1.269521683026646 },
      { ath: -166.3714552236516, atv: -1.4183871226905165 },
      { ath: -164.82888755330723, atv: 0.5732879404948622 },
    ],
    id: "hs629",
  },
  {
    points: [
      { ath: -170.44612158367107, atv: -5.513194923260638 },
      { ath: -170.79229822307326, atv: -6.287375103268078 },
      { ath: -175.59893317208974, atv: -6.234626481574424 },
      { ath: -175.50601568890124, atv: -5.607203824694294 },
    ],
    id: "hs630",
  },
  {
    points: [
      { ath: -166.10957510982402, atv: 0.6025497861536965 },
      { ath: -167.4247860944314, atv: -1.4287170572391241 },
      { ath: -174.92110699487134, atv: -1.3984674770198053 },
      { ath: -174.22395648027836, atv: 0.4627026872213929 },
    ],
    id: "hs631",
  },
  {
    points: [
      { ath: -175.66672276646977, atv: 0.511538947515832 },
      { ath: -176.2826231243244, atv: -1.4709484563287931 },
      { ath: 175.31175749050632, atv: -1.4351816917894133 },
      { ath: 174.8856976314417, atv: 0.42460337760974776 },
    ],
    id: "hs632",
  },
  {
    points: [
      { ath: -176.34993018385666, atv: -5.613726670781403 },
      { ath: -176.40415268176844, atv: -6.388009178614019 },
      { ath: 178.7487082782881, atv: -6.298929045713841 },
      { ath: 178.83812612932576, atv: -5.754872420398252 },
    ],
    id: "hs633",
  },
  {
    points: [
      { ath: 177.83351862793597, atv: -5.548948671486413 },
      { ath: 177.79889064178712, atv: -6.321720630002522 },
      { ath: 173.06244762806324, atv: -6.295166160727847 },
      { ath: 172.90511834030252, atv: -5.611635786494235 },
    ],
    id: "hs634",
  },
  {
    points: [
      { ath: 173.42890502578064, atv: 0.5384934200064265 },
      { ath: 173.88471632193, atv: -1.473045738492283 },
      { ath: 167.08748397777964, atv: -1.3218913207629137 },
      { ath: 165.40841873337803, atv: 0.5886204688602569 },
    ],
    id: "hs635",
  },
  {
    points: [
      { ath: 171.9841663702479, atv: -5.414377663523197 },
      { ath: 172.2115928201433, atv: -6.303895813201111 },
      { ath: 167.56008234316414, atv: -6.184047646929657 },
      { ath: 167.33966829619794, atv: -5.611237475885387 },
    ],
    id: "hs636",
  },
  {
    points: [
      { ath: 166.2071685779759, atv: -5.31913499412604 },
      { ath: 166.4433862702582, atv: -6.176266656897809 },
      { ath: 161.83217175964245, atv: -6.076284655057117 },
      { ath: 161.6315135006078, atv: -5.24415156715901 },
    ],
    id: "hs637",
  },
  {
    points: [
      { ath: 164.31710512379107, atv: 0.5167455287047481 },
      { ath: 165.46565896458372, atv: -1.3821406758672703 },
      { ath: 158.8971004907486, atv: -1.4098869489039514 },
      { ath: 156.47865945066172, atv: 0.5704772176415991 },
    ],
    id: "hs638",
  },
  {
    points: [
      { ath: 160.61215258416416, atv: -5.250078185536933 },
      { ath: 161.15123655303898, atv: -6.041630008028548 },
      { ath: 153.76971318475233, atv: -5.968666989287369 },
      { ath: 152.9348567322669, atv: -5.238859269726926 },
    ],
    id: "hs639",
  },
  {
    points: [
      { ath: 152.2778515051038, atv: -5.045964540079293 },
      { ath: 152.79687920225578, atv: -5.52511898049256 },
      { ath: 149.19176453202624, atv: -5.582214762906278 },
      { ath: 148.38332151042235, atv: -4.926943582894223 },
    ],
    id: "hs640",
  },
  {
    points: [
      { ath: 147.69249348869124, atv: -4.814204764149294 },
      { ath: 148.32781265333318, atv: -5.471215911759685 },
      { ath: 144.5267764601092, atv: -5.328790726894446 },
      { ath: 143.72529368204755, atv: -4.667757946808886 },
    ],
    id: "hs641",
  },
  {
    points: [
      { ath: 143.32429927111207, atv: -4.637586972230566 },
      { ath: 143.98104399045906, atv: -5.269717591274078 },
      { ath: 140.31663692033766, atv: -5.159945359256376 },
      { ath: 139.5018654883129, atv: -4.525762098471627 },
    ],
    id: "hs642",
  },
  {
    points: [
      { ath: 138.87959659190847, atv: -4.519632816664228 },
      { ath: 139.57972043674198, atv: -5.038469140382901 },
      { ath: 138.0243303177035, atv: -4.99217069894731 },
      { ath: 137.50239569359266, atv: -4.334460853808957 },
    ],
    id: "hs643",
  },
  {
    points: [
      { ath: 155.35935340650087, atv: 0.6633231932929466 },
      { ath: 157.50227109497823, atv: -1.3377693594896534 },
      { ath: 150.10378835305926, atv: -1.3326454962646856 },
      { ath: 147.16543689037218, atv: 0.614196501625619 },
    ],
    id: "hs644",
  },
  {
    points: [
      { ath: 146.3977340453763, atv: 0.6423988006748351 },
      { ath: 149.07821763650725, atv: -1.2209733867814936 },
      { ath: 142.5519900859659, atv: -1.13483759804248 },
      { ath: 139.04690259596413, atv: 0.49763161411133394 },
    ],
    id: "hs645",
  },
  {
    points: [
      { ath: 138.26624046029477, atv: 0.5803318721923065 },
      { ath: 141.2743466216407, atv: -1.217635196688608 },
      { ath: 135.76251125204064, atv: -1.145909885276021 },
      { ath: 133.99914347260426, atv: 0.49010963688464315 },
    ],
    id: "hs646",
  },
  {
    points: [
      { ath: -142.44315118204904, atv: -1.0448978463380971 },
      { ath: -139.37764097253378, atv: 0.4992675804605459 },
      { ath: -133.86199732270862, atv: 0.3271150960024663 },
      { ath: -136.63481927913904, atv: -1.1596290340014326 },
    ],
    id: "hs647",
  },
];
