import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyArtmr-r-WtbbIGNLqoKNeYplk2Um4jlpk",
  authDomain: "gigamatch-8f275.firebaseapp.com",
  projectId: "gigamatch-8f275",
  storageBucket: "gigamatch-8f275.appspot.com",
  messagingSenderId: "415588085177",
  appId: "1:415588085177:web:ef6037da11a48a092a1a68",
  measurementId: "G-C1997ZJ4FQ"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);