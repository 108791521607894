import { Grow } from "@mui/material";
import { TeamComponent } from "../Team";
import { Match } from "../../App";
import { FC } from "react";

import "../InitialBanner/style.css";
import "./style.css";

interface ITeamSelect {
  data: Match;
  active: boolean;
  updateSeatForTeam: (event: boolean) => void;
}
export const TeamSelect: FC<ITeamSelect> = ({
  data,
  active,
  updateSeatForTeam,
}) => (
  <Grow in={active}>
    <div className="containerTeamSelect">
      <h1 className="titleBanner">Selecciona a tu equipo para apoyarlo</h1>
      <div className="containerTeams containerTeamsSelect">
        <TeamComponent
          team={data?.local}
          active={active}
          textColor="#fff"
          background="transparent"
          onClick={() => updateSeatForTeam(true)}
        />
        <h2 className="vsText">VS</h2>
        <TeamComponent
          team={data?.visitante}
          active={active}
          textColor="#fff"
          background="transparent"
          onClick={() => updateSeatForTeam(false)}
        />
      </div>
    </div>
  </Grow>
);
